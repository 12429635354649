.cards-container {
  max-width: 1140px;
  margin: auto;
  padding: 40px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0 20px;
}

.header-title {
  font-weight: bold;
  margin: 0;
  font-size: 30px;
  color: black;
}

.view-more-link {
  text-decoration: none;
  color: darkblue;
  font-weight: 600;
  font-size: 16px;
}

.view-more-link:hover {
  text-decoration: underline;
}

.arrow-icon {
  font-size: 18px;
  margin-left: 5px;
  margin-bottom: -5px;
}

.latest-posts {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  max-width: 1140px;
  margin: auto;
  padding: 20px;
  font-family: "Roboto", sans-serif;
  text-align: left;
}

.post-card {
  background-color: #fff;
  border: 1px solid #ddd;
  border-top: 4px solid darkblue;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  padding: 15px;
}

.post-card:hover {
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
}

.post-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.post-category {
  font-size: 15px;
  color: black;
  margin-bottom: 5px;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}

.post-category:hover {
  color: #397ead;
}

.post-title {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
}

.post-description {
  font-size: 16px;
  color: black;
  margin-bottom: auto;
  line-height: 1.5;
}

.post-link {
  text-decoration: none;
  color: darkblue;
  font-weight: 600;
  margin-top: 15px;
  display: inline-block;
  text-align: left;
}

.post-link:hover {
  text-decoration: underline;
}

.post-meta {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #555;
}

.created-by,
.published-date {
  font-size: 14px;
  color: #555;
}

.created-by {
  margin-right: 15px;
}

/* _________Post Detail.css__________ */

.allPost-title {
  font-weight: bold;
  margin: 0;
  font-size: 30px;
  color: black;
  padding: 0 20px;
  margin-bottom: 20px;
}

hr {
  border: none;
  border-top: 2px solid rgb(209, 203, 203);
  margin: 5px 0;
}

.post-detail-wrapper {
  flex-grow: 1;
  max-width: 900px;
  margin: 30px auto;
  padding: 10px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.back-button-container {
  position: absolute;
  top: 100px;
  left: 13em;
}

.back-icon {
  cursor: pointer;
  color: black;
  transition: color 0.3s ease;
}

.post-detail-title {
  font-size: 2rem;
  font-weight: 700;
  color: black;
  margin-top: 10px;
  padding: 30px;
}

.post-full-description {
  font-size: 1.125rem;
  line-height: 1.8;
  color: black;
  margin: 20px 40px 20px 30px;
}

.publish-date {
  text-align: left;
  margin-left: 2em;
  font-size: 0.875rem;
  color: #888;
  margin-bottom: 1rem;
  display: flex;
  margin-right: 2em;
  justify-content: space-between;
}

/* _________Search Bar.css____________ */

.Search-and-clear-filter-section-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  gap: 20px;
  top:2px;
  z-index: 5;
}

.clear-filter-section-review-button{
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Arial", sans-serif;
  cursor: pointer;
  padding: 16px;
  border-radius: 5px;
  display: flex;
  background-color: #007bff;
  font-weight: 600;
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.clear-filter-section-review-button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.clear-filter-section-review-button1{
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", sans-serif;
  cursor: pointer;
  padding: 14px;
  border-radius: 10px;
  display: none;
  background-color: #007bff;
  font-weight: 600;
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.clear-filter-section-review-button1:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

@media screen and (max-width: 676px) { 
  .clear-filter-section-review-button1{
    display: flex;
  }
  .clear-filter-section-review-button{
    display: none;
  }
}

.blog-question-header-search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1130px;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(197, 195, 195, 0.1);
  background-color: #fff;
  transition: border-color 0.3s ease;
  margin: auto;
  margin-top: 2px;
  cursor: pointer;
}

.blog-question-no-border, .category-input, .published-date-input {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 14px;
  background-color: transparent;
}

.blog-search-icon {
  color: gray;
}

.blog-question-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.blog-question-header-main {
  flex: 1 1;
  margin-right: 5px;
}

.vertical-line {
  border-left: 1px solid #ccc;
  height: 20px;
  margin: 0 10px;
}

.input-wrapper {
  position: relative;
  width: 100%;
  max-width: 400px;
}

.dropdown-options {
  position: absolute;
  top: 2.5em;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 100;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
}

.dropdown-options li {
  padding: 10px;
  cursor: pointer;
}

.dropdown-options li:hover {
  background-color: #f0f0f0;
}

@media screen and (max-width:490px){
  .blog-question-no-border, .category-input, .published-date-input {
    width: 100%;
    padding: 5px;
    border: none;
    outline: none;
    font-size: 8px;
    margin-left: -5px;
  }

  .blog-search-icon {
    font-size: 20px !important;
    padding-right: 4px;
  }

  .dropdown-options {
    position: absolute;
    top: 2.1em;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    z-index: 100;
    list-style-type: none;
    margin: 0;
    padding: 0;
    max-height: 150px;
    overflow-y: auto;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 120%;
  }
}
