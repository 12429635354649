 /*__________________________ Landing Page _____________________________________*/
.landing_page{
    width: 100% !important;
    height: 100vh !important;
    background: #fafbff;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* padding: 20px; */
}

 /*__________________________ Landing Page Header _____________________________________*/
.landing_page .header {
    margin-bottom: 20px; 
}

 /*__________________________ Landing Page Carousel _____________________________________*/
.landing_page .carousel-root {
    margin: 69px 0 20px 0;
    width: 100% !important;
    height: 500px !important;
}