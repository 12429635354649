.achivement-section-background-color {
  background-color: #fafbff;
}
.achivement-section-width-alignment-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
}

.achivement-flex-container {
  display: flex; 
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  gap: 10px;
}

.achivement-section-image-container {
  max-width: 550px;
  max-height: 550px;
  
 
}

.achivement-section-image-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}



.achivement-heading-conatainer {
  text-align: left;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;

}

.achivement-render-tagline {
  font-size: 17px;
  padding-bottom: 10px;
  padding-top: 1px;
  font-family: "Roboto", sans-serif;
}

.achivement-display-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
padding: 10px;
  gap: 50px;
}

.achivemet-single-container-design {
  min-width: 160px;
  min-height: 160px;
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  border-radius: 10px;
  align-items: center;
  background-color: #ffffff;
}

.achivement-icon-conatiner {
  padding: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.achivement-icon-button {
  /* background-color: #efeff0 !important; */
  border-radius: 8px !important;
}

.achivement-icon {
  font-size: 43px !important;
  color: #078efb;
}

.achivement-numbers {
  font-weight: bold;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.achivement-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Roboto", sans-serif;
}

.achivemet-single-container-design:hover {
  /* outline: 1px solid #078efb; */
  box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5); /* Light up effect */
  transform: scale(1.03); /* Slightly scale up the card */
  outline: 4px solid #078efb;
  transition: transform 1s, box-shadow 1s;
}

@media screen and (max-width: 830px) {
  .achivement-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}

@media screen and (max-width: 430px) {
  .achivement-display-container {
    gap: 10px;
  }
}

@media screen and (max-width: 396px) {
  .achivement-display-container {
    grid-template-columns: 1fr;
  }
  .achivemet-single-container-design {
    max-width: 160px;
  }
}
