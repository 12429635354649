/*__________________ hero section _______________________*/
.hero_div{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}
.hero-section {
    width: 1140px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 2rem;
}
  
  /*__________________ hero content _______________________*/
  .hero-content {
    flex: 1;
    padding-right: 2rem;
    /* padding-left: 60px; */
  }
  
  /*__________________ hero content heading _______________________*/
  .hero-content h1 {
    font-size: 2.5rem;
    margin-bottom: 1rem;
    margin-top: 0;
    font-family: "Roboto", sans-serif;
  }
  
  /*__________________ hero content paragraph _______________________*/
  .hero-content p {
    font-size: 17px;
    margin-bottom: 1.5rem;
    font-family: "Roboto", sans-serif;
  }
  
  /*__________________ hero content image _______________________*/
  .apply-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
    cursor: pointer;
  }
  
  .apply-button:hover {
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  
  /*__________________ hero section image container _______________________*/
  .hero-intern-image {
    flex: 1;
  }
  
  /*__________________ hero section image _______________________*/
  .hero-intern-image img {
    width: 100%;
    height: auto;
    border-radius: 0.3rem;
  }

/*__________________ about section about outside _______________________*/
.about-intern-outside{
  display: flex;
  width: 100%;
  padding: 0;
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
}

/*__________________ about section about container _______________________*/
.about-intern-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 1140px;
}

/*__________________ about section about content _______________________*/
.about-intern-content {
  flex: 1;
  padding-right: 20px;
  max-width: 50%;
  margin-left: 80px;
  color: black;
}

/*__________________ about section about heading _______________________*/
.about-intern-content h2 {
  font-size: 30px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

/*__________________ about section about paragraph _______________________*/
.about-intern-content p {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  text-align: justify;
}

/*__________________ about section about image container _______________________*/
.about-inter-image {
  flex: 1;
  text-align: left;
  opacity: 0;
  max-width: 500px;
  transform: translateX(50px);
  transition: opacity 0.6s ease-out 0.6s, transform 0.6s ease-out 0.6s;
}

/*__________________ about section about image container animation _______________________*/
.about-inter-image.animate {
  opacity: 1;
  transform: translateX(0);
}

/*__________________ about section about image _______________________*/
.about-inter-image img {
  width: 100% !important;
  height: 50% !important;
  border-radius: none !important;
  box-shadow: none !important;
}

  /*__________________ benefits section _______________________*/
  .benefits_internship{
    width: 100%;
    display: flex;
    background-color: white;
    align-items: center;
    justify-content: center;
  }

  .benefits-section {
    width: 1140px;
    padding: 3rem 2rem;
    text-align: center;
  }
  
   /*__________________ benefits section heading _______________________*/
  .benefits-section h2 {
    font-size: 2rem;
    margin-bottom: 2rem;
    color: #333;
    font-family: "Roboto", sans-serif;
  }
  
   /*__________________ benefits section cards container_______________________*/
  .benefits-cards {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
   /*__________________ benefits section cards _______________________*/
  .benefit-card {
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin: 1rem;
    flex: 1;
    max-width: 300px;
    text-align: center;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
   /*__________________ benefits section card animation _______________________*/
  .benefit-card.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
   /*__________________ benefits section card hover _______________________*/
  .benefit-card:hover{
    background-color: white;
    box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5);
    transform: scale(1.03);
    transition: transform 1s, box-shadow 1s;
    outline: 4px solid #078efb;
  }

   /*__________________ benefits section card hover icon _______________________*/
  .benefit-card:hover .benefit-icon{
    color: #0056b3;
  }
  
   /*__________________ benefits section card icon _______________________*/
  .benefit-icon {
    font-size: 40px;
    color: #007bff;
    margin-bottom: 1rem;
  }
  
   /*__________________ benefits section card heading _______________________*/
  .benefit-card h3 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #333;
    font-family: "Roboto", sans-serif;
  }
  
   /*__________________ benefits section card paragraph _______________________*/
  .benefit-card p {
    font-size: 1rem;
    color: #666;
    font-family: "Roboto", sans-serif;
  }

   /*__________________ Responsive css _______________________*/
  @media (max-width: 1068px) {
    .hero-section {
      flex-direction: column;
      text-align: center;
    }
  
    .hero-content {
      padding-right: 0;
      padding-bottom: 1rem;
    }
  
    .hero-image img {
      width: 80%;
      height: auto;
    }
    
    .about-intern-outside{
      padding: 0;
    }
    .about-intern-container {
      flex-direction: column;
      padding: 20px 0;
      align-items: center;
    }
  
    .about-intern-content {
      padding-right: 0;
      margin-right: 0;
      margin-left: 0;
      margin-bottom: 20px;
      max-width: 90% !important;
      order: -1;
    }
  
    .about-inter-image{
      text-align: center !important;
    }
  
    .about-inter-image img {
      width: 100% !important;
    }

    .benefits-cards {
        flex-direction: column;
        align-items: center;
    }
    
    .benefit-card {
        max-width: 90%;
    }
  }

  @media (max-width: 768px){
    .about-intern-container{
      margin: 0 20px;
    }
  }