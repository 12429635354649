/* Quiz section container */
.quiz-outer-section{
    width: 100%;
    background-color: #f8f9fa;
    display: flex;
    align-items: center;
    justify-content: center;
}
.quiz-section {
    width: 1100px;
    text-align: left;;
    margin: 0 auto;
    padding: 40px;
    font-family: "Roboto", sans-serif;
  }
  .quiz-section p {
    width: 800px;
    font-family: "Roboto", sans-serif;
    font-size: 17px;
    
  }
  
  /* Container for the cards */
  .quiz-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin-top: 20px;
  }
  
  /* Each card design */
  .quiz-card {
    background-color: white;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    padding: 30px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 200px;
    text-align: left;
  }
  

  /* Heading in each card */
.quiz-card h3 {
  font-size: 18px;
  color: black;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box; /* Required for line-clamp */
  -webkit-line-clamp: 2; /* Limits the heading to two lines */
  -webkit-box-orient: vertical; /* Required for the clamp to work */
}

  
  /* Tags inside the card */
  .quiz-tags span {
    display: inline-block;
    background-color: #eaf0f6;
    color: #007bff;
    padding: 5px 10px;
    border-radius: 5px;
    margin: 5px 5px 0 0;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }

  .expires-container{
    margin-top: 60px;
  }


  .quiz-info {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    font-size: 14px;
    font-family: "Roboto", sans-serif;
  }
  
  /* Optional: Add padding or margin adjustments if needed */
  .quiz-info p {
    margin: 0;
  }
  
  .divider {
    border: 0;
    height: 1px;
    background: #e0e0e0;
    margin: 10px 0;
  }
  
  /* Expiry info */
  .expires {
    /* color: red; */
    margin-top: 10px;
    font-weight: bold;
    font-family: "Roboto", sans-serif;
  }
  
  /* Button inside the card */
  .quiz-card button {
    width: 100%;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 15px;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
  }
  
  .quiz-card button:hover {
    background-color: #0056b3;
  }
  
  /* View More button at the bottom */
  .view-more-container {
    margin-top: 40px;
  }
  
  .view-more-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Roboto", sans-serif;
  
  }
  
  .view-more-btn:hover {
    background-color: #0056b3;
  }
  
  /* Responsive adjustments */
  @media (max-width: 900px) {
    .quiz-container {
      justify-content: center;
    }
  }
  
  @media (max-width: 768px) {
    .quiz-card {
      width: 100%;
      max-width: 350px;
    }
  }
  