:root {
  --profileBg: white;
  --profileMgnt: rgba(232, 228, 228, 0.447);
  --profileContainerColor: #f8f8f8 !important;
}

.proflieContainer {
  font-family: "Roboto", sans-serif !important;
  padding: 20px;
  padding: 20px;
}

@media (min-width: 1000px) {
}
.basicOuterBox {
  background-color: var(--profileBg);
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.2) !important;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
@media (min-width: 996px) {
  .basicOuterBox {
    margin-left: -7px !important;
    box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.2) !important;
  }
}

.dropbtnprofilepagesave {
  background-color: #5679d6;
  border: none;
  color: white;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 40px;
  width: 80px;
}
.dropbtnprofilepagecancel2 {
  background-color: #e04b4b;
  border: none;
  color: white;
  padding: 4px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 40px;
  width: 80px;
}

.pmBtnUpdResume {
  border: none;
  color: white;
  background-color: blue;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  padding: 12px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 20px;
  width: auto;
}

/* Student Profile Page .js */
.PPContainer {
  margin-left: 9px;
  margin-right: 17px;
  margin-top: -10px;
  /* border:2px solid red; */
}

@media (max-width: 790px) {
  .PPContainer {
    margin-left: 15px;
    margin-right: 16px;
  }
}
.profiledetails > * {
  color: black !important;
}
.PPImageOuterContainer {
  display: flex; /* Enable flexbox layout */
  align-items: flex-start; /* Align items at the start (optional) */
}

.ppImgContainer {
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  justify-items: center;
  border-right: 2px solid rgb(192, 192, 192);
  margin: auto;
  height: auto;
}

@media (max-width: 783px) {
  .PPImageOuterContainer {
    width: 100% !important;
    justify-content: center !important;
  }
  .ppImgContainer {
    border-right: none;
  }
}
@media (min-width: 783px) {
  .ppImgContainer {
    padding-right: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .PPImageOuterContainer {
    border-right: 2px solid rgba(208, 203, 203, 0.923);
  }
}
@media (min-width: 1281px) {
  .pmNamefieldsSeparator {
    border-left: 2px solid rgb(192, 192, 192);
    padding-left: 25px;
  }
}

@media (max-width: 1278px) {
  .ppImgContainer {
    max-width: 100%;
    padding: 8px; /* Adjust padding for smaller screens */
    justify-content: center;
  }
}
.ppImgContainer2 {
  width: 160px;
  height: 180px;
  /* border: 1px solid rgb(146, 70, 70); */
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 10px;
}

.PPfont-size-and-alignment-and-font-family {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* text-align: center; */
  color: black;
  flex-wrap: wrap;
}
.PPfont-size-and-alignment-and-font-family1 {
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* text-align: center; */
  color: black;
  flex-wrap: wrap;
}

.bsDeatilContainer {
  width: 100%;
}
.pmProgressContainer {
  height: 160px;
  width: 160px;
}
.pmProgressContainer1 {
  height: 160px;
  width: 160px;
  border-radius: 50%;
  position: absolute;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  transform: rotate(180deg);
}

.ppProfileImage {
  width: 92%;
  height: 92%;
  /* max-height: 150px; */
  border-radius: 50%;
  /* transform: rotate(180deg); */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  position: relative;
  z-index: 2;
  margin-left: 6.5px;
  margin-top: 13px;
}
.ppPercentage_50 {
  color: #ff0000;
  /* transform: rotate(180deg); */
}
.ppPercentage_70 {
  color: #fca607;
  /* transform: rotate(180deg); */
}
.ppPercentage_90 {
  color: #b0d02efb;
  /* transform: rotate(180deg); */
}
.ppPercentage_100 {
  color: #00ff00;
  /* transform: rotate(180deg); */
}

.ppPercentageText {
  position: relative;
  top: -5%;
  left: 2%; /* transform: rotate(180%) !important; */

  background-color: white;
  font-weight: bold;

  width: 65px;
  padding: 5px;
  text-align: center;
  border-radius: 40%;
  z-index: 4;
}

.smPPpage {
  justify-content: center;
  display: flex;
}
.ppNameContainer {
  margin: 0 auto;
  text-align: center;
  padding-top: 10px;
  width: 90%;
}
.ppMobBasicDetails {
  padding-left: 20px;
  font-weight: bold;
  color: black;
}
.ppModal {
  width: 1000px;
  position: relative;
}
.PPBasicRow2 {
  display: flex;
  justify-content: space-between;
  gap: 2px;
}
.PPBasicPart1 {
  width: 50%;
}
.PPBasicPart2 {
  width: 50%;
}

@media screen and (max-width: 690px) {
  .PPBasicRow2 {
    display: flex;
    flex-direction: column;
  }
  .PPBasicPart1 {
    width: 100%;
  }
  .PPBasicPart2 {
    width: 100%;
  }
}

.pmNamefields > * {
  color: black;
  padding-left: 15px;
}

.pmDetailContainer {
  margin: 10px;
  margin-left: 25px;
  /* padding-top: 10px; */

  flex-grow: 1; /* Take up the remaining space */
}
.pmVerifiedIcon {
  color: rgb(20, 205, 20);
  font-size: 16px !important;
  margin-left: 10px;
}
.pmCompanyName {
  /* font-weight: bold; */
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black;
}
.pmBasicIcons {
  color: black;
  font-size: 19px !important;
  margin-right: 10px;
}
/*________Profile Mgmt Common_______ */
.pmEditIcon {
  color: blue;
  font-size: 20px !important;
}

.pmEditIcon2 {
  /* color: #256bec; */
  color: blue;
  font-size: 15px;
  /* padding: 10px; */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 11px;
  padding-bottom: 9px;
  &:hover {
    border-radius: 50%;
    background-color: rgba(234, 235, 235, 0.894);
  }
}

.pmDeleteIcon {
  color: red;
  margin-left: 8px;
  font-size: 12px !important;
  cursor: pointer;
  padding: 5px;
  &:hover {
    border-radius: 50%;
    background-color: rgba(210, 210, 206, 0.696);
    padding: 5px;
  }
}

.pmLabel {
  font-weight: bold;
  color: black;
  /* border: 1px solid red; */
}

.pmLabel-and-container-gap {
  display: grid;
  gap: 4px;
}

.pmLabel-and-container-gap1 {
  display: grid;
  gap: 8px;
  /* border: 1px solid red; */
  margin-bottom: 14px;
}

.pmLabel-and-container-gap2 {
  display: grid;
  gap: 1px;
  /* border: 1px solid red; */
  margin-bottom: 14px;
}

.pmShowMoreColor {
  color: blue;
  cursor: pointer;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
}

.pmRemderListTitle {
  font-weight: bold;
  color: black;
}

.pmAddEmpBtn {
  color: blue;
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    text-underline-offset: 5px;
    text-decoration-thickness: 2px;
  }
}
/*________Profile Basic Detail Modal_______ */
.pmBasicModalContainer {
  margin-left: 20px;
  margin-right: 20px;
}

.pmBasFields {
  /* background-color: yellow; */
  width: 50%;
}

.pmBasFields1 {
  width: 50%;
}

@media screen and (max-width: 750px) {
  .pmModField {
    min-width: 100%;
    padding-bottom: 22px;
  }
  .pmBasFields {
    /* background-color: yellow; */
    width: 100%;
    padding-bottom: 25px;
  }
  .pmBasFields1 {
    width: 100%;
  }
}

.experience-container {
  width: 100%;
  margin-bottom: 1px;
  /* border: 1px solid red; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.experience-fields {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .experience-fields {
    flex-direction: row;
    gap: 16px; /* Optional: adds space between the fields */
  }
}

/*________Profile Resume container_______ */

.pmResumeNameContainer {
  width: 97%;
  margin-top: -14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  margin-bottom: 5px;
}
.pmResumeDownload {
  cursor: pointer;
  color: green;
  font-size: 20px !important;
}
.pmResumeDelete {
  cursor: pointer;
  color: rgb(220, 26, 4);
  font-size: 20px !important;
}
.pmResumeName {
  color: black;
  font-weight: bold;
  font-size: 12px;
}
.pmResumeDim {
  color: #98a3a3;
  font-weight: 300; /* You can use 100, 200, or 300 depending on the desired thinness */
  font-family: "Arial", sans-serif;
  font-size: 10px;
}

/*________Profile Resume Headine Render_______ */

.pmresume-heading-container {
  padding: 10px;
}

.resume-headline-modal-text-filed-alignment {
  padding: 10px;
  padding-top: 0px;
}

.pmUpdResumeContainer {
  border: 2px dashed #7a9fbf;
  padding-left: 20px;
  margin-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  align-content: center;
  border-radius: 10px;
}

.pmTabHeadings2 {
  color: black;
  font-size: 1.3rem;
  font-weight: bold;
}

.pmTabtagline {
  line-height: 25px;
  margin-top: 8px;
}

.pmTabHeadings-basic-details {
  color: black;
  font-size: 1.5rem;
  font-weight: bold;
  /* text-align: center; */
  /* border: 1px solid red; */
  padding: 10px;
  margin-top: -5px;
  margin-left: -7px;
}
.pmTabHeadings {
  color: black;
  font-size: 1.3rem;
  font-weight: bold;
  /* border: 2px solid red; */
  margin-top: 1px !important;
  padding: 5px;
  /* padding-bottom: 15px; */
}
.pmcent {
  font-weight: bold;
}
@media screen and (max-width: 500px) {
  .pmcent {
    text-align: center;
  }
  .pmcent2 {
    text-align: center;
    margin-left: -15px;
  }
  .PPfont-size-and-alignment-and-font-family1 {
    justify-content: center;
  }
}
@media screen and (max-width: 782px) {
  .pmcent2 {
    text-align: center;
    margin-left: -15px;
  }
}

.pmModalContent {
  padding: 25px;
}

.empJobProfile {
  width: 800px;
  margin-bottom: 20px;
}

/*________Profile Employment Render_______ */
.pmEmpOuterContainer {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  align-items: center;
    flex-wrap: wrap;
}
.pmEmpCompanyName {
  color: black;
  font-weight: 500;
}
.pmProlifeUserData {
  margin-top: 13px;
}
.pmDimDuration {
  color: #98a3a3;
  font-weight: 300; /* You can use 100, 200, or 300 depending on the desired thinness */
  font-family: "Arial", sans-serif;
}

.pmEmpSkillRender {
  color: black;
}
.pmModHeader2 {
  display: flex;
  justify-content: space-between;
}
.EmploymentModal_section {
  margin-left: 5px;
  margin-right: 5px;
}

/*________Profile KeySkills Render_______ */

.chip-background-color-and-alignment {
  background-color: #1d88fa !important;
  color: white !important;
  margin-bottom: 5px;
  margin-top: 5px;
}

.chip-container-render {
  padding: 5px;
}

.pmBtnkskills1 {
  background-color: transparent;
  border: 1px solid rgb(131, 128, 128);
  color: rgb(5, 5, 5);
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 14px;
  margin: 6px 8px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;

  cursor: pointer;
  border-radius: 40px;
  width: auto;
}

.AddKeyskillContainer {
  margin-left: 20px;
  margin-right: 30px;
}

.pmKeySkillChips {
  color: white !important;
  font-weight: bold;
  gap: 10px;
  background-color: rgb(36, 67, 203) !important;
}

/*________Profile IT Skills Render_______ */
.tableContainer {
  /* border: 2px solid black; */
  border-radius: 15px;
  display: inline-block;
  overflow-x: scroll;
  width: 100%;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.tableContainer::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.tableData {
  text-align: center;
  border-collapse: collapse; /* Apply collapse to the table */
  width: 100%;
  /* Ensure the table takes up the full width of the container */
}

.tableinnerData > * {
  padding: 10px;
}
.tableinner_first_child {
  text-align: left;
}
.pmtableHeaderonly {
  border-bottom: 3px solid gray;
  overflow: hidden;
}

/*________Profile ProfileSummary Render_______ */
.pmSummaryData {
  font-weight: bold;
  color: black;
  font-size: 14px;
}
/*________Profile Pers.Details Render_______ */
.pmDataLabel {
  font-weight: 500;
  color: rgb(0, 0, 0);
  font-size: 16px;
}
/*________ Profile Modal  MUI  Box / Container _______ */

.pm-personal-data-text-alignment {
  color: #6b6b6b;
  font-size: 14px;
}
.pmMuiModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 700px;
  background-color: rgb(255, 255, 255);
  border: none;
  border-radius: 10px;
  padding: 10px;
  /* height: 90vh; */
  outline: none;
}
.pmMuiModalBox2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  width: 500px;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 30px;
  max-height: 72vh;
}

.pmMuiProfilePicModalBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  background-color: white;
  border: none;
  border-radius: 20px;
  padding: 30px;
  height: auto;
  max-height: 95vh !important;
}

.pmModParaText {
  color: gray;
  font-size: 14px;
  line-height: 20px;
}

.pmmodElement {
  margin-bottom: 17px !important;
  /* background-color: yellow !important; */
}

.profileModal {
  padding: 10px;
}
.pmModalContainer {
  overflow-y: auto;
  display: grid;
  gap: 18px;
  padding-right: 10px;
  max-height: 67vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* border: 1px solid red; */
  margin-top: 10px;
  padding-top: 7px;
  margin-right: 10px;
}

.pmModalContainer-it-skills {
  overflow-y: auto;
  display: grid;
  gap: 18px;
  padding-right: 10px;
  max-height: 65vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* border: 1px solid red; */
  margin-top: 6px;
  margin-right: 10px;
}

.pmModalContainer-project {
  overflow-y: auto;
  display: grid;
  gap: 18px;
  padding-right: 10px;
  max-height: 65vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* border: 1px solid red; */
  padding: 10px;
  padding-left: 10px;
  margin-right: 10px;
}

.pmModalContainer-carrer-profile {
  overflow-y: auto;
  display: grid;
  gap: 18px;
  padding-right: 14px;
  max-height: 65vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* border: 1px solid red; */
  padding: 10px;
  margin-right: 10px;
}
/* Make sure the scrollbar is visible */
.pmModalContainer-it-skills::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.pmModalContainer-it-skills::-webkit-scrollbar-thumb {
  background-color: #bbbaba; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.pmModalContainer-it-skills::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

.pmModalContainer-it-skills::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px;
}

/* Make sure the scrollbar is visible */
.pmModalContainer-project::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.pmModalContainer-project::-webkit-scrollbar-thumb {
  background-color: #bbbaba; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.pmModalContainer-project::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

.pmModalContainer-project::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px;
}

/* Make sure the scrollbar is visible */
.pmModalContainer::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.pmModalContainer::-webkit-scrollbar-thumb {
  background-color: #bbbaba; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.pmModalContainer::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

.pmModalContainer::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px;
}

/* Make sure the scrollbar is visible */
.pmModalContainer-carrer-profile::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.pmModalContainer-carrer-profile::-webkit-scrollbar-thumb {
  background-color: #bbbaba; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.pmModalContainer-carrer-profile::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

.pmModalContainer-carrer-profile::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px;
}

.pmRadioModal {
  padding-left: 25px;
}
.error_Pmlabel {
  color: red !important;
}
/* __________________________________________________________ */

.deleteIconAtpmModal {
  position: absolute;
  right: 55px;
  top: 20px;
}

/* Edit Modal delete icon */
.pmEDDeleteIcon {
  color: red;
  margin-right: 40px;
  font-size: 16px !important;
  cursor: pointer;
  padding: 15px;
  &:hover {
    border-radius: 50%;
    background-color: rgba(210, 210, 206, 0.696);
    padding: 15px;
  }
}
/* ________ Profile Career Profile Modal ___________  */
.pmChkBoxes {
  max-width: 200px;
}

.pmmodal-career-profile-container {
  /* border: 1px solid red; */
  margin-top: -22px;
  margin-bottom: -20px;
  padding-bottom: -10px;
}
/* ________ Profile Personal Details Modal ___________  */

.pmModalContainer-personal-details {
  padding-right: 10px;
  max-height: 66vh;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  /* border: 1px solid red;  */
  margin-right: 10px;
}

/* Make sure the scrollbar is visible */
.pmModalContainer-personal-details::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.pmModalContainer-personal-details::-webkit-scrollbar-thumb {
  background-color: #bbbaba; /* Color of the scrollbar thumb */
  border-radius: 6px; /* Roundness of the scrollbar thumb */
}

.pmModalContainer-personal-details::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb when hovered */
}

.pmModalContainer-personal-details::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Color of the scrollbar track */
  border-radius: 6px;
}

.pm-personal-details-textfileds-alignment {
  margin-bottom: 5px;
}

.pm-personal-details-container {
  padding-left: 10px;
  display: grid;
  gap: 10px;
  /* border: 1px solid red;  */
}
.pmPersonalTableHeader {
  text-align: center;
  color: black !;
  font-weight: bold !important;
  text-align: left;
}
.pmPersonalTableContent {
  text-align: center;
  /* border: 2px solid red; */
  text-align: left;
}

.pmPersDetailBtn {
  padding: 10px;
  border-radius: 25px;
  padding-right: 15px;
  padding-left: 15px;
  background-color: rgba(255, 255, 255, 0.352);
  margin-right: 25px;
  margin-top: 10px;
  border: 2px solid rgb(216, 216, 216);
}

.pmSelectedbtn {
  background-color: rgba(219, 224, 225, 0.971);
  color: black;
  /* font-weight: bold; */
  background-color: #1d88fa !important;
  color: white !important;
  border: none;
}

.PmPersonalLang {
  margin-bottom: 10px;
  align-items: center;
}
.PmPersonalLangAdd {
  width: 200px;
  color: blue;
  cursor: pointer;
}
.PmPersonal_DeleteLang {
  color: red;
  cursor: pointer;
}

/*________Profile Picture  Modal  _______ */

.profilePicModalFooter {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  margin-right: 20px;
}

/*________Key Skill Add Modal  _______ */
/* app.css */
.pmModalAddKeyskillPlusBtn {
  background-color: rgb(187, 179, 179);
  border-radius: 50%;
  color: rgb(31, 159, 31);
  font-size: 25px;
  /* padding: 6px; */
}

.saveButton {
  background-color: green; /* Customize the success color */
  color: white;
}

.saveButton:hover {
  background-color: darkgreen; /* Customize the dark success color */
}

/*________add Project Modal  _______ */

.pmLabel-data-alignment {
  margin-top: 5px;
  /* border: 1px solid red; */
}

@media (max-width: 635px) {
  .pmWorkTill {
    margin-top: 25px;
  }
}
/*________add Project Modal end _______ */

/*________add Employment Modal  _______ */

.PPEmpJobProfilecustomInput {
  overflow: auto;
  padding: 8px;
  border-radius: 4px;
}

.PmRadioView {
  display: flex !important;
  flex-direction: row !important;
}
.PmRadioItem {
  min-width: 150px !important;
  margin-left: 5px !important;
}
/*________add Employment Modal end _______ */

/* Add your other CSS styles as needed */

.showProfilePicEditorIcon {
  color: blue;
  background-color: white;
  border-radius: 50%;
  transition: filter 0.3s ease;
  /* transform: rotate(180deg); */
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 11px;
  padding-bottom: 9px;
  z-index: 4;
  position: relative;
}

.hideProfilePicEditorIcon {
  color: transparent;
  font-size: 15px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 11px;
  padding-bottom: 9px;
  border-radius: 50%;
  background-color: transparent;
}

.profileImageWrapper {
  position: relative;
  &:hover {
    border-radius: 50%;
  }
}

.profileImageWrapper:hover img {
  filter: brightness(70%);
}
.ppProfileImage-text {
  width: 92%;
  height: 92%;
  /* max-height: 150px; */
  border-radius: 50%;
  /* transform: rotate(180deg); */
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  position: relative;
  z-index: 2;
  margin-left: 6px;
  margin-top: 12px;
  font-size: 80px;
  background-color: #f0f0f0;
}

.profileImageWrapper:hover .ppProfileImage-text {
  background-color: #7d7b7b;
}

.editIconWrapper {
  position: absolute;
  bottom: 55px;
  right: 60px;
}

/* _____ Profile Management table  and Personal detail ______ */

.pmPersonalDetailTable {
  padding: 10px;
  border-radius: 10px !important;
}
.pmPersonalDetailTablehrd {
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 3px solid gray;
  color: black;
  padding-left: 20px !important;
  padding: 10px;
}
.pmPersonalDetailTableData {
  padding: 10px;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;
  color: black;
  /* border-bottom: 3px solid gray; */
}
.modal-close-iconButton-right-top-corner-symbol {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px !important;
}
.modal-close-iconButton-right-top-corner {
  position: absolute !important;
  top: -15px;
  right: -13px;
}

.createteslayoutmodel6 {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 18px;
}

.createteslayoutmodel6-resume-headline {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 9px;
  margin-bottom: 5px;
}

.createteslayoutmodel6-add-employment {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-right: 0px;
  margin-right: 7px;
}

.createteslayoutmodel6-skills-modal {
  display: flex;
  justify-content: flex-end;
  margin-top: 18px;
  margin-right: 9px;
}

.PMradio_group {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Optional: adds space between the radio buttons */
}

.PMradio_group1 {
  display: flex;
  flex-wrap: wrap;
  gap: 16px; /* Optional: adds space between the radio buttons */
  margin-bottom: 10px;
}

@media (max-width: 690px) {
  .PMradio_group {
    /* flex-direction: column; */
    flex-wrap: wrap;
  }
}

.PMCarProfileRender {
  display: flex;
  flex-direction: column;
  padding-left: 4px;
}

@media (min-width: 768px) {
  .PMCarProfileRender {
    flex-direction: row;
    padding-bottom: 25px;
  }
}
.buttonicons {
  font-size: 17px !important;
  margin-right: 4px;
  cursor: pointer;
  margin-top: -2px !important;
  color: white !important;
}

.proflieContainer-background-color {
  background-color: #fafbff;
}

.profile-container-widh-container {
  max-width: 950px;
  margin: auto;
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 25px;
}

.experience-container1 {
  width: 100%;
  margin-bottom: 1px;
  /* border: 1px solid red; */
  padding-top: 5px;
  padding-bottom: 5px;
}

.experience-fields1 {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: flex-start;
}

.pmBasFields2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}

@media (min-width: 789px) {
  .experience-fields1 {
    flex-direction: row;
    gap: 16px;
  }
  .pmBasFields2 {
    width: 100%;
    padding-top: 10px;
  }
}

.employment-form-from-and-to-date {
  padding-bottom: 20px;
}

.from-data-and-to-date {
  padding-top: 20px;
}

.add-language-alignment-container {
  padding: 3px;
}


@media screen and  (max-width: 400px) {

.PPfont-size-and-alignment-and-font-family{
  font-size: 12px;
  
}
}