.test-series-outer-container{
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    background-color: #f8f9fa;
    /* margin-left: 30px; */
    padding-left: 20px !important;
    padding-right: 0 !important;
    /* max-width: 1140px; */
}

.card-heading h2{
  color: black;
  font-family: "Roboto",sans-serif;
  font-size: 24px;
}

.test-series-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 20px;
    margin: 20px;
    width: 100%; 
    max-width: 1140px;
  }

  .carousel-container {
    width: 100%; 
    max-width: 890px;
    margin: 0 auto;
  }
  
  .carousel-item-padding-40-px {
    padding: 1px;
  }
  
  .test-series-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 220px;
    padding: 30px;
    transition: transform 0.2s;
    position: relative;
    height: 400px;
    /* margin-left: 90px; */
  }
  
  /* Header with logo and users */
  .test-series-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .test-logo {
    width: 40px;
    height: 40px;
  }
  
  .user-count {
    background-color: #f8f9fa;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Positioning content in fixed places */
  .test-content {
    flex-grow: 1;
  }
  
  .test-title-container{
    min-height: 60px;
  }
  .test-title {
    font-size: 18px;
    margin: 10px 0;
    font-family: "Roboto",sans-serif;
  }
  
  .test-total-free {
    font-size: 14px;
    margin-bottom: 10px;
    height: 30px;
    font-family: "Roboto",sans-serif;
  }
  
  .test-languages {
    font-size: 14px;
    margin-bottom: 10px;
    font-family: "Roboto",sans-serif;
  }
  
  .test-breakdown ul {
    /* list-style-type: none; */
    padding-left: 0;
    font-family: "Roboto",sans-serif;
    font-size: 15px;
  }
  
  .test-breakdown li {
    margin: 5px 0;
    /* padding-right: 10px; */
  }
  
  /* Divider styling */
  .divider {
    border: 0;
    height: 1px;
    background: #e0e0e0;
    margin: 10px 0;
  }
  .test-logo {
    width: 40px;
    height: 40px;
  }
  
  .test-avatar {
    background-color: #007bff; /* Set a background color */
    color: white; /* Avatar text color */
    font-size: 1.5rem; /* Adjust the size of the avatar font */
    width: 40px;
    height: 40px;
  }
  
  .user-count {
    background-color: #f8f9fa;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Roboto",sans-serif;
  }
  
  
  
  /* View button pinned to bottom */
  .view-series-btn {
    padding: 12px 20px;
    margin-right: 20px;
    text-decoration: none;
    color: white;
    background-color: #007bff; /* Change to a bold color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  }
  
  .view-series-btn:hover {
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  
  .view-more-container {
    display: flex;
    justify-content: center;  /* Center the button */
    margin-top: 20px;  /* Add spacing between cards and the button */
    width: 100%;  /* Ensure it takes full width */
}

/* Button styling */
.view-more-btn {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.2s;
}

.view-more-btn:hover {
    background-color: #0056b3;
}
.carousel-arrow {
  background-color: transparent; /* Transparent background */
  border: none; /* Remove border */
  color: #f6f6f6; /* Change arrow color */
  font-size: 17px; /* Arrow size */
  cursor: pointer; /* Pointer cursor */
  position: absolute; /* Position absolutely */
  top: 0%; /* Center vertically */
  transform: translateY(-50%); /* Adjust for vertical centering */
  z-index: 1; /* Bring to the front */
  transition: color 0.3s; /* Smooth transition for hover */
}

.left-arrow {
    left: 10px; /* Position to the left */
    height: 40px;
    width: 40px;
    border-radius: 50%;
    margin-top: 210px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    opacity: 1 !important;
    border: 1px solid #6a6f73;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08)
}

.right-arrow {
  right: 10px; /* Position to the right */
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-top: 210px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  opacity: 1 !important;
  border: 1px solid #6a6f73;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08)
}

/* Custom Avatar styles */
.avatar {
  width: 40px !important; /* Avatar width */
  height: 40px !important; /* Avatar height */
  background-color: #1976d2 !important; /* Avatar background color */
  color: white; /* Avatar text color */
  font-weight: bold; /* Bold text for initials */
  display: flex; /* Flex layout for centering text */
  justify-content: center; /* Center text horizontally */
  align-items: center; /* Center text vertically */
  font-size: 20px; /* Font size for initials */
  border: 2px solid #ffffff; /* Optional border to separate it from the background */
}

  @media (max-width: 1102px) {
    .test-series-container {
      justify-content: center;
    }
  }

  @media (max-width: 834px) {
    .test-series-outer-container{
        padding-left: 0 !important;
    }
    
  }

  @media (max-width: 1100px){
    .test-series-card{
        width: 200px;
    }
  }
  @media (max-width: 916px){
    .test-series-card{
        width: 300px;
    }
  }

  @media (max-width: 897px){
    .test-series-card{
        width: 300px;
    }
  }

  @media (max-width: 802px){
    .test-series-card{
        width: 280px;
    }
  }

  @media (max-width: 743px){
    .test-series-card{
        width: 250px;
    }
  }

  @media (max-width: 600px) {
  .test-series-outer-container {
    padding: 10px; /* Adjust padding to fit smaller screens */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .carousel-container {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .test-series-card {
    width: 100%; /* Allow the card to take full available width */
    max-width: 350px; /* Set the card width to match the container */
    margin: 0 auto; /* Center the card inside the carousel */
  }
}

  @media (max-width: 643px){
    .test-series-card{
        width: 200px;
    }
  }
  
  @media (max-width: 555px) {
    .test-series-card{
        width: 350px;
    }
    .test-series-container {
      justify-content: center;
    }
  }

  @media (max-width: 412px){
    .test-series-card{
        width: 300px;
    }
  }
  
  @media (max-width: 1100px) {
    .test-series-card {
      width: 280px;
    }
  }
  
  @media (max-width: 768px) {
    .test-series-card {
      width: 240px;
    }
  }
  
  @media (max-width: 576px) {
    .test-series-card {
      width: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .test-series-card {
      width: 180px;
    }
  }


  