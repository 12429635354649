/*__________________first_component_outside_______________________*/
.comp_2 {
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.first_Component_Outside {
  width: 1140px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding: 0 30px;
  padding-top: 30px;
}

/*__________________first_component_inside_______________________*/
.first_Component_Inside {
  width: auto;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

/*__________________first_component_image_______________________*/
.glassImage {
  width: 100%;
  height: 123%;
  object-fit: cover;
  display: block;
  overflow: clip;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

/*__________________first_component_image_animation_______________________*/
.image-loading {
  animation: image-loading-animation 0.5s ease-in-out 1;
}

@keyframes image-loading-animation {
  0% {
    transform: scale(1.2);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/*__________________first_component_second_grid_______________________*/
.second_grid {
  width: 590px;
  background-color: transparent;
  margin: 0;
  padding-left: 50px;
}

.teach-image{
  height: auto;
  width: 80%;
  object-fit: cover;
  display: block;
  overflow: clip;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

/*__________________first_component_second_grid_inside_div_______________________*/
.grid_first {
  width: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 15px;
  flex-shrink: 0;
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
}

/*__________________first_component_second_grid_content_______________________*/
.content_1 {
  height: 80px;
  text-align: justify;
  width: 550px;
}

.content2{
  margin-bottom: 15px;
}

.content_1 h2{
  margin-bottom: 5px;
}

.content_1 p {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  margin-top: 0;
}

/*__________________component 3 outside div______________________*/
.comp_3 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
}
.component_3_outside {
  width: 1140px;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  padding: 30px 30px;
  padding-left: 80px;
}

/*__________________component 3 grid div______________________*/
.component_3_grid_div {
  width: auto;
  display: grid;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 60px;
}

/*__________________component 3 grid div______________________*/
.content_grid_3 {
  width: 610px;
  gap: 50px;
  padding: 0 40px;
  height: 350px;
}

/*__________________component 3 image div______________________*/
.comp_3_img {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  /* margin-left: 30px; */
  /* padding-top: 20px; */
  height: 350px;
}

.env_img {
  width: 100%;
  height: auto;
}

/*__________________component 4 outside div______________________*/
.component_4_outside_div {
  margin-top: 1px;
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background: white;
}

/*__________________component 4 inside div______________________*/
.component_4_inside_div {
  gap: 30px;
  width: 1140px;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: row;
}

/*__________________component 4 grid div______________________*/
.component_4_grid_div {
  width: 100%;
  display: grid;
  align-items: start;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr;
}

/*__________________component 4 first grid div______________________*/
.component_4_first_grid {
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

/*__________________component 4 firts grid div heading______________________*/
.component_4_first_grid_heading {
  height: 25px;
  font-size: 2.5rem;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
  line-height: 1.5;
}

/*__________________component 4 first grid div paragraph______________________*/
.component_4_first_grid_paragraph {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
}

/*__________________Apply Now button______________________*/
.apply_button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  cursor: pointer;
}

.apply_button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4) !important;
}

/*__________________FAQ component______________________*/
.Faq-section-background-color {
  background-color: #ffffff;
  height: auto;
  padding-top: 30px;
}

.Faq-section-conatiner-width-conatiner {
  max-width: 1140px;
  margin: auto;
}

.Faq-section-heading-alignemnt {
  margin: auto;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 2rem;
  padding-left: 20px;
}

.Faq-section-flex-container {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.Faq-section-left-sction {
  flex: auto;
  padding-top: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 400px;
  width: -webkit-fill-available;
  padding-bottom: 2px;
}
.Faq-section-right-sction {
  max-height: 438px;
  max-width: 440px;
  
}
.Faq-section-right-sction img {
  margin:auto;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 930px) {
  .Faq-section-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }
}


/*__________________Responsive of all component______________________*/
@media (max-width: 1168px) {
  .comp_2 {
    height: auto;
    padding-top: 0;
  }
  .first_Component_Outside {
    height: auto;
  }
  .first_Component_Inside {
    grid-template-columns: 1fr;
    grid-gap: 20px;
    margin-left: 25px;
    margin-bottom: 20px;
  }

  .glassImage {
    width: 80%;
    height: auto;
    margin-left: 40px;
  }

  .second_grid {
    width: 90%;
    height: auto;
    padding-top: 0;
  }

  .grid_first {
    width: 85%;
    height: auto;
    gap: 20px;
    font-size: 15px !important;
    padding-right: 30px;
  }

  .content_1 {
    height: auto;
    width: 100%;
  }
  .content_1 h2 {
    font-size: 20px;
  }
  .content_1 p {
    font-size: 16px;
  }

  .teach-image{
    width: 75%;
    margin-left: 60px;
  }

  .component_3_outside {
    height: auto;
    padding-left: 0;
  }

  .component_3_grid_div {
    margin-left: 25px;
    grid-template-columns: 1fr;
    grid-gap: 0;
    width: 100%;
  }

  .content_grid_3 {
    order: -1;
    width: 85%;
    margin-left: 30px;
    margin-top: 100px;
    height: auto;
  }

  .comp_3_img {
    width: 60%;
    height: auto;
    margin-left: 100px;
  }

  .env_img {
    width: 100%;
    margin-left: 0;
    text-align: center;
  }

  .component_4_inside_div {
    flex-direction: column;
    width: 85%;
    padding-top: 50px;
  }

  .component_4_grid_div {
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }

  .component_4_first_grid {
    width: 85%;
  }

  .component_4_first_grid {
    padding: 0;
  }

  .FAQ_grid {
    margin-left: 25px;
    grid-template-columns: 1fr;
    margin-bottom: 40px;
  }

  
.Faq-section-left-sction{
  order: -1;
  margin: 15px 0 ;
}

.Faq-section-background-color{
  height: auto;
}
}

@media (max-width: 768px) {
  .content_1 {
    height: auto;
  }

  .content_grid_3{
    margin-top: 50px;
    padding: 0;
  }

  .comp_3_img{
    width: 100%;
    margin-left: 0;
  }

  .first_Component_Inside{
    align-items: center !important;
  }

  .glassImage{
    width: 100%;
    margin-left: 0;
  }

  .env_img{
    width: 80%;
  }
  .second_grid{
    padding: 0;
    width: 100%;
  }
  .component_4_inside_div {
    flex-direction: column;
    margin-top: -50px !important;
  }

  .component_4_first_grid_heading {
    font-size: 25px !important;
  }

  .component_4_first_grid_paragraph {
    font-size: 17px;
  }

  .component_4_first_grid {
    width: 85%;
  }

  .component_4_cards {
    width: 85%;
  }

  .component_4_first_cards
    .component_4_second_card
    .component_4_third_card
    .component_4_fourth_card {
    width: 400px !important;
  }
}

@media (max-width: 568px){

  .component_4_outside_div{
    padding: 0;
  }
  .content_grid_3{
    margin-top: 0;
  }

  .grid_first{
    width: 100%;
  }

  .content_1 p{
    margin: 0;
  }

  .content_grid_3{
    width: 85%;
  }

  .teach-image{
    margin-left: 40px;
  }

  .comp_3_img{
    margin-left: 0;
  }
}

@media (max-width: 400px){
  .component_3_grid_div{
    margin-left: 0;
  }

  .first_Component_Outside{
    padding-left: 10PX;
  }
}
