:root {
  --control_btn_border:rgb(216, 216, 223);
}

.controlButtons {
  margin-left: 20px !important;
  margin-right: 20px !important;
  padding: 10px;
  border: 2px solid var(--control_btn_border);
}
.controlButtons > *{
    margin-left: 3px;
    background-color: transparent;
    color:black;
    border: none;
    font-size: 14px ;
}

.editor{
  border: 2px solid  #ccc;
  font-family: '"Georgia", "Segoe UI", Arial, sans-serif';
  font-weight: 400;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 500px;
  padding: 5px;
  overflow-y: auto;
}
.editor:focus {
  outline: none;
  border-color: #007FFF;
  border-radius: 5px;
}

.editorOuter{
  border-color: #007FFF !important;

}





    .editor::-webkit-scrollbar {
      width: 16px;
      height:1px;
    }

    .editor::-webkit-scrollbar-thumb {
      background-color: #6a78d9;
      height:20px;
      border-radius: 3px;
    }
    ::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }



.colorSelector {
  display: flex;
  flex-wrap: wrap;
}






/* __________________________________Rakshith Added CSS _____________________________________________ */

.text_editor_Main_container {
  border: 2px solid #ccc;
  border-radius: 10px;
  margin: 10px;
  position: relative;
}

/* ________________ toolbar   _________________________*/
.toolbar {
  display: flex;
  flex-wrap: nowrap !important;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.buttonDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: auto;
  margin: 0 1px;
  padding: 8px;
  flex-wrap: wrap;
}


.ctrlActive {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  padding: 4px !important;
}

.separator {
  width: 2px;
  height: 30px;
  background-color: #ccc;
  margin: 0 3px;
}

.buttonDiv button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 4px;
  margin: 4px;
  transition: background-color 0.3s ease;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.ctrlActive:hover {
  background-color: #95a5a6;
}
.ctrlActive.active {
  background-color: #ddd;
}

ul.custom-bullets {
  list-style-type: disc;
}

ul.custom-bullets li {
  margin-left: 20px;
}


/*____________________Heading________________________________*/
.Heading{
  margin: 10px 10px 10px 10px;
}


/* ________________ context_toolbar   _________________________*/

.context_toolbar {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  display: flex;
  padding: 5px 3px;
  margin: 10px;
  border-radius: 12px;
  justify-content: space-around;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s;
  width: 50;
  height: 40;
}

.context_toolbar > * {
  margin: 0 8px;
  padding: 5px 7px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fafafa;
  color: #333;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  transition: background-color 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.context_toolbar > *:hover {
  background-color: #e0e0e0;
  border-color: rgba(0, 0, 0, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.context_toolbar > *:active {
  background-color: #d5d5d5;
  border-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 600px) {
  .context_toolbar {
    margin: 5px;
    border-radius: 5px;
    flex-wrap: wrap;
  }
}
/*________________________________ContentEditor___________________________*/
.before_editor{
  margin: 10px 10px 10px 10px !important;
}
.Content-editor {
  border: 1px solid #ccc !important;
  max-height: 500px !important;
  padding: 10px !important;
  overflow: auto !important;
  color: black;
}

.Content-editor:active {
  border-color: #055be5 !important;
  outline: none !important;
  border-radius: 1px;

}

.Content-editor:focus {
  border: 2px solid #055be5 !important;
  outline: none !important;
  border-radius: 1px;
}

.Content-editor::-webkit-scrollbar {
  width: 10px;
  height:1px;
}

.Content-editor::-webkit-scrollbar-thumb {
  background-color: #9850e0;
  height:20px;
  border-radius: 3px;
}


/* ________________ Content Image Editor   _________________________*/

.editor-image-container {
  position: relative !important;
  display: inline-block !important;
  overflow: hidden !important;
  user-select: none !important;
}

.editor-image-container img {
  width: 100% !important;
  height: auto !important;
}

.editor-image-container.selected {
  border: 4px solid rgb(195, 87, 225) !important;
}

/*____________________colorPicker________________*/

.colorPicker {
  position: absolute;
  z-index: 1000;
  margin: 350px 0 0 580px;
}

.color-picker {
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.color-picker-popover {
  position: absolute;
  left: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.color-picker-color {
  width: 36px;
  height: 14px;
  border-radius: 2px;
  cursor: pointer;
}


/*________________________________________ CSS for the dynamically inserted button ____________________________________*/
.dynamic-button {
  padding: 5px 10px;
  background-color: hsl(115, 75%, 51%);
  color: rgb(255, 255, 255);
  border: none;
  border-radius: 6px;
  cursor: pointer;
  margin: 5px;
}

.dynamic-button:hover {
  background-color: hsl(304, 52%, 77%);
  color: rgb(240, 240, 240);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.dynamic-button.selected {
  border: 4px solid rgb(195, 87, 225) !important;
}


/*________________________________________ CSS for Code Viewer Dialog___________________________________ */
.code-viewer-modal {
  display: flex;
  align-items: center;
  justify-content: center !important;
  position: relative;
}

.code-viewer-dialog {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 45%;
  /* max-width: 700px; */
  max-height: 660px !important;
  position: relative;
}

.code-viewer-dialog .MuiTextField-root {
  margin-bottom: 10px;
}


/*________________________Style for the multiline TextField____________________________*/
.code-viewer-dialog .MuiTextField-root .MuiInputBase-root {
  font-size: 14px;

}

.code-viewer-dialog .MuiTextField-root .MuiInputBase-inputMultiline {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  padding: 8px;
}


/*______________________________________Style for the Autocomplete dropdown options______________________________*/
.code-viewer-dialog .MuiAutocomplete-popper {
  font-family: 'Courier New', monospace;
  font-size: 14px;
  height: 50px;
}

/*_____________________________Adjust the height of the multiline TextField ________________________*/
.code-viewer-dialog .MuiTextField-root .MuiInputBase-inputMultiline {
  height: 400px !important;
  max-height: 500px !important;
}

/*____________________________Dialog box cancel button____________________________________*/
.enquiry-form-addeducationalMapmodalcancel {
  position: absolute !important;
  top: -16px;
  right: -15px;
}

.enquiry-form-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;  
  font-size: 16px !important;
}

/*_________________________Dialog box heading___________________________*/
.code-viewer-dialog-heading {
  margin-bottom: 20px;
  text-align: center;
  color: black;
}

.code-viewer-dialog-heading h5 {
  font-weight: bold;
}

/*_________________________Dialog box Language Selection_______________________*/
.LangugaeSelection {
  margin-bottom: 20px;
}

/*___________________________ CodeViewer Modal box Save BUtton________________________________*/
.editor_saveButton {
  display: flex;
  justify-content: flex-end !important;
}



/*______________________Code-Viewer_container____________________*/
.code-viewer-container {
  border: 1px solid #e1e1e1;
  border-radius: 3px;
  background-color: #ffffff;
  margin: 15px 0;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  position: relative;
  transition: border-color 0.3s, box-shadow 0.3s;
  padding: 0 !important;
}

/* Highlight the selected code viewer container */
.code-viewer-container.selected {
  border: 4px solid rgb(195, 87, 225) !important;
}

/* Style for the language display */
.language-display {
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
  color: #2c3e50;
  display: flex;
  align-items: center;
  background-color: #ecf0f1;
  padding: 10px 15px;
  border: 1px solid #dfe4ea;
}

/* Style for the edit and copy buttons */
.edit-button{
  background: none;
  border: none;
  color: #3498db;
  cursor: pointer;
  font-size: 1em;
  padding: 5px;
  margin-left: auto !important;
  transition: color 0.3s;
}

.copy-button {
  background: none !important;
  border: none;
  color: #3498db !important;
  cursor: pointer;
  font-size: 1em;
  padding: 5px;
  margin: 0 0 0 10px !important;
  transition: color 0.3s;
}

.edit-button:hover, .copy-button:hover {
  color: #2980b9;
}

/* Style for the code viewer content */
.code-viewer-content {
  background-color: #f8f7fa !important;
  overflow-x: auto;
  font-family: 'Courier New', Courier, monospace;
  font-size: 0.9em;
  /* color: #2c3e50; */
  border: 1px solid #f7f9fa !important;
  min-height: 10px;
  padding: 0 !important;
}

/* Style the SyntaxHighlighter */
.code-viewer-content pre {
  margin: 0 !important;
  background-color: #f7f9fa;
  padding: 15px;
  border-radius: 4px;
  color: #2c3e50;
}

/* Customize scrollbar for code viewer */
.code-viewer-content::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.code-viewer-content::-webkit-scrollbar-thumb {
  background: #bdc3c7;
  border-radius: 4px;
}

.code-viewer-content::-webkit-scrollbar-thumb:hover {
  background: #95a5a6;
}

.code-viewer-content::-webkit-scrollbar-track {
  background: #ecf0f1;
  border-radius: 4px;
}



/*______________________________CodeViewer ContextMenu_______________________*/
.context-menu {
  position: absolute;
  z-index: 1000;
  border: 1px solid #bdbdbd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  overflow: hidden;
}

/* Context Menu Items */
.context-menu__item {
  padding: 8px 16px;
  font-size: 14px;
  color: #333333;
  cursor: pointer;
  transition: background-color 0.3s;
}

.context-menu__item:hover {
  background-color: #f5f5f5;
}

/*_______________________________________CodeViewer Context Menu Icon Styles____________________________________*/
.EditIcon {
  min-width: 36px;
  color: #007bff;
}

.EditIcon svg {
  width: 1em;
  height: 1em;
  margin-right: 8px;
  color: #007bff;
}

.DeleteIcon {
  min-width: 36px;
  color: #007bff;
}

.DeleteIcon svg {
  width: 1em;
  height: 1em;
  margin-right: 8px;
  color: #ff6347;
}



/*________________________________________Button_Context_Menu______________________________________*/
/* Style for the dialog box */
.dialog {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 5px 0;
  z-index: 10000 !important;
}

.dialog-item {
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dialog-item:hover {
  background-color: #f0f0f0;
}

/* Font Awesome icons */
.dialog-item i {
  margin-right: 10px;
}



/* .dialog {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 8px;
  z-index: 1000;
}

.dialog-item {
  display: flex;
  align-items: center;
  padding: 6px 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dialog-item:hover {
  background-color: #f5f5f5;
}

.dialog-item i {
  margin-right: 8px;
} */

.dialog-item i.fa {
  width: 16px;
  height: 16px;
}

.dialog-item i.fa-pencil-alt {
  color: #007bff;
}

.dialog-item i.fa-link {
  color: #17a2b8;
}

.dialog-item i.fa-trash-alt {
  color: #ff6347;
}

.dialog-item i.fa-external-link-alt{
  color: #17a2b8;
}

/*_______________________________tag_______________________________*/
.tagStart{
  margin:  10px 10px 10px 10px!important;
}

/*__________________________link context menu____________________*/
.link-context-menu {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  width: 200px;
  position: absolute;
}

.link-context-menu-item {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.link-context-menu-item:hover {
  background-color: #eee;
}

.link-context-menu-item i {
  margin-right: 8px;
}

.link-context-menu-item span {
  flex-grow: 1;
}

/* Icon Colors */
.navigate-icon {
  color: #17a2b8;
}

.edit-icon {
  color: #007bff;
}

.delete-icon {
  color: #dc3545;
}

/*__________________________ButtonDropDown____________________*/
.toolbar-container {
  display: flex;
  align-items: center;
  gap: 5px; 
}

/* Optional: Adjust the button styling for consistency */
.toolbar-container button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px !important;
  border: 1px solid #ccc;
  cursor: pointer;
}

.toolbar-container button:hover {
  background-color: #95a5a6;
}

.chip-background-color-and-alignment{
  background-color: #1d88fa !important;
  color: white !important;
}

.chip-close-icon{
  transition: transform 0.4s ease-in-out background-color 0.2s ease-in-out;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
  color: #95a5a6;
  margin-top: 3px;
}

/* .MuiIconButton-root.MuiAutocomplete-clearIndicator {
  background-color: #ff0404 !important;
  color: white;
  font-size: 16px !important;
  width: 22px !important;
  height: 22px !important;
}

.MuiIconButton-root.MuiAutocomplete-clearIndicator:hover {
  background-color: #d90404 !important;
} */

@media (max-width:768px){
  .colorPicker {
    margin: 350px 0 0 450px;
  }
}

@media (max-width: 668px){
  .colorPicker {
    margin: 400px 0 0 100px;
  }
}

@media (max-width: 568px){
  .code-viewer-dialog{
    width: 50% !important;
  }

  .colorPicker {
    margin: 400px 0 0 150px;
  }
}