/*__________________Header_______________________*/
.header {
  position: relative;
  background-color: var(--N100, #fff);
  height: 62px !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08), 0 4px 12px rgba(0, 0, 0, 0.08);
  z-index: 5;
  margin: auto;
  transition: all 0.3s ease;
}

.header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

/*__________________header items_______________________*/
.header-with-control-container {
  max-width: 1140px;
  margin: auto;
}
.headerItems {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 62px;
}

/*__________________Dropdown menu div_______________________*/
.ddMenuClass {
  margin: 0 0px;
}

/*__________________Logo _______________________*/
.logo {
  max-width: 45px !important;
  max-height: 45px !important;
  object-fit: cover;
  margin-left: 10px !important;
  
}

/*__________________________ Dropdown_____________________________________*/
.dropdown {
  position: relative;
  display: inline-block;
  width: max-content;
}

/*__________________________ DropdownMenu toggle_____________________________________*/
.dropdown-toggle {
  background-color: transparent;
  border: none;
  padding: 8px 5px;
  font-size: 17px;
  color: #000;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  cursor: pointer;
  min-height: 63px;
}

/*__________________________ DropdownMenu toggle hover_____________________________________*/
.dropdown-toggle:hover {
  color: #055be5;
}

/*__________________________ DropdownMenu_____________________________________*/
.dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  box-shadow: 0 0px 1px rgb(95, 95, 95);
  z-index: 1000;
  width: 300px;
  min-height: 80vh;
  /* top: calc(10vh); */
  padding: 5px;
}

/*__________________________ DropdownMenu sub menu_____________________________________*/
.sub-menu {
  min-width: 300px;
  width: auto;
  position: absolute;
  top: 0px;
  left: 297px;
}

.NavItem-common.hover {
  background-color: #050df4;
}

/*__________________________Nav Items_____________________________________*/
.NavItem-common {
  padding: 10px;
  cursor: pointer;
  /* position: relative; */
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.NavItem-common1 {
  padding: 10px;
  cursor: pointer;
  /* position: relative; */
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  background-color: #fff;
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.NavItem-common1:hover {
  background-color: #f1f1f1;
}

/* Add this for active background color */
.NavItem-common.active {
  background-color: #f1f1f1;
}

/* You can also add a class for active subcategory */
.dropdown-section.active .NavItem-common {
  background-color: #f1f1f1;
}

/*__________________________ DropdownMenu hover_____________________________________*/
.dropdown:hover .dropdown-menu {
  display: block;
}

/*__________________________ Nav Item Submenu Hover_____________________________________*/
.NavItem-common:hover .sub-menu {
  display: block;
  /* top: -15px;
    position: absolute; */
}

/*__________________________ dropdown-section _____________________________________*/
/* .dropdown-section {
    position: relative;
  } */

/*__________________________ NavItem-common:hover _____________________________________*/
.NavItem-common:hover {
  background-color: #f1f1f1 !important;
}

/*__________________________ dropdown-section:hover .topics-menu _____________________________________*/
.dropdown-section:hover .topics-menu {
  display: block;
}

/*__________________________ Search Container _____________________________________*/
.searchContainer {
  display: flex;
  align-items: center;
  width: 120px;
  border: 1px solid #ccc;
  border-radius: 25px;
  padding: 0 10px;
  height: 40px;
  transition: border-color 0.3s;
  margin: 0 15px;
  position: relative;
}

/*__________________________ Search Input _____________________________________*/
.searchInput {
  border: none;
  outline: none;
  padding: 0 10px;
  font-size: 17px;
  width: 80%;
}

.searchInput.size {
  width: 90%;
}

/*__________________________ Search Icon _____________________________________*/
.searchIcon {
  color: grey;
  transition: color 0.3s;
}

/*__________________________ Search Container Focus _____________________________________*/
.searchContainer:focus-within {
  border: 2px solid #055be5;
  width: 100%;
}

/*__________________________ Search Container Focus Icon _____________________________________*/
.searchContainer:focus-within .searchIcon {
  color: #055be5;
}

/*__________________________ Search Container Place Holder _____________________________________*/
.searchContainer:focus-within .searchInput::placeholder {
  color: #055be5;
}

/*__________________________ Nav Links _____________________________________*/
.navLinks {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  padding: 0 3px;
  margin: 0 5px;
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.navLinks.hide {
  display: none;
}
/*__________________________ Nav Links items _____________________________________*/
.navItem {
  color: #000 !important;
  text-decoration: none !important;
  transition: color 0.3s;
}

.moreverticon-color {
  color: #000 !important;
  font-size: 28px !important;
}

.moreverticon-color:hover {
  color: #050df4 !important;
}

/*__________________________ Nav Links items Hover _____________________________________*/
.navItem:hover {
  color: #050df4 !important;
}

/*__________________________ More Options _____________________________________*/
.moreOptions {
  margin-right: 40px;
}

/*__________________________ More Options Icon _____________________________________*/
.customIconButton .moreVertIcon {
  font-size: 20px;
  width: 30px;
  height: 30px;
  color: #000;
}

/*__________________________ More Options Custom menu _____________________________________*/
.MuiPopover-paper.customMenuPaper {
  border-radius: 15px !important;
  margin-left: -70px !important;
}

.home-page-login-menu-icon-avatar {
  width: 30px !important;
  height: 30px !important;
  font-size: 14px !important;
  background-color: darkcyan !important;
  margin: auto;
  font-weight: bold;
  cursor: pointer;
}

/*__________________________ More Options menu _____________________________________*/
.MoreMenu {
  border-radius: 10px;
}

/*__________________________ More Options menu item icon _____________________________________*/
.menuItemIcon {
  margin-right: 10px;
  font-size: 22px !important;
}

/*__________________________ More Options menu item icon logoin _____________________________________*/
.loginIcon {
  color: #2196f3; /* Blue */
}

/*__________________________ More Options menu item icon register _____________________________________*/
.registerIcon {
  color: #4caf50; /* Green */
}

/*__________________________ More Options menu item icon about us _____________________________________*/
.aboutUsIcon {
  color: #ff9800; /* Orange */
}

/*__________________________ More Options menu item icon blog _____________________________________*/
.blogIcon {
  color: #9c27b0; /* Purple */
}

/*__________________________ More Options menu item icon contact us _____________________________________*/
.contactIcon {
  color: #e91e63; /* Pink */
}

/*__________________________ More Options custom menu item _____________________________________*/
.customMenuItem {
  font-size: 15px;
  font-family: Arial, Helvetica, sans-serif;
  transition: color 0.3s;
}
/* .Home-metadot-icon-menu-width{
 width: 250px;
 margin-left: -70px;
  
}

@media (max-width: 960px) {
.Home-metadot-icon-menu-width{
  margin-left: 0px;
}
} */

.customLink {
  text-decoration: none;
  color: inherit;
 
}

.customLink1 {
  text-decoration: none;
  color: inherit;
  display: flex;
    align-items: center;
    justify-content: center;
}

.Home-text-alignment{
 margin-top: 3px; 
}

/*__________________________ More Options custom menu item hover _____________________________________*/
.customMenuItem:hover {
  color: #050df4;
}

.three-dot-name-overflow {
  display: inline-block;
  max-width: 15ch;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.profileIcon {
  color: #a849e3;
}
/*__________________________ Hamburger Menu _____________________________________0/
.10 {
  position: relative;
}

/*__________________________ Hamburger Menu Button _____________________________________*/
.menuButton {
  background: none;
  border: none;
  cursor: pointer;
  width: 40px;
  height: 60px;
}

/*__________________________ Hamburger Menu Button hover _____________________________________*/
.hamburg-menu-icon-size:hover {
  color: blue;
}

.hamburg-menu-icon-size {
  font-size: 28px !important;
}

/*__________________________ Hamburger Menu content _____________________________________*/
.modalContentMobileView {
  background-color: rgb(255, 255, 255);
  max-width: 270px;
  overflow-y: scroll;
  width:80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.4);
}

.modalHeaderMobileView {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  /* background-color: #4d7aff; */
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: bold;
}

.hamburgmenu-home-icon {
  font-size: 28px !important;
}

.search-icon-for-mobile-view {
  font-size: 28px !important;
}

.hamburgmenu-home-icon-and-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.hamburg-closeIcon {
  font-size: 24px !important;
}

.hamburg-close-icon-container {
  display: flex;
  align-items: center;
  padding: 2px;
}

.hamburg-close-icon-container:hover {
  color: #050df4;
  cursor: pointer;
  background-color: #e9e9e9;
  border-radius: 5px;
}

.hamburg-search-container {
  padding-left: 14px;
  padding-right: 14px;
}

.hamburg-searchBox {
  width: 98%;
}

.hamburg-our-product-outer-container {
  padding-left: 14px;
  padding-top: 10px;
}

.hamburg-heading {
  text-align: left;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 10px;
}

.hamburg-heading-course {
  text-align: left;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 1px;
  padding-left: 14px;
  padding-top: 12px;
}
.hamburg-our-product-conatiner {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: -3px;
  gap: 10px;
}

.hamburg-coursename-render-container {
  padding-left: 14px;
  padding-top: 10px;
  padding-bottom: 14px;
  padding-right: 14px;
  max-height: calc(100vh - 200px);
  height: 95%;
  overflow-y: auto;
  /* border:1px solid red; */
}

@media (max-width:900px) {
  .hamburg-coursename-render-container {
    min-height: calc(100vh - 480px);
    height: auto;
  }
  .hamburg-our-product-conatiner {
    grid-template-columns: 1fr;
  }
}



.hamburg-course-name-container {
  padding: 8px;
  color: #000;
  scrollbar-width: 3px;
  /* border: 1px solid red; */
  text-overflow: ellipsis;
  white-space: nowrap;
  text-overflow: ellipsis;
  width:90%;
  overflow: hidden;
}

.hamburg-course-name-container:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

/*__________________________ Hamburger Menu nav item _____________________________________*/
.navItem {
  padding: 5px;
}

.navItem a:hover {
  color: #050df4 !important;
}
.navItem a {
  color: #2f1c6a !important;
}

.mobileview-search-and-meta-dot {
  display: flex;
  align-items: center;
  gap: 10px;
}

/*__________________________ Hamburger Menu nav item a _____________________________________*/
.navItem a {
  text-decoration: none;
  color: black;
  margin-right: 0 !important;
}

.navItem a:hover {
  color: #050df4;
}

/*__________________________ Hamburger Menu nav item icon _____________________________________*/
.navigate-next-icon-menu {
  margin-left: 15px;
  vertical-align: middle;
}

/*__________________________ dropdown Menu down arrow _____________________________________*/
.downArrow {
  margin-left: 3px;
  vertical-align: middle;
}

.subMenu {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  width: 200px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
  border-radius: 5px;
}

.subMenuItem {
  padding: 10px;
  cursor: pointer;
}

.subSubMenu {
  padding-left: 20px;
  background: #e9e9e9;
}

/* Responsive Design */
@media (max-width: 1111px) {
  .headerItems {
    justify-content: space-between;
  }
  .logo {
    max-width: 45px;
    max-height: 45px;
    margin-left: 10px;

    object-fit: contain;
  }
  .dropdown-toggle {
    font-size: 15px;
  }
  .searchContainer {
    width: 100px;
    margin: 0 5px;
  }

  .navLinks {
    gap: 1px;
    font-size: 15px;
  }

  .moreOptions {
    margin-right: 10px !important;
  }
}

@media (max-width: 960px) {
  .headerItems {
    justify-content: space-between;
    align-items: center;
  }

  .moreOptions {
    margin-right: 20px;
  }

  .ddMenuClass,
  .navLinks {
    display: none;
  }
}

/* _________________search functionlity____________*/
.searchResultsContainer {
  height: auto;

  position: absolute;
  z-index: 1000002;
  width: 100%;
  left: -2px;
  top: 51px;

  background-color: rgb(255, 255, 255);

  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.1);
}

.search-functionality-course-name-container {
  padding: 10px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 15px;
}

/* Common icons size for button */
.buttoniconcommon-search-functionality {
  font-size: 18px !important;
  margin-top: 2px;
  color: #474df8;
}

.search-functionality-course-name-container:hover {
  background-color: rgba(0, 139, 139, 0.071);
}
