.badge-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 4px;
}

.section-label {
  margin-top: 6%;
  background-color: #bbdefb;
  width: 100%;
}

.avatar-container {
  display: flex;
  flex-wrap: wrap;
  margin: 5px;
  padding: 7px;
  /* border: 1px solid red; */
}

.quiz-question-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  margin-top: 16px;
}

.question-container {
  text-align: center;
  margin: auto;
  overflow: scroll; /* Add this line */
}

.options-container {
  display: flex;
  margin-left: 80px;
  /* flex-direction: column; */
  justify-content: flex-start;
  text-align: start;
  color: #000000;
}

.nav-icon-container {
  position: absolute;
  top: calc(50% + 17%);
  right: 0;
  transform: translateY(-50%);
}

.green-label {
  color: green;
}

.bold-label {
  font-weight: bold;
}

.quizquestion1container {
  max-height: 380px;
  overflow-y: scroll;
  margin: 15px;
  padding: 15px;
}

.quizquestion1container::-webkit-scrollbar {
  width: 10px;
  height: 5px;
  border-radius: 10px;
}

.quizquestion1container::-webkit-scrollbar-track {
  background: #b4b7b6;
  border-radius: 10px;
  padding: 12px;
  margin: 20px;
}

.quizquestion1container::-webkit-scrollbar-thumb {
  background: #626363;
  border-radius: 15px;
}

.quizquestion1container::-webkit-scrollbar-thumb:hover {
  background: #626363;
  border-radius: 5px;
}

.additional-options {
  display: block;
  justify-content: flex-end;
  justify-items: center;
  padding: 10px;
}

.card-content-container {
  display: flex;
  justify-content: space-between;
}

/* __________Quiz main header_____________ */
.quiz-main-header-design {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  /* border: 1px solid rgb(216, 224, 240);  */
  /* background-color: aliceblue; */
  
}

.quiz-main-header-second-container {
  display: grid;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  /* border-bottom: 1px solid rgb(216, 224, 240); */
  background-color: #ffffff;
  /* border: 1px solid red; */
  grid-template-columns: 1fr 1fr 1fr;
  padding: 8px;
  gap: 10px;
  text-align: center;
  align-items: center;
  max-height: 60px;
}

.quizbashboard1-image-container-testname-container{
  /* border: 1px solid red; */
  display: flex;
  gap: 20px;
  align-items: center;
}

.quizbashboard1-image-container-testname-container1{
  /* border: 1px solid red; */
  display: flex;
  gap: 20px;
  justify-content: end;
  justify-items: center;
  align-items: center;
}

.quizbashboard1-timer-alignment-quiz-time{
  text-align: center;

}

.quizdashboard1-testname-alignment-and-color{
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  /* border: 1px solid red; */
  min-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

}

.quiz-dashboard {
  display: flex;
  width: 100%;
}

.quiz-content {
  width: 70%; /* Set the width of the main content area */
  padding: 20px; /* Add some padding if needed */
}

.quiz-nav {
  display: flex;
  flex-direction: column;
  height: 100%; /* Ensure it takes full height */
  border-right: 1px solid rgb(216, 224, 240); /* Optional border */
}

/* _____________QuizNav_________________ */
.more-icon-color-quiznav {
  color: blue !important;
  /* position: relative !important; */
  right: 0; 
  
  border-radius: 50%;
}




.more-icon-background-color-and-alignment{
  background-color: #ccd0cfac !important;
}
.more-icons-placement-at-the-container-end{
  /* background-color: #ff0a0a; */
  position: absolute;
  right: 5px;
  top:12px;
}

.quiz-nav-box-shadow {

  position: absolute;
}

/* ____________________Quiz dashBoard css_______________ */

.quizboard1-modal-importent {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0);
}

.quizboard1-modal-container-importent {
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 10px;
}

.quizboard1-header {
  text-align: center;
  padding: 5px;
  color: #000000;
  font-weight: bold;
}

.quizboard1-modal-choose-laanguage {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
  color: #000000;
}

.quizboard1-note-container {
  padding: 10px;
  color: #000000;
}

.quizboard1-note-text {
  font-weight: bold;
  color: #000000;
}

.quizboard1-note-content {
  color: red;
}

.quizboard1-alignment-modal {
  padding: 10px;
  display: flex;
  justify-content: end;
}

.quizboard1-screen-exit-modal{ 
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center; 
    height: 100vh;
}


.quizboard1-screen-exit-modal1{ 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(0,0,0,0);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4);
  width: 100%;
}

.quizboard1-screen-exit-modal2{  
    background-color: rgb(255, 255, 255);
    padding: 10px;
    border-radius: 5px;
    min-width: 200px;
    position: relative;
    margin: 10px;
}

.quizboard1-alert-heading{
  text-align: center; 
  padding: 5px;
   color: #ff0a0a;
   font-weight: 900;
}

.quizboard1-content-of-modal{
  color: #000000;
  padding: 10px;
}

.quizbashboard1-ok-cancel-button-alignment{ 
    padding: 10px;
    display: flex;
    justify-content: end;
  
}

.quizdashboard1-snackbar-info{
  color: #000000f6;
  /* border: 1px solid red; */
bottom: 20px;
}

.avatar-and-name-alignemnt-font {
  /* border: 1px solid red; */
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 4px;
 
}

.text-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1; /* Ensure the text takes available space */
}

@media (max-width:990px){


.text-content1 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 110px;
  /* border: 1px solid red; */
}
}



