/* Outer container for full width */
.container-outer {
  width: 100%;
  background-color: #f9f9f9;
  padding: 20px 0;
  position: relative;
}

/* Inner container for max-width content and centering */
.container-inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 5px;
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
}

.container-inner h1 {
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  text-align: left;
}

/* Content container for main and sidebar layout */
.container-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}

/* Mock Test container */
.mock-test-container {
  width: 70%; /* Main content area */
}

.tabs {
  margin: 20px 0;
}

.tab-btn {
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 10px 15px;
  cursor: pointer;
  font-family: "Roboto",sans-serif;
}

.tab-btn.active {
  border-bottom: 2px solid #008cff;
}






/* Button for showing more cards */
.view-more-btn {
  color: white;
  background-color: #008cff;
  outline: 2px solid white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

/* Responsive styles for tablets and mobile */
@media (max-width: 1022px) {
  .container-content {
    flex-direction: column;
    align-items: left;
  }

 
}

@media (max-width: 768px) {
  .mock-test-container {
    width: 100%; /* Full width for mock test */
  }

  
}



@media (max-width: 550px) {
  

  .test-series-box h4,
  .test-series-box p {
    font-size: 14px; /* Adjust text size */
    margin-left: 40px; /* Margin adjustment */
  }
}

@media (max-width: 502px) {
  .container-inner {
    font-size: 12px; /* Adjust overall font size */
  }
}

@media (max-width: 394px) {
  .container-inner {
    font-size: 8px; /* Smaller font size for very small screens */
  }
}

/* _______________________________________________________________________________________ */
/* ___________________________________________________________________________________________ */



/* Ads section styles */
.adds-section {
  position: sticky; /* Keep the ad section fixed */
  right: 0; /* Fix it to the right side of the page */
  top: 50px; /* Adjust the space from the top */
  width: 20%; /* Sidebar area for ads */
  padding: 20px; /* Padding inside the ads section */
  z-index: 100; /* Ensures it's above other content */
  margin-top: 0px;
}

/* Heading for ads */
.adds-heading {
  margin: -50px 0 20px;
  font-size: 18px;
}

/* Ads image */
.adds-image {
  max-width: 100%; /* Responsive image */
  height: auto; /* Maintain aspect ratio */
  display: block; /* Block element for image */
  margin-bottom: 10px; /* Space below the image */
}

/* Content under the ad */
.adds-content {
  font-size: 14px; /* Text size for ad content */
  color: #555; /* Text color */
  line-height: 1.5; /* Line height for readability */
}

/* CSS for fixed ad */
.adds-fixed {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000; /* Ensure the ad stays on top */
  width: 100px; /* Adjust width as per your layout */
}

/* Responsive styles for tablets and mobile */
/* @media (max-width: 1030px) {
  .adds-section {
    width: 14%; 
    left: 0;
    top: 120px; 
   margin-left: 0px;
  }
} */

@media (max-width: 1030px) {
  .adds-section {
    display: none; /* Hide the ad section in responsive view */
  }
}

