.process-progress-circle-container {
    position: fixed;  /* Makes the container fixed relative to the viewport */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.174);  /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;  /* Ensures the progress bar is on top of other elements */
  }
  
  .circle-background-color{
    background-color: rgb(255, 255, 255);
    height: 80px;
    width: 320px;
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    box-shadow: 0px 0px 10px gray;
    border-radius: 10px;
    padding: 25px;
  }

  .text-conataienalignment-progrees-bar{
    color: black;
    padding: 10px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;

  }