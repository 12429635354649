.hero_section_main{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
}

.hero-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1140px;
    padding: 20px;
  }
  
  .hero-text {
    flex: 1;
    max-width: 50%;
    text-align: justify;
    color: black !important;
  }
  
  .hero-title {
    font-size: 2.5em !important;
    margin-bottom: 20px;
    white-space: nowrwp;
    animation: fadeIn 2s ease-out;
  }
  
  .hero-subtitle {
    font-size: 17px !important;
    margin-top: 0;
    animation: fadeIn 2.5s ease-out;
  }

  .corporate-button{
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
    cursor: pointer;
    margin-top: 20px;
  }

  .corporate-button:hover{
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  
  .hero-image {
    flex: 1;
    max-width: 50%;
    text-align: center;
  }
  
  .hero-image img {
    width: 80%;
    /* height: 450px !important; */
    border-radius: 10px;
    animation: fadeIn 2s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .overview-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fafbff;
  }

  .overview-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 1140px;
    padding: 20px;
    opacity: 0;
    transform: translateY(100px);
    transition: opacity 1s ease-out, transform 1s ease-out;
  }
  
  .overview-container.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .overview-image {
    flex: 1;
    text-align: left;
  }
  
  .overview-image img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }
  
  .overview-text {
    flex: 1;
    max-width: 50%;
    font-family: "Roboto", sans-serif;
    text-align: justify;
    padding: 0 20px;
    color: black !important;
  }
  
  .overview-title {
    font-size: 2em !important;
    margin-bottom: 20px;
    margin-top: 0;
  }
  
  .overview-description {
    font-size: 17px !important;
    line-height: 1.6;
  }

  .modes-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
  }

  .modes-container {
    padding: 20px;
    width: 1140px;
    text-align: center;
    font-family: "Roboto", sans-serif;
    color: black !important;
  }
  
  .modes-heading h2 {
    font-size: 2em !important;
    margin-bottom: 10px;
  }
  
  .modes-heading p {
    font-size: 17px !important;
  }
  
  .modes-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .mode {
    flex: 1;
    max-width: 30%;
    transition: transform 0.3s ease;
  }
  
  .mode img {
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }

  .online-mode img{
    height: 200px;
  }

  .onsite-mode img{
    height: 200px;
  }

  .selfpace-mode img{
    height: 200px;
  }
  
  .mode h3 {
    font-size: 1.5em !important;
    margin-bottom: 10px;
  }
  
  .mode p {
    font-size: 17px !important;
  }
  
  .mode:hover {
    transform: translateY(-10px);
  }

  .corporate-features-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fafbff;
    padding-bottom: 20px;
  }
 
  .corporate-training {
    padding: 20px;
    width: 1140px;
    text-align: center;
  }
  
  .corporate-training h2 {
    font-size: 2em !important;
    color: black;
    margin-bottom: 10px;
  }
  
  .corporate-training p {
    font-size: 17px !important;
    color: black !important;
    margin-bottom: 20px;
  }
  
  .features-cards {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
  }
  
  .card {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  .card.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .card:hover {
    background-color: white;
    box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5);
    transform: scale(1.03);
    transition: transform 1s, box-shadow 1s;
    outline: 4px solid #078efb;
  }
  
  .card:hover .corporate-feature-icon{
    color: #0056b3;
  }

  .corporate-feature-icon {
    font-size: 40px !important;
    color: #007bff;
    margin-bottom: 30px !important;
  }
  
  .card h3 {
    font-size: 18px !important;
    color: #333;
    margin-top: 10px;
  }

  .sub-heading {
    font-size: 17px !important;
    color: #333 !important;
    margin-top: 5px;
  }

  /* Media Queries for Responsive Design */
@media (max-width: 1200px) {
    .hero-container {
      width: 960px;
    }
  }
  
  @media (max-width: 992px) {
    /* .hero-container {
      width: 720px;
    } */

    .hero-container {
      width: 100%;
      flex-direction: column;
      align-items: center;
      height: auto;
    }

    .hero-text {
      max-width: 90%;
      text-align: left;
    }
    .hero-image {
      max-width: 90%;
      text-align: center;
      margin-top: 20px;
    }

    .hero-title {
      font-size: 2em;
    }
    .hero-subtitle {
      font-size: 1em;
    }

    .overview-container {
        flex-direction: column;
        align-items: center;
    }

    .overview-text{
      max-width: 90%;
      order: -1;
    }
    
    .overview-image {
      /* order: -1; */
      max-width: 100%;
      text-align: center;
      margin-bottom: 20px;
    }
  

    .mode {
        max-width: 45%;
    }

    .features-cards {
        flex-direction: column;
        align-items: center;
    }
    
    .card {
        width: 80%;
        margin: 20px 0;
    }
}

  @media (max-width: 768px) {
    .hero-container {
      width: 100%;
      flex-direction: column;
      align-items: center;
      height: auto;
    }
    .hero-text {
      max-width: 90%;
      text-align: left;
    }
    .hero-image {
      max-width: 90%;
      text-align: center;
      margin-top: 20px;
    }

    .overview-image {
      order: -1 !important;
    }

    .modes-content {
        flex-direction: column;
        align-items: center;
    }
    
    .mode {
        max-width: 100%;
        margin-bottom: 20px;
    }
  }
  
  @media (max-width: 576px) {
    .hero-title {
      font-size: 1.5em;
    }
    .hero-subtitle {
      font-size: 0.9em;
    }

    .overview-image{
      order: -1;
    }

    .overview-title {
        font-size: 1.5em;
    }
    
    .overview-description {
        font-size: 1em;
    }

    .modes-heading h2 {
        font-size: 2em;
    }
    
    .modes-heading p {
        font-size: 1em;
    }
    
    .mode h3 {
        font-size: 1.3em;
    }
    
    .mode p {
        font-size: 0.9em;
    }

    .corporate-training h2 {
        font-size: 24px;
    }
    
    .corporate-training p {
        font-size: 15px;
    }
    
    .corporate-feature-icon {
        font-size: 35px;
    }
    
    .card h3 {
        font-size: 16px;
    }
    
    .sub-heading {
        font-size: 15px;
    }
  }

  @media (max-width: 1140px) {
    .card{
      max-width: 250px;
    }
  }
  