/* ______________Add General Info_____________________ */

.createtesteditgeneralinforamation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252, 0.071);
}

.createtesteditgeneralinforamation1 {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 550px;
  position: absolute;
  outline: none;
}

.createtesteditgeneralinforamation2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  padding-top: 25px;
  max-height: calc(100vh - 220px);
  overflow: scroll;
}

.add-general-info-heading {
  color: black;
  align-items: center;
  font-weight: bold;
  text-align: center;
  margin-bottom: 21px;
}

.createtesteditgeneralinforamation3 {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

@media (max-width: 450px) {
  .createtesteditgeneralinforamation2 {
    grid-template-columns: 1fr;
  }
}

/* __________________Small info dispaly____________ */

.createtestgeneralinfoemarionsmalldiv {
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 1px gray;
  border: 1px solid rgb(216, 224, 240);
  margin: 5px;
  padding: 6px;
  border-radius: 10px;
  align-items: center;
}

.createtestgeneralinfoemarionsmalldiv1 {
  display: flex;
  /* border: 1px solid red; */
  gap: 10px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: left;
  flex-grow: 0.7;
}

.fontsizeandweight {
  /* border: 1px solid green; */
  /* max-width: 200px; */
  padding: 3px;
}

.general-info-small-div-icon-size-alignment {
  font-size: 14px !important;
}

.icon-and-name-alignment-generalinfo {
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(75, 75, 75);
  font-size: 14px;
}

.info-dispaly-font-color {
  color: black;
  font-weight: bold;
}

.edit-icon-alignemnt {
  display: flex;
  align-items: center;
}

.edit-icon-color-and-fontsize-generalinfo {
  color: blue;
  font-size: 18px;
}

/* __________Question bacnk and Question show_____________ */
/* Main container uses flexbox to lay out the two containers */
.main-container-all-component-render {
  display: flex;
  position: relative;
  /* box-shadow: 0px 0px 1px gray; */
  /* border: 1px solid rgb(216, 224, 240); */
  /* margin: 5px; */
  /* padding: 6px; */
  border-radius: 10px;
  max-height: calc(100vh - 100px);
  min-height: calc(100vh - 110px);
}

.left-container {
  max-height: calc(100vh - 120px);
  overflow-y: scroll;
  padding: 5px;
  /* padding: 20px; */
  /* background-color: aliceblue; */
  transition: flex-basis 0.3s ease-in-out;
  /* height: 500px; */
  margin-top: 5px;
  /* border-right: 1px solid rgb(216, 224, 240); */
}

.left-container.expanded {
  flex-basis: 50%;
}

.left-container.full-width {
  flex-basis: 100%;
  
}

.right-container {
  flex-basis: 50%;
  /* padding: 20px; */
  background-color: #ffffff;
  position: relative;
  transition: transform 0.3s ease-in-out;
  padding: 5px;
}

.right-container.closed {
  transform: translateX(100%);
}

.toggle-button {
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translateY(-50%);
  color: rgb(34, 43, 212) !important;
  z-index: 10;
}

.toggle-button.closed {
  left: 0;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.close-the-container-arrow-alignment {
  padding-left: 6px !important;
}

.common-page-search-container-all-component-render {
  display: flex;
  align-items: center;
  border: 1px solid rgb(216, 224, 240);
  border-radius: 16px;
  overflow: hidden;
  max-width: 290px;
  width: 80%;
  box-shadow: 0px 0px 1px gray;
  /* padding-right: 2px; */
}

.common-page-search-container-all-component-render :hover,
.common-page-search-container-all-component-render :focus-within,
.common-page-search-container-all-component-render :active {
  box-shadow: -0px 0px 1px gray;
}

.common-page-search-icon-all-component-render {
  padding: 8px;
}

.common-page-search-icon-all-component-render svg {
  font-size: 28px;
  color: gray;
}

.search-input-all-component-render {
  width: 90%;
  padding: 10px;
  border: none;
  outline: none;
}

.container-for-filter-search-all-component-render {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -35px;
}

.common-border-template-filter {
  border: 1px solid rgb(216, 224, 240);
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0px 0px 1px gray;
  font-size: 18;
  font-weight: 600;
  height: fit-content;
  cursor: pointer;
}

.avatar-alignemnt-in-add-to-test {
  background-color: green !important;
  font-size: 14px !important;
}

.sidebar-content {
  max-height: calc(100vh - 176px);
  overflow-y: scroll;
  margin-top: 5px;
}

.sidebar-content::-webkit-scrollbar {
  width: 5px;
  /* height: 5px; */
}

.sidebar-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

.sidebar-content::-webkit-scrollbar-thumb {
  background: #b7b8ba;
  border-radius: 5px;
}

.sidebar-content::-webkit-scrollbar-thumb:hover {
  background: #626363;
}

/* _________________Create test quetion view____________ */

.all-component-render-page-outer-container {
  /* border: 1px solid red; */
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 1px solid rgb(216, 224, 240);
  border-radius: 10px;
  margin-bottom: 5px;
}

.accordion-summary-of-the-question-display {
  color: black;
  font-weight: bold;
  display: flex;
  position: relative;
}

.difficulty-level-shown-color-font {
  height: 16px !important;
  font-size: 8px !important;
  /* background-color: #003d1175 !important; */
  color: #ffffff !important;
  position: absolute;
  top: -30px;
  font-weight: bold;
}

.difficulty-level-shown-color-font1 {
  height: 16px !important;
  font-size: 8px !important;
  /* background-color: #003d1175 !important; */
  color: #ffffff !important;
  position: absolute;
  top: -60px;
  font-weight: bold;
}

.option-line {
  margin-bottom: 8px; /* Space between options */
  display: flex;
  color: black;
}

.highlight-green {
  /* background-color: #d4edda; */
  color: green;
  /* padding: 4px; */
  border-radius: 4px;
  font-weight: bold;
}

.color-dimming-for-the-options {
  font-weight: bold;
}

.color-change-for-the-answer {
  font-weight: bold;
  color: green;
}

.display-options-and-answer {
  /* border: 1px solid red; */
  margin-left: 28px;
  margin-top: 5px;
}

.add-icon-and-right-icon-placement {
  position: relative;
  display: flex;
  justify-content: end;
  align-items: center;
}

.icon-button-to-disapay-add-button {
  position: absolute !important;
  bottom: 1px;
  color: blue !important;
}

.icon-button-to-disapay-add-button1 {
  position: absolute !important;
  top: -5px;
  right: -3px;
  color: blue !important;
  /* border: 1px solid red !important; */
}


.icon-button-to-disapay-add-button3 {
  position: absolute !important;
  top: -65px;
  right: 0px;
  color: rgb(255, 0, 0) !important;
  
  /* border: 1px solid red !important; */
}

.icon-button-to-disapay-done-button {
  position: absolute !important;
  bottom: 1px;
  color: green !important;
}

.selected-question-view-container.full-width {
  /* border: 1px solid red; */
  max-width: 800px;
  width: 60%;
  margin: auto;
}

.selected-question-view-container.expanded {
  /* border: 1px solid red; */
  max-width: 800px;
  width: 98%;
  margin: auto;
}

.Avatar-and-Button-conatiner-in-question-view-container {
  display: flex;
  position: absolute;
  justify-content: space-between;
  /* width: 900px; */
}

.Avatar-and-Button-conatiner-in-question-view-container1 {
  display: flex;
  position: absolute;
  justify-content: space-between;
  /* width: 900px; */
  right: 60px;
}

.no-questions-message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  background-color: #62636317;
  color: black;
  font-weight: bold;
}

.create-test-question-view-more-icons {
  color: blue;
}

.create-test-menuitem-edit {
  color: blue !important;
}

.create-test-menuitem-delete {
  color: red !important;
}

.question-tag-div {
  /* border: 1px solid red; */
  font-size: 6px;
  color: black;
  display: flex;
  justify-content: right;
  flex-wrap: nowrap;
  width: auto;
  height: 20px;
  overflow: hidden;
}

.button-icons-tags {
  font-size: 10px !important;
  color: #e6db05;
}

.question-container-view1 {
  /* border: 1px solid red; */
  max-width: 86%;
  width: 90%;
  color: black;
}

/* _____________Manage test_______________ */

.Manage-test-modal-outer-container{
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 550px;
  position: absolute;
  outline: none;
}

.modal-center-alignment-to-center{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(252, 252, 252, 0.005);
}

.mange-test-edit-data-heading{
  font-size: 24px;
  color: black;
  font-weight: bold; 
}

.button-icons-alignments{
  display: flex;
  margin-top: 10px;
  align-items: end;
  justify-content: end;
  justify-items: end;

}

/* _____________instructor_______ */
.testseriesmanagement{
  box-shadow: 0px 0px 10px rgb(116, 112, 112);;
  min-width: 330px;
  height: auto;
  padding: 10px;
  border-radius: 10px; 
}

.testseriesmanagement1{
  padding: 4px;

}

.testseriesmanagement2{
  display: flex;
  justify-content: flex-end;
  text-align: end;
  padding: 5px;
}
.testseriesmanagement3{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

.testinstructionlink{
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 10px;
  border-bottom: 1px solid gray;
  
}
.testinstructionlink1{
  height: 400px;
  margin: 10px;
  overflow: scroll;
  
}

.testinstructionlink2{
  display: flex;
  margin: 5px;
  padding: 2px;
}

.testinstructionlink3{
      display: flex;
      flex-grow: 1;
      justify-content: flex-start;
      align-items: center;
}

.instructionpage1{
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 15px;
      justify-content: center;
}

.instructionpage2{
  margin: 8px;
  color: #000000;
}

.instructionpage3{
      border: 1px solid black;
      margin: 10px;
      max-width: 95%;
}

.testmanagementdatagrid3{
  display: flex;
  justify-Content: center;
  align-items: center;
}


.testmanagementdatagrid4{
  width: calc(95%);
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  position: relative;
}
