
.scrUL
{ 
    list-style: none; 
    background-color: white;
} 

/* Main container styles */
.mainContainerNewScroll {
    display: flex;
    height: 100vh;
    margin-top: 15px;
    padding-top: 12px;
}

/* Navigation styles */
.scrollspyTabLeft { 
    min-width: 150px;
    width: 20%; 
    padding-left: 0; 
    border-left: 1px solid #ddd; 
    background-color: white; 
    overflow-y: auto; 
    padding-top: 1rem;
    height: 98% ;
    box-shadow:  0px 0px 10px rgba(30, 10, 58, .2) !important;
    border-radius: 10px;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* Internet Explorer 10+ */
}


.scrollspyTabLeft::-webkit-scrollbar { 
    display: none; /* Safari and Chrome */
}

.scrollspyTabLeft a { 
    display: block; 
    text-decoration: none; 
    line-height: 1rem; 
    padding: 0 1rem; 
    color: #202020; 
} 

.scrollspyTabLeft a:hover, 
.scrollspyTabLeft a:focus { 
border-radius: 10px !important;
} 
.scrollspyTabItem{
    margin-left: 15px;
    margin-right: 15px;
    margin-top: 7px;
    margin-bottom: 7px;
}
/* Content area styles */
.scrollspyContentSectionRight { 
    width: 80%;
    padding: 0 1rem; 
    overflow-y: scroll; /* Make content area scrollable */
    scroll-behavior: smooth;
    height: 100% !important;
    
    
    /* Hide scrollbar */
    scrollbar-width: none; /* For Firefox */
}

.scrollspyContentSectionRight::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Edge */
}



.uniqueSection {
    border: 2px solid gray;
    padding: 1rem;
    margin-bottom: 1rem;
}



.profileScrollTabsNew{
    background-color: var(--profileBg);
    border-radius: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 5px;
    padding-right: 17px;
    padding-left: 17px;
    margin-bottom: 20px;
    box-shadow:  0px 0px 10px rgba(30, 10, 58, .2) !important;
    /* box-shadow:0 6px 12px rgba(30,10,58,.04) */
}
  
.profile_Scroll_Active{
    
    padding: 20px !important;
    border-radius: 0 !important;
    border-left: 4px solid blue !important;
    &:hover{
      background-color: rgb(245, 245, 245) !important;
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
    }
  }
  .profile_Scroll{
    
    padding: 20px !important;
    border-left: none !important;
    border-radius: 0 !important;
    &:hover{
      background-color: rgb(245, 245, 245) !important;
      border-radius: 10px !important;
    }
  }

  /* scrollspyTabLeft */
  @media (max-width: 800px) {
  .scrollspyTabLeft{
    /* background-color: yellow; */
    display: none;
  }

  .scrollspyContentSectionRight{
    width: 100%;
  }

}

.pmActiveContent{
    border-left: 3px solid blue;
}

/* .profileScrollTabsNew:hover{
  outline: 2px solid blue;
} */