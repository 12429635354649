.enquiry-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.064);
  outline: none;
}

.enquiry-form-content-container {
  max-width: 461px;
  width: 78%;
  background-color: #fafbff;
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  position: relative;
  padding: 20px;
  border-radius: 10px;
  margin: 10px;
  max-height: auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 23px;
  z-index: 100005;
}

.enquiry-form-addeducationalMapmodalcancel {
  position: absolute !important;
  top: -16px;
  right: -15px;
}

.enquiry-form-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;  
  font-size: 16px !important;
}

.enquiry-table-header {
font-size: 21px;
    margin-top: -10px;
    font-weight: bold;
    padding: 12px;
    padding-left: 0px;
    padding-bottom: 8px;
    overflow: hidden;
    height: 30px;
    /* border-bottom: 1px solid rgb(205, 204, 204); */
    text-overflow: ellipsis;
    white-space: nowrap;
    text-overflow: ellipsis
 
 
}

@media screen and (max-width: 430px) {
  .enquiry-table-header{
    font-size: 18px;
    min-height: 30px;
  }
}

.enquiry-table-grid-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 460px;
  width: 100%;
  max-height: calc(100vh - 170px);
 /* max-height: calc(100vh - 170px); */
  overflow: scroll;
  scrollbar-width: none;
  padding-top: 9px;
  padding-bottom: 9px;
}

.enquiry-table-button-alignment{
  display: flex;
  margin-top: 12px;
  margin-bottom: -5px;
  justify-content: flex-end;
}

.helper-text-phone-container{
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 2px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
    color: #d32f2f;

}

.phone-input-error .form-control {
  border: 1px solid #d32f2f !important;
  
}

.react-tel-input .form-control{
  background-color: #fafbff !important;
}

.react-tel-input .flag-dropdown{
  background-color: #fafbff !important;
}