.header-small-top-bar-background-color {
  background-color:rgb(1, 172, 172);
}

.header-small-top-bar-width-conatiner {
  max-width: 1140px;
  margin: auto;
  text-align: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding-right: 10px;
}

.social-media-icons-top-small-bar {
  font-size: 14px !important;
  color: white !important;
  cursor: pointer;
  margin-top: 2px;
}

.social-media-icons-top-small-bar-mail {
  font-size: 14px !important;
  color: white !important;
  cursor: pointer;
}

.mail-info-container {
  display: inline-flex;
  align-items: center;
  margin-left: 4px;
  margin-top: -1px;
}

.mail-info-text {
  margin-top: 0px;
  margin-left: 4px;
  font-weight: 500;
  color: white;
  font-size: 12px;
}
