.exam-main-section {
    background-color: white;
    width: 100%;
}

.exam-section {
    max-width: 1140px; /* Full-width section */
    padding: 20px 10px;
    margin: 0 auto;
}

.exam-heading-container {
    margin: 0 auto;
    text-align: left;
}

.exam-heading-container h2 {
    font-size: 24px;
    margin-bottom: 10px;
    font-family: "Roboto",sans-serif;
}

.exam-heading-container p {
    font-size: 18px;
    color: black;
    font-family: "Roboto",sans-serif;

}

.exam-slider-container {
    max-width: 1140px; /* Slider container */
    margin: 20px auto;
    display: flex;
    justify-content: center; /* Center the button and content */
    position: relative; /* Enable absolute positioning for buttons */
}

.exam-names-container {
    display: flex;
    align-items: center;
    background-color: white; /* White background for the exam names */
    padding: 10px;
    border-radius: 5px; /* Optional: rounded corners */
    box-shadow: 10px 10px 10px rgba(114, 113, 113, 0.1); /* Optional: subtle shadow */
    flex-grow: 1; /* Allow to take full available space */
    max-width: 100%; /* Ensure it doesn't exceed its container */
    position: relative; /* Position relative to allow absolute positioning of .exam-names */
    overflow: hidden; /* Allow horizontal scrolling */
 
}

.exam-names {
    display: inline-flex;
    transition: transform 0.3s ease; /* Smooth transition for moving effect */
    will-change: transform; /* Optimize for performance */
}

.exam-name {
    flex: 0 0 auto; /* Prevents flex items from growing */
    margin: 0 12px;
    padding: 10px 20px;
    background-color: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 30px; /* Rounded buttons for exams */
    font-size: 18px;
    cursor: pointer;
    text-align: center;
    font-family: "Roboto",sans-serif;
    transition: all 0.3s ease; /* Smooth transition for hover effect */
    display: inline-block;
}

.exam-name:hover {
  
    color: #008cff; /* Change text color on hover */
    /* background-color:#038cfc;   */
    /* outline: 2px solid rgb(241, 242, 253); */
    outline: 2px solid rgb(241, 242, 253);

}
.exam-name.active {
    background-color: #008cff; /* Blue background for active state */
    color: white; /* White text color for active state */
    outline: 2px solid rgb(241, 242, 253); /* Optional outline */
}

.slider-btn {
    background-color: rgba(0, 0, 0, 0.3) !important; /* Change to a primary color */
    opacity: 1;
    color: white; /* Text color for arrows */
    width: 45px;
    height: 45px;
    border: none;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.3s ease; /* Smooth transition for button hover effect */
    position: absolute; /* Position the buttons absolutely */
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust for button height */
    overflow: hidden;

}

.left-btn {
    left: 1px; 
}

.right-btn {
    right: 1px; 
}

/* New styles for cards */
.exam-cards-container {
    margin-top: 20px; /* Space above the cards container */
    max-width: 1140px; /* Ensure the cards container doesn't exceed this width */
    display: flex;
    flex-wrap: wrap;
    overflow: hidden; /* Cut off overflow on left and right */
    padding: 0 20px; /* Adjust padding to center cards */
       
}

.avatar-chip {
    width: 60px;
    height: 60px;
    background-color: #dcedfe;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 24px;
    font-weight: bold;
    margin-right: 10px;
}
.exam-card {
    display: flex; /* Use flexbox for layout */
    padding: 5px; /* Add some padding */
    border: 1px solid #ddd; /* Example border */
    border-radius: 10px; /* Rounded corners */
    margin: 10px; /* Margin around cards */
    width: 400px; /* Set the desired width of the card */
    max-width: 100%; /* Ensure the card does not exceed the container width */
    position: relative; /* Position relative to allow for absolute positioning */
    flex: 0 0 calc(30% - 1px); /* Set card width to allow 3 cards per row with some spacing */
    background-color: white; /* Card background */
    text-align: center; /* Center the text */
   text-decoration: none;
}


/* New zoom-out effect */
.exam-card:hover {
    transform: scale(1.03); /* Slightly scale up the card */
   outline: 2px solid #078efb;
  transition: transform 1s, box-shadow 1s;

}

.cardText {
    font-size: 17px; /* Font size for card text */
    color: #333;
    margin-top: 25px;
    font-weight: normal;
    font-family: "Roboto",sans-serif;
  }
  .exploreAll{
    font-size: 17px; /* Font size for card text */
    color: #333;
    margin-top: 25px;
    font-weight: normal;
    margin-left: 90px;
    text-align: left;
    font-family: "Roboto",sans-serif;
  }
  .exploreAll:hover{
    color: #078efb;
  }

.exam-card-logo {
    display: flex; /* Use flexbox for logo alignment */
    align-items: center; /* Center vertically */
}

.exam-card-logo img {
    height: auto; /* Maintain aspect ratio */
    width: 60%; /* Adjust width as necessary for responsiveness */
    max-width: 120px; /* Limit maximum width for larger screens */
}

/* Styles for the arrow using ::after */
.exam-card h3::after {
    border-color: #86a1ae;
    border-width: 2px;
    width: 8px;
    height: 8px;
    content: "";
    display: inline-block;
    margin-left: 10px; /* Space between text and arrow */
    border-left-style: solid;
    border-bottom-style: solid;
    transition: all .2s ease-in-out;
    transform: rotate(-135deg);
    position: absolute; /* Change to absolute positioning */
    right: 20px; /* Fix position to the right of the card */
    bottom: -10px;
    top: 50%; /* Center vertically */
    transform: translateY(-50%) rotate(-135deg); /* Center vertically and rotate */
}

/* Additional styles for hover effect */
.exam-card:hover {
    transform: scale(0.95); /* Zoom-out effect on hover */
}

/* Media Queries for Smaller Screens */
/* Media Queries for Smaller Screens */
@media (max-width: 1024px) {
    .slider-btn {
        display: none; /* Hide buttons on smaller screens */
    }

    .exam-names-container {
        padding: 10px 0; /* Adjust padding for smaller screens */
        overflow-x: auto; /* Allow horizontal scrolling */
    }

    .exam-card {
        flex: 2 1 calc(50% - 90px); /* 2 cards per row */
        margin: 10px; /* Space around cards */
    }

    /* Show the explore card if there are more than 3 cards */
    .exam-cards-container:has(.exam-card:nth-child(n+4)) .exploreAll {
        display: flex; /* Display the Explore All card */
        justify-content: center; /* Center the text */
        align-items: center; /* Center vertically */
        flex-basis: 100%; /* Full width for the explore card */
        padding: 20px;
        margin: 10px;
        border-radius: 10px;
        cursor: pointer;
    }

}

/* Media Queries for 768px */
@media (max-width: 710px) {
    .exam-name {
        font-size: 14px; /* Adjust font size */
        padding: 10px 15px; /* Adjust padding */
    }

    .exam-card {
        flex: 2 1 calc(50% - 90px); /* 2 cards per row */
    }

    /* Show the explore card if there are more than 2 cards */
    .exam-cards-container:has(.exam-card:nth-child(n+3)) .exploreAll {
        display: flex; /* Show explore card */
        justify-content: center; /* Center text */
        align-items: center; /* Center vertically */
        flex-basis: 100%; /* Take full width */
        padding: 20px; /* Add some padding */
        margin: 10px; /* Margin around the explore card */
        border-radius: 10px; /* Rounded corners */
    }
}

@media (max-width: 716px) {
    .exam-name {
        font-size: 14px; /* Adjust font size */
        padding: 10px 10px; /* Adjust padding */
    }

    /* Show 1 card per row */
    .exam-card {
        flex: 0 1 calc(100% - 20px); /* 1 card per row */
    }

    /* Always show the explore card if there are more than 1 card */
    .exam-cards-container:has(.exam-card:nth-child(n+2)) .exploreAll {
        display: flex; /* Show explore card */
        justify-content: center; /* Center text */
        align-items: center; /* Center vertically */
        flex-basis: 100%; /* Take full width */
        padding: 20px; /* Add some padding */
        margin: 10px; /* Margin around the explore card */
        border-radius: 10px; /* Rounded corners */
    }
}
@media (max-width: 411px) {
    .exam-name {
        font-size: 16px; /* Adjust font size */
        padding: 10px 10px; /* Adjust padding */
    }

    /* Show 1 card per row */
    .exam-card {
        flex: 0 1 calc(100% - 25px); /* 1 card per row */
        
    }
    .exam-names-container {
        padding: 10px 0; /* Adjust padding for smaller screens */
       
    }

    /* Always show the explore card if there are more than 1 card */
    .exam-cards-container:has(.exam-card:nth-child(n+2)) .exploreAll {
        display: flex; /* Show explore card */
        justify-content: center; /* Center text */
        align-items: center; /* Center vertically */
        flex-basis: 100%; /* Take full width */
        padding: 20px; /* Add some padding */
        margin: 10px; /* Margin around the explore card */
        border-radius: 10px; /* Rounded corners */
    }
}


