.prevpapers-outer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f8f9fa;
}

/* Container for the prevpapers */
.prevpapers-container {
    flex-direction: column;
    align-items: left;
    padding: 20px;
    width: 100%;
    max-width: 1140px;
}
.prevpapers-mainheading{
    font-size: 24px;
    font-family: "Roboto",sans-serif;
    
}

.prevpaper-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    padding: 20px;
    transition: transform 0.2s;
    width: 680px;
}

.prevpaper-card:hover {
    transform: scale(1.02);
}

/* Header (Label) */
.prevpaper-card-header {
    display: flex;
    justify-content: flex-start;
    font-family: "Roboto",sans-serif;
}

.prevpaper-card-label {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-family: "Roboto",sans-serif;
}

/* Content Section */
.prevpaper-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

/* Left Side Info */
.prevpaper-card-info-left {
    display: flex;
    flex-direction: column;
}

.prevpaper-card-title {
    font-size: 18px;
    margin: 10px 0;
    font-family: "Roboto",sans-serif;

}

.prevpaper-card-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 12px;
    color: #666;
    font-family: "Roboto",sans-serif;
}

.prevpaper-card-info span {
    display: flex;
    align-items: center;
}

.icon {
    margin-right: 5px;
    font-size: 16px;
}

/* Right Side Button */
.prevpaper-card-info-right {
    display: flex;
    align-items: center;
    font-family: "Roboto",sans-serif;
}

.start-test-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Roboto",sans-serif;
}

.start-test-btn:hover {
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
}

.button-icon {
    margin-right: 8px; /* Add some space between the icon and text */
}

/* Footer Section for Languages */
.prevpaper-card-languages {
    margin-top: 15px;
    font-size: 13px;
    color: #555;
    font-family: "Roboto",sans-serif;
}

.prevpaper-card-languages span {
    font-weight: bold;
    color: #007bff;
    font-family: "Roboto",sans-serif;
}

/* View More Button Styles */
.view-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    font-family: "Roboto",sans-serif;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
    margin-right: 200px;
}

.view-more-btn:hover {
    background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 988px) {
    .prevpaper-card {
        width: 800px;
        padding: 15px;
    }
}

@media (max-width: 850px) {
    .prevpaper-card {
        width: 650px;
    }

    .prevpaper-card-label {
        font-size: 12px;
    }

    .prevpaper-card-title {
        font-size: 15px;
    }

    .prevpaper-card-info {
        font-size: 12px;
    }

    .icon {
        font-size: 12px;
    }
}

@media (max-width: 700px) {
    .prevpaper-card {
        width: 550px;
    }
}

@media (max-width: 600px) {
    .prevpaper-card {
        width: 500px;
        padding: 10px;
    }

    .prevpaper-card-title {
        font-size: 14px;
    }

    .start-test-btn {
        font-size: 13px;
    }

    .view-more-btn {
        font-size: 14px;
    }
}

@media (max-width: 550px) {
    .prevpaper-card {
        width: 400px;
    }

    .prevpaper-card-title {
        font-size: 13px;
    }

    .start-test-btn {
        font-size: 11px;
    }
}

@media (max-width: 450px) {
    .prevpaper-card {
        width: 350px;
    }

    .prevpaper-card-title {
        font-size: 12px;
    }
}

@media (max-width: 380px) {
    .prevpaper-card {
        width: 310px;
    }
}
