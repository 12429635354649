/* _________________common css for all_________________ */

.coursetemplate2-title-and-expand-container-overall {
  /* border-left: 4px solid #427bfe; */
  border-top: 4px solid #427bfe;
  /* border-right: 1px solid #002884;
  border-bottom: 1px solid #002884; */
  box-shadow: 0px 0px 20px 0px rgba(0,0,0,0.1);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}
.coursetemplate2-title-and-expand-container-overall:hover {
  transition: transform 0.3s ease;
  box-shadow: 0 0 10px 1px rgba(177, 223, 242, 0.804);
}
.coursetemplate2-title-and-expand-container-overall.active {
  border-left: 1px solid darkblue;
  border-top: 1px solid darkblue;
  border-right: 1px solid darkblue;
  border-bottom: 1px solid darkblue;
}

.coursetemplate2-general-first-three-tabs {
  padding: 10px;
  margin: auto;
  max-width: 1000px;
  display: grid;
}

.coursetemplate2-title-and-expand-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
}

.coursetemplate2-title-expandicon {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 1;
}

.coursetemplete2-title {
  padding-left: 5px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 25px;
  text-align: left;
}

.course-template-expand-icon-color {
  color: black;
}

.coursetemplate2-content-render-conatiner {
  margin: 2px;
  margin-left: 10px;
  margin-right: 10px;
  border-top: 0.5px solid #209ae65e;
  padding: 5px;
}

.coursetemplate2-checkicon-and-points {
  display: flex;
  margin-top: 12px;
  padding-bottom: 5px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: justify;
}

.bulletpoints {
  font-size: 12px !important;
}

.buttonicons-common {
  font-size: 17px !important;
  margin-right: 4px;
  cursor: pointer;
}


/* ____________________________End_____________________________ */

/* ________________________Header_Container___________________ */

.course-header-section-background-color {
  background-color: #fafbff;
  padding-top: 10px;
  padding-bottom: 30px;
}

.course-name-container {
  display: block;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}
.coursetemplate2-course-name {
  font-size: 30px; /* Adjusted for responsiveness */
  font-weight: bold;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;  
  /* background-color: white; */
  display: flex;
  text-align: left;
}

.coursetemplate2-course-category-name {
  font-size: 15px;
  color: rgb(87, 87, 87);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding-top: 5px;
  display: flex;
  align-items: center;
}

.category-icon-size {
  font-size: 19px !important;
  color: blue;
  margin-top: -2px;
}
.category-space {
  margin-left: 3px;
}

.coursetemplate2-course-image-geninfo-container {
  display: flex;
  max-width: 1000px;
  gap: 35px;
  justify-content: center;
  margin: auto;
  margin-top: 15px;
  padding-left: 10px;
  padding-right: 10px;
}

.coursetemplate2-course-image-conatiner {
  background-color: white;
  border-radius: 10px;
  /* border: 2px solid blue; */
  height: 390px;
  flex: 1;
  /* box-shadow: 0px 0px 1px 1px rgb(223, 221, 221); */
  overflow: hidden; /* Ensure content does not overflow the container */
  position: relative; /* For positioning the overlay */
}

.coursetemplate2-image-container-final-view {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  box-shadow: 0px 0px 8px 1px rgb(223, 221, 221);
  /* border: 1px solid red; */
}

.coursetemplate2-image-container-final-view img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.6s ease-in-out;
}

.coursetemplate2-image-container-final-view img:hover {
  transform: scale(1.1) !important;
}

.coursetemplate2-course-General-info {
  box-shadow: 0px 0px 10px 0px rgb(0, 0, 0, 0.1);
  background-color: white;
  color: black;
  min-width: 200px;
  max-height: 390px;
  border-radius: 10px
}

.coursetemplate2-geninfo-div {
  display: flex;
  padding: 14px;
  margin: 8px;
  border-bottom: 0.5px solid #c7c0c09a;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  justify-items: center;
  align-items: center;
}

.coursetemplate2-icons-color-alignment {
  color: blue;
}

.general-info-align-top {
  margin-top: -4px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: black;
  font-weight: 600;
  font-size: 17px;
}

.coursetemplate2-geninfo-div-download-brochure {
  display: flex;
  justify-content: center;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  justify-items: center;
  align-items: center;
  margin-top: -33px;
  padding: 12px;
  
}



.course-download-brochure  {
  font-size: 1rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */
  margin-top: 25px;
  padding: 12px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #2196f3;
  font-weight: bold;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width:100%;
  text-align: center;
  align-content: center;
  justify-content: center;

}

.color-change-gradient{
  background-image: linear-gradient(40deg, #553e7a, #d65db1, #fefefe, #fe7441,#fbb635,#f5f537);
font-weight: bold;

background-clip: text;
color: rgba(190, 33, 33, 0);
  background-size: 400%;
  animation: gradient-animation 5s ease infinite;
}

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.course-download-brochure:hover {
  transform: scale(1.02) !important;
  background-color: rgb(4, 99, 4);
}


@media screen and (max-width: 1128px) {
  .coursetemplate2-course-image-geninfo-container {
    display: block;
  }
  .coursetemplate2-course-General-info {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 10px;
    min-height: unset;
  }
  .coursetemplate2-course-image-conatiner {
    max-width: 100%;
  }
}

@media screen and (max-width: 875px) {
  .coursetemplate2-course-General-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-top: 10px;
    min-height: unset;
    max-height: 400px;
  }
}

@media screen and (max-width: 700px) {
  .coursetemplate2-course-General-info {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 10px;
    max-height: 434px;
  }
}
/* __________________________end_________________________*/

/* __________________Course Template2 tabs section css___________ */


.coursetemplate2-tabs-navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
  box-shadow: 0px 0px 8px 1px rgb(223, 221, 221);
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  position: sticky;
  top: 0px;
  /* z-index: 1001; */
  padding: 10px;
  overflow-x: scroll;
  scrollbar-width: none;
  max-width: 980px;
  margin: auto;
}

.coursetemplate2-tabs-navbar.sticky {
  max-width: 100%;
}

.coursetemplate2-tabs-container {

 background-color: #ffffff;
  box-shadow: 0px 0px 8px 1px rgb(255, 255, 255);
}

.coursetemplate2-each-tabs {
  padding: 10px;
  transition: transform 0.4s ease-in-out;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 20px;
  cursor: pointer;
}

.coursetemplate2-each-tabs.active {
  background-color: rgba(218, 226, 250, 0.461);
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  color: blue;
}

.coursetemplate2-each-tabs:hover {
  background-color: rgba(255, 255, 255, 0.461);
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  color: blue;
  cursor: pointer;
  transform: scale(1.05);
}

.objctive-background-color {
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
}

.Skills-background-color {
  background-color: #fafbff;
}

.Learners-background-color {
  background-color: #fafbff;
}

.Content-background-color {
  background-color: #fafbff;
}

.CourseInclude-background-color {
  background-color: #ffffff;
}

.Instructor-background-color{
  background-color: #fafbff;
}

/* __________________end___________ */

/* _____________________courseTemplate2OverViewTemplate_____________ */

.buttonicons1 {
  font-size: 20px !important;
  margin-right: 4px;
  margin-top: 2px !important;
  cursor: pointer;
  color: green !important;
}
/* _________________End________________________ */

/* ________________course-contnet__________________ */

.coursetemplate2-subtitle-and-expand-container-overall {
  box-shadow: 0px 0px 3px 1px rgb(214, 213, 213);
  background-color: white;
  border-radius: 10px;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  margin-bottom: 12px;
}

.coursetemplate2-subtitle-and-expand-container-overall:hover {
  transition: transform 0.3s ease;
  box-shadow: 0 0 5px 1px rgba(177, 223, 242, 0.804);
}
.coursetemplate2-subtitle-and-expand-container-overall.active {
  border-left: 4px solid rgba(177, 223, 242, 0.804);
}

.coursetemplate2-content-textfiled-fontsize {
  font-size: 32px;
}

.coursetemplete2-subtitle {
  margin-top: 6px;
  padding-left: 5px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 18px;
  text-align: left
}

.coursetemplate2-checkicon-and-points-content {
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
}

/* _____________________End_______________________ */

/* _________________instructor_______________ */

.coursetemplete2-title-instructor {
  padding: 10px;
  max-width: 1000px;
  margin: auto;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 25px; 
  display: flex;
  margin: auto;
  
  
}

.coursetemplate2-instructor-cards-container {
  max-width: 1050px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: auto; /* Enable horizontal scroll */
  scroll-snap-type: x mandatory; /* Snap scrolling for one card at a time */
  margin: auto;
  scrollbar-width: none;
}

.coursetemplate2-instructor-card {
  flex: 0 0 100%; /* Each card takes full width */
  scroll-snap-align: start; /* Snap alignment */
  box-sizing: border-box;
}

/* _________________End_______________ */

/* _____________________Course Include_________________ */
.courseinclude-width-aligment {
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}

.CourseInclude-title {
  padding-top: 30px;
  max-width: 1000px;
  margin: auto;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 25px;
 
}

.course-include-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
}

.course-include-image-section {
  max-height: 400px;
  max-width: 400px;
  
}

.course-include-image-section img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.course-include-tabs-section {
  padding:10px;
}

.each-include-element-conatiner {
  display: flex;
  align-items: center;
  font-size: 20px;
  padding: 15px;
  text-align: left;
  gap: 4px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

@media screen and (max-width: 930px) {
  .course-include-grid {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
  .course-include-tabs-section {
    padding: 15px;
  }
}
/* _______________________end_______________________ */

/* _________________Course template FAQ______________ */

/*__________________FAQ component______________________*/
.course-template-Faq-section-background-color {
  background-color: #ffffff;
  height: auto;
  padding-top: 30px;
}

.course-template-Faq-section-conatiner-width-conatiner {
  max-width: 1000px;
  margin: auto;
  padding: 10px;
}

.course-template-Faq-section-heading-alignemnt {
  margin: auto;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  font-size: 25px;

}

.course-template-Faq-section-flex-container {
  display: flex;
  justify-content: space-between;
  /* flex-direction: row-reverse; */
  gap: 5px;
}

.course-template-Faq-section-left-sction {
  flex: auto;
  padding-top: 20px;
  overflow-y: scroll;
  scrollbar-width: none;
  max-height: 400px;
  width: -webkit-fill-available;
  padding-bottom: 2px;
}
.course-template-Faq-section-right-sction {
  max-height:400px;
  max-width: 400px;
  object-fit: cover;
  
}
.course-template-Faq-section-right-sction img {
  margin:auto;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 930px) {
  .course-template-Faq-section-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}

/* ____________________Enquiry section____________________ */


.course-enquiry-section-bakground-color{
  background-color: #fafbff;
}
.course-enquiry-section-container {
  max-width: 1000px;
  margin: auto;

  padding: 10px;
}

.course-enquiry-section-flex-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.course-enquiry-section-image-conatiner {
  max-height: 450px;
  max-width: 450px;
}

.course-enquiry-section-image-conatiner img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

/* .enquiry-section-Button-section {
  padding: 50px;
} */

.course-enquiry-section-heading-section {
  font-size: 25px;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left;
}

.course-enquiry-section-points {
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  text-align: left;
}

.course-enquiry-section-point-icons {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
}

.course-buttoniconspoints-enquiry {
  font-size: 25px !important;
  margin-right: 4px;
  margin-top: -4px !important;
  cursor: pointer;
  color: green;
  
}

.course-buttonicons {
  font-size: 18px !important;
  margin-right: 4px;
  margin-top: -2px ;
  cursor: pointer;
  color: green;
  
}

.course-button-container {
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */
  margin-top: 25px;
  padding: 16px;
  padding-top: 20px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #2196f3;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.course-button-container:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.course-buttonicons-common-enquiry {
  font-size: 24px !important;
  padding-left: 4px;
  cursor: pointer;
  color: white;
  margin-top: -4px;
  animation: dance 0.5s infinite alternate;
  transition: color 0.2s ease-in-out;
}

.course-button-container:hover .buttonicons-common-enquiry {
  color: red;
}

@keyframes dance {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

@media screen and (max-width: 830px) {
  .course-enquiry-section-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
  }
}
/* _______________________end_______________________ */


.coursetemplate2-instructor-cards-container {
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.coursetemplate2-instructor-card {
  min-width: 25%; /* Adjust the width depending on how many cards you want visible at once */
  transition: transform 0.5s ease-in-out;
}

.arrow-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.arrow-left {
  left: 0;
}

.arrow-right {
  right: 0;
}

.carousel-dots {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.carousel-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  margin: 0 5px;
  cursor: pointer;
}

.carousel-dot.active {
  background-color: #333;
}
