/* __________________________________ Accordion FAQ - Start _____________________ */
.faqParentContainer{
    width: 100%;
    justify-content: center; /* Centers the inner container horizontally */
    align-items: center;  
    align-content: center;
}
.faqContainer {
    margin: 0 auto;
    width: 95%;
}
.instructorFAQHeader{
    margin-left: 200px;
    margin-right: 200px;
}

@media (max-width: 768px) {
    .faqContainer{
        width: 80%;
    }
    .instructorFAQHeader{
        margin-left: 50px;
        margin-right: 50px;
    }
}





.instructorFAQ{
    margin-left: auto;
    margin-right: auto;
}
.accordionQuestion{
    color: black !important;
    font-weight: bold;
}

.accordionSummary{
    border-radius: 10px;
}
.accordionDetail{
    border-radius: 10px;
    padding-bottom: 20px;
}

.accordionExpanded{
    margin-bottom: 20px;
    border-left: 4px solid darkblue;
    border-top: 1px solid darkblue;
    border-right: 1px solid darkblue;
    border-bottom: 1px solid darkblue;
    border-radius: 7px !important;
}
.exHOv:hover{
    transform: scale(1.02);
    transition: transform 0.3s ease;
    box-shadow: 0 0 10px rgba(177, 223, 242, 0.804);
}
.accordionCollapsed{
    margin-bottom: 20px;
    border-left: 1px solid darkblue;
    border-top: 1px solid darkblue;
    border-right: 1px solid darkblue;
    border-bottom: 1px solid darkblue;
   
}
.Acc_divider{
    border: .5px solid #c7c0c0;
    margin-bottom: 20px;
    margin-top: -10px;
}
/* __________________________________ Accordion FAQ - End _____________________ */

