/* General styles */
.contact-form-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 1.8s ease-out, transform 0.6s ease-out;
}

/* Animation class */
.contact-form-wrapper.animate {
  opacity: 1;
  transform: translateY(0);
}

/* Container for the image and info */
.contact-form-outside {
  display: flex;
  align-items: flex-start;
  width: 80%;
  max-width: 1140px;
  margin: 20px;
}

/* Image container */
.contact-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
}

/* Contact image */
.contact_image {
  width: 80%;
  height: 70%;
  max-width: 500px;
}

/* Contact info section */
.contact-info {
  width: 50%;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
}

/* Heading styles */
.contact-info h2 {
  color: #333;
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 20px;
  white-space: nowrap;
}

/* Subheading styles */
.contact-info p {
  color: #777;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

/* Button styles */
.contact-button {
  width: auto;
  padding: 12px;
  border: none;
  background-color: #007bff;
  color: white;
  font-size: 17px;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out;
}

.contact-button:hover {
  transform: scale(1.05);
  background-color: rgb(4, 99, 4);
}

/*__________________ contact section card container _______________________*/
.contact-card-container {
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  padding: 10px;
  width: 1140px;
}

/*__________________ contact section card outside _______________________*/
.contact-card-outside{
  width: 100%;
  padding: 40px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
}

/*__________________ contact section card _______________________*/
.contact-card {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 20px;
  flex: 1;
  max-width: 300px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  text-align: center;
  transition: opacity 0.5s ease, transform 0.5s ease;
}

/*__________________ contact section card hover _______________________*/
.contact-card:hover{
  background-color: white;
  box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5);
  transform: scale(1.03);
  transition: transform 1s, box-shadow 1s;
  outline: 4px solid #078efb;
}
/*__________________ contact section card hover icon _______________________*/
.contact-card:hover .icon{
  color: #0056b3 !important;
}


/*__________________ contact section card icon _______________________*/
.contact-card .icon {
  font-size: 2rem;
  color: #007bff;
  margin-bottom: 10px;
}

/*__________________ contact section card heading _______________________*/
.contact-card h3 {
  font-size: 1.25rem;
  margin: 10px 0;
  color: #333;
}

/*__________________ contact section card paragraph _______________________*/
.contact-card p {
  font-size: 1rem;
  color: #666;
}

/*__________________ contact section card link _______________________*/
.contact-card a{
  text-decoration: none;
}

/*__________________ Responsive design _______________________*/
@media (max-width: 1128px) {
  .contact-form-outside {
    flex-direction: column;
  }

  .contact-info, .contact-image-container {
    width: 100%;
  }

  .contact-info {
    text-align: center;
    padding-left: 0;
  }

  .contact-info h2{
    white-space: normal;
  }

  .contact-button {
    width: auto;
  }

  .contact-card-outside{
    padding: 0;
  }
  .contact-card-container {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  .contact-card {
    margin-bottom: 20px;
    width: 100%;
  }
}

@media (max-width: 468px){
  .contact-form button{
    width: 40%;
  }
}

  