.hire-hero-main{
    width: 100%;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.hire-hero-section {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px 50px;
    width: 1140px;
  }
  
  .hire-hero-left {
    max-width: 50%;
    font-family: "Roboto", sans-serif;
  }
  
  .hire-hero-left h1 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .hire-hero-left p {
    font-size: 17px;
    margin-bottom: 30px;
  }
  
  .cta-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
    cursor: pointer;
  }
  
  .cta-button:hover {
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  
  .hire-hero-right img {
    max-width: 100%;
    border-radius: 10px;
  }

  .mission-statement-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fafbff;
  }

  .mission-statement {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px;
    width: 1140px;
  }
  
  .mission-left {
    width: 50%;
  }
  
  .mission-left img {
    width: 80%;
    height: auto;
    border-radius: 10px;
  }
  
  .mission-right {
    width: 50%;
    font-family: "Roboto", sans-serif;
    text-align: justify;
  }
  
  .mission-right h2 {
    font-size: 2em;
    margin-bottom: 20px;
  }
  
  .mission-right p {
    font-size: 17px;
    line-height: 1.5;
  }
  

  .usp-main{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: #fafbff !important;
  }
  .usp-section {
    text-align: center;
    padding: 50px;
    width: 1140px !important;
    background-color: none;
    font-family: "Roboto", sans-serif;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }

  .usp-section.fade-in {
    opacity: 1;
    transform: translateY(0);
  }
  
  .usp-section h2 {
    font-size: 2.5em;
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  .usp-cards {
    display: flex;
    justify-content: space-around;
    align-items: stretch;
    gap: 20px;
  }
  
  .usp-card {
    background: #f8f9fa;
    border-radius: 10px;
    padding: 20px;
    flex: 1;
    max-width: 300px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    text-align: center;
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .usp-card:hover{
    background-color: white;
    box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5);
    transform: scale(1.03);
    transition: transform 1s, box-shadow 1s;
    outline: 4px solid #078efb;
  }

  .usp-card:hover .usp-icon{
    color: #0056b3;
  }
  
  .usp-icon {
    font-size: 3em;
    color: #007bff;
    margin-bottom: 20px;
  }
  
  .usp-card h3 {
    font-size: 1.5em;
    margin-bottom: 15px;
  }
  
  .usp-card p {
    font-size: 1em;
    color: #666;
  }


  .Hire-benefits-main {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: white;
  }
  
  .Hire-benefits-container {
    padding: 20px;
    width: 1140px;
    text-align: center;
    font-family: "Roboto", sans-serif;
  }
  
  .Hire-benefits-heading h2 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .Hire-benefits-content {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    height: 400px;
  }
  
  .Hire-benefit {
    flex: 1;
    max-width: 30%;
    height: auto;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.6s ease-out, transform 0.6s ease-out;
  }
  
  .Hire-benefit.fade-in {
    opacity: 1;
    transform: translateY(0);
  }

  .Hire-benefit img{
    height: 35%;
  }

  
  .Hire-benefit h3 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }
  
  .Hire-benefit p {
    font-size: 1em;
    color: #666;
  }
  
  .Hire-benefit:hover {
    transform: translateY(-10px);
  }


  @media (max-width: 768px) {
    .hire-hero-section {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
    }
  
    .hire-hero-left, .hire-hero-right {
      max-width: 100%;
    }
  
    .hire-hero-left {
      margin-bottom: 20px;
    }

    .hire-hero-left p{
        text-align: justify;
    }

    .hire-hero-right{
        text-align: center;
    }

    .mission-statement {
        flex-direction: column;
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    
    .mission-left, .mission-right {
       width: 80%;
    }

    .mission-right{
        order: -1;
    }
    
    .mission-right {
        margin-bottom: 20px;
        width: 90% !important;
    }

    .usp-cards {
        flex-direction: column;
        align-items: center;
    }
    
    .usp-card {
      width: 80%;
      margin-bottom: 20px;
    }

    .Hire-benefits-content {
      flex-direction: column !important;
      align-items: center;
      height: auto !important;
    }
  
    .Hire-benefit {
      max-width: 80%;
      margin-bottom: 20px;
    }
    .Hire-benefit img{
      width: 40%;
    }
  }


  @media (max-width: 568px) {
    .Hire-benefits-heading h2{
      font-size: 2rem;
    }
  }