/* ____________________Overall Footer Styling________________________ */

.footer-bottombar-background-color {
  background-color: #f4f5ff;
}

.footer-width-control-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
}

.footer_container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: auto;
  padding: 10px;
  align-content: center;
  gap: 10px;
}

/* Icons Styling */
.svg-inline--fa {
  margin-right: 15px;
  font-size: 28px;
}

.footer_content .anchor-tag-border-remove {
  position: relative;
}

.footer_content .footer-1st-paragraph-component a::before {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 0;
  height: 2px;
  background-color: #673de6;
  transition: width 0.3s ease, left 0.3s ease;
  transform: translateX(-50%);
}

.footer_content .footer-1st-paragraph-component a:hover::before {
  width: 100%;
  left: 50;
}

.footer_content a {
  color: #2f1c6a !important;
  text-decoration: none;
}

.footer_content a:hover {
  color: #673de6;
}

.social-icons i:hover {
  color: #673de6;
}

.footer_divider {
  width: 100%;
  height: 1px;
  background-color: #d5dfff;
}

.footer-heading {
  font-size: 1.37em;
  margin-bottom: 15px;
  text-align: left;
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}

.footer-1st-paragraph-component {
  padding: 12px;
  display: flex;
  color: #2f1c6a;
}

.footer-1st-paragraph-component3 {
  padding: 12px;
  display: flex;
}

.footer-1st-paragraph-component-header {
  padding: 12px;
  padding-bottom: 1px;
}

.footer-component2-grid-conatiner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 350px;
}


.footer-component3-alignmet-conatiner-flex {
  display: flex;
  gap: 20px;
}

/* _________________Footer bottom bar_____________ */

.bottom_bar {
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.bottom_bar p {
  margin: 0;
  color: #2f1c6a;
  font-size: 16px;
}

/* Media Queries for Responsiveness */

@media (max-width: 890px) {
  .footer_container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .footer_container {
    grid-template-columns: repeat(1, 1fr);
    width: auto;
    text-align: left;
    padding-left: 50px;
  }
}

@media (max-width: 400px) {
  .footer_container {
    padding-left: 25px;
  }
}
