/* _______________________Review header section css______________________ */
.interviewquestion-section-header-backgroundcolor {
  background-color: #fafbff;
}

.interviewquestion-section-width-container {
  max-width: 1140px;
  margin: auto;
}

.interviewquestion-section-flex-container {
  display: flex;
  justify-content: space-between;
}

.interviewquestion-section-content-content-section {
  padding: 10px;
}

.interviewquestion-section-image-container {
  max-width: 450px;
  width: 90%;
  max-height: 450px;
  height: 91%;
}

.interviewquestion-section-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .interviewquestion-section-flex-container {
    display: block;

    margin: auto;
  }

  .interviewquestion-section-image-container {
    margin: auto;
  }

  .interviewquestion-section-content-content-section {
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

/* ___________________interview question render section_____________ */
.interview-question-render-section-heading {
  font-size: 26px;
  font-weight: bold;
  padding: 10px;
  text-align: start;
  margin: auto;
}

.interview-question-render-section-container {
  margin: auto;
  max-width: 1140px;
  padding-bottom: 20px;
}

.interview-question-input-fields {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  gap: 10px;
}

.button-container-interview-questions {
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  padding: 15px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #007bff;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.button-container-interview-questions:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.interview-question-button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

/* ___________________interview-question-cards_________ */

.interview-questions-card {
  max-width: 1140px;
  margin: auto;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  width: 97%;
  background-color: white;
}

.interview-questions-card:hover {
  outline: 1px solid #078efb;
}

.interview-questions-card.expanded {
  border-top: 4px solid #427bfe;
}

.interview-questions-container {
  padding: 1px;
  padding-bottom: 5px;
  font-size: 16px;
  margin: 10px 20px;
  align-items: center;
  justify-content: space-between;
}

.interview-questions-question {
  font-size: 1.2em;
  color: #333; 
  margin-bottom: 5px;
}

.interview-questions-separator {
  height: 1px; 
  background-color: #e1e1e1; 
  margin: 5px 0;
  width: 100%; 
}

.interview-questions-info {
  font-size: 0.9em;
  color: #666; 
}

.interview-questions-skills {
  margin-top: 10px;
  font-size: 0.8em;
  color: #666; 
}

.interview-questions-answer {
  font-size: 16px;
  padding-top: 1px;
  line-height: 22px;
  padding: 20px;
  padding-bottom: 0px;
  font-size: 16px;
  padding-top: 1px;
  margin-top: -12px;
  line-height: 24px;
  max-height: 72px; 
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  transition: max-height 0.3s ease;
  text-align: justify;
  line-clamp: 3;
  margin-left: -10px; 
  padding-left: 10px; 
  padding-top: 10px;
}

.interview-questions-answer.interview-questions-expanded {
  max-height: none; 
  height: auto; 
  -webkit-line-clamp: unset;
  line-clamp: 3;
}

.interview-questions-toggle-text {
  cursor: pointer;
  color: #427bfe;
  display: block;
  margin-top: 5px;
  font-size: 0.8em;
  padding-bottom: 10px;
}

.interview-questions-toggle-text:hover {
  color: rgb(4, 99, 4);
}

/*________search______*/

.interview-search-icon {
  color: gray;
}
.interview-question-header-main {
  flex: 1;
  margin-right: 5px;
}

.interview-question-header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.interview-question-header-search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1130px;
  border: 1px solid #ddd;
  padding: 8px;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(197, 195, 195, 0.1);
  background-color: #fff;
  transition: border-color 0.3s ease;
  margin: auto;
  margin-top: 2px;
  cursor: pointer;
}

.interview-question-header-search.expanded {
  border: 2px solid #276bd8;
  cursor: auto;
}

.interview-question-search-icon {
  margin-right: 10px;
}

.interview-question-no-border {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
}

/* _________________interview question add____________  */
.interviewquestion-section-add-interview-backgroundcolor {
  background-color: white;
  padding-top: 20px;
}

.interviewquestion-section-add-interview-width-container {
  max-width: 1140px;
  margin: auto;
}

.interviewquestion-section-add-interview-flex-container {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */

  justify-items: flex-start;
}

.interviewquestion-section-add-interview-content-content-section {
  padding: 10px;
  max-height: 550px;
  max-width: 600px;
  height: 90%;
  width: 95%;
}

.interviewquestion-section-add-interview-image-container {
  max-width: 400px;
  width: 90%;
  max-height: 400px;
  height: 91%;
}

.interviewquestion-section-add-interview-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .interviewquestion-section-add-interview-flex-container {
    display: block;
    margin: auto;
  }

  .interviewquestion-section-add-interview-image-container {
    margin: auto;
  }

  .interviewquestion-section-add-interview-content-content-section {
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

.add-interview-enquiry-section-points {
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  text-align: left;
}

.add-interview-enquiry-section-point-icons {
  display: flex;
  margin-top: 3px;
  gap: 5px;
  padding: 10px;
  line-height: 24px;
}

.add-interview-buttoniconspoints-enquiry {
  font-size: 25px !important;
  margin-right: 4px;
  margin-top: 1.5px !important;
  cursor: pointer;
  color: green;
}

.interview-question-button-container-add-questions {
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* ________________interview question geninfo modal___________ */

.interview-question-add-geninfo-modal {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
}

.interview-question-add-geninfo-modal1 {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  max-width: 550px;
  width: 90%;
}

.interview-question-add-geninfo-modal-container {
  max-width: 450px;
  width: 90%;
  background-color: #fafcff;
  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.09);
  position: relative;
  padding: 25px;
  border-radius: 10px;
  margin: 10px;
  max-height: auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 20px;
}

.enquiry-form-addeducationalMapmodalcancel {
  position: absolute !important;
  top: -16px;
  right: -15px;
}

.enquiry-form-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px !important;
}

.enquiry-table-header-interview-question-add-modal {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 24px;
  margin-top: -10px;
  font-weight: bold;
  padding: 6px;
  padding-left: 0px;
  padding-bottom: 14px;
  overflow: hidden;
  height: auto;
}

.enquiry-table-header-interview-question-add-modal-gen-info {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  font-size: 24px;
  margin-top: -10px;
  font-weight: bold;
  padding: 6px;
  padding-left: 0px;
  padding-bottom: 14px;
  overflow: hidden;
  height: auto;
}

@media screen and (max-width:417px) {
  .enquiry-table-header-interview-question-add-modal-gen-info{
    font-size: 20px;
  }
  
}


.over-flow-control-container {
  max-height: calc(100vh - 145px);
  /* border: 1px solid red; */
  overflow-y: scroll;
  scrollbar-width: none;
  /* max-width: 700px;
  width: 100%; */
}

.add-gen-info-grid-container {
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 14px;
}

.next-button-alignment-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

@media screen and (max-width: 430px) {
  .add-gen-info-grid-container {
    grid-template-columns: 1fr;
  }
}

/* __________Add question and information-render-section____________ */
.interview-question-add-question-modal-container {
  max-width: 561px;
  width: 100%;
  background-color: #dfdfdf;
  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.09);
  position: relative;
  padding: 25px;
  border-radius: 10px;
  margin: 10px;
  max-height: auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 23px;
}

/* _____________filter render section____ */
.interview-question-all-section-main-flex-container {
  display: flex;
  padding: 10px;
  justify-content: space-between;
  justify-content: center;
}

.interview-question-section-filter-section {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 250px;
  width: 95%;
  height: 430px;
  padding: 20px;
  position: sticky;
  top: 70px;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #dedede #fffefe;
}
.inerviewquestion-all-questions-render-section {
  flex: 1;
  /* margin-top: 30px; */
}

/* _________for responsive design media query_____ */
@media screen and (max-width: 590px) {
  .interview-question-section-filter-section {
    display: none;
  }
}

@media screen and (max-width: 676px) {
  .interview-question-section-filter-section {
    display: none;
  }
}

/* _______________modal2 add question _________ */
.interview-question-add-question-modal-container2 {
  max-width: 1000px;
  width: 95%;
  background-color: #fafcff;
  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.09);
  position: relative;
  padding: 25px;
  border-radius: 10px;
  margin: 10px;
  max-height: auto;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 23px;
}
.add-interview-question-modal2-container {
  border: 1px solid red;
}

.general-info-alignment-in-modal2 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  justify-items: center;
  gap: 20px;
  box-shadow: 0px 0px 1px gray;
  padding: 10px;
  border-radius: 10px;
  flex-wrap: wrap;
}

.general-info-align-alihn-inside-content {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 10px;
  flex-wrap: wrap;
  max-height: 70px;
  height: 100%;
  overflow: scroll;
  scrollbar-width: none;
}

.footer-each-container-modal2 {
  font-size: 10px;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-left: 1px solid rgb(196, 196, 196); */
  padding-left: 10px;
  flex-wrap: nowrap;
}

.footer-each-conttainer-icons-modal2 {
  font-size: 14px;
  color: rgb(136, 136, 136);
}

.details-information-span-modal2 {
  color: black;
  font-weight: bold;
  margin-top: -0.3px;
  font-size: 16px;
}



.add-question-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: -10px;
}

.add-the-question-card-render-section {
  height: calc(100vh - 255px);
  margin-top: 10px;
  overflow: scroll;
  scrollbar-width: none;
  /* border: 1px solid red; */
  margin-bottom: 10px;
}

.add-the-question-save-button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-flex-container-modal2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  justify-items: center;
}

.buttoncommon-edit-geninfo {
  color: blue !important;
  font-size: 18px !important;
}

.question-and-answer-saving-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;
}

.interview-question-section-filter-section-modal-filter {
  padding: 15px;
}

@media screen and (max-width: 530px) {
  .enquiry-table-header-interview-question-add-modal {
    display: none;
  }
  .header-flex-container-modal2 {
    justify-content: center;
    justify-items: center;
    margin-bottom: 8px;
  }
}
