/*__________________ about section comp 1 _______________________*/
.comp_1{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('./AboutImages/background.jpg') no-repeat center center/cover;
}

.comp1_outside {
  width: 1140px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: -60px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding-top: 100px;
  padding-bottom: 200px;
  padding-left: 30px;
}

/*__________________ about section comp 1 wrapper _______________________*/
.comp1_wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 250px;
  padding-left: 140px;
  justify-content: flex-start;
}

/*__________________ about section comp 1 container _______________________*/
.comp1_container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.comp1_container::before {
  box-sizing: border-box;
}

/*__________________ about section comp 1 heading _______________________*/
.comp1_heading {
  color: #181818;
  margin: 0 auto;
  margin-left: 0;
  font-size: 60px;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  font-weight: bold;
  animation: fadeInUp 1s ease-out;
}

/*__________________ about section comp 1 paragraph _______________________*/
.comp1_paragraph {
  color: #181818;
  margin: 0 auto;
  margin-left: 0;
  max-width: 600px;
  padding-top: 40px;
  font-size: 40px;
  font-family: "Roboto", sans-serif;
  white-space: nowrap;
  font-weight: bold;
  animation: fadeInUp 1s ease-out;
  animation-delay: 0.5s;
  animation-fill-mode: both;
}

/*__________________ about section comp 1 text animation _______________________*/
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.about-button{
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px !important;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
  0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  text-transform: uppercase;
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  cursor: pointer;
  margin-top: 30px;
}

.about-button:hover{
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4) !important;
}

/*__________________ about section comp 1 image _______________________*/
.comp1_img {
  position: absolute;
  bottom: 30px;
  z-index: -1;
  animation: floatBobY 3s ease-in-out infinite;
}

/*__________________ about section comp 1 image animation _______________________*/
@keyframes floatBobY {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

/*__________________ about section mission _______________________*/
.mission-outside{
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 10px;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease-out, transform 0.6s ease-out;
}

/*__________________ about section mission animation container _______________________*/
.mission-outside.animate {
  opacity: 1;
  transform: translateY(0);
}

/*__________________ about section mission container _______________________*/
.mission-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 1140px;
}

/*__________________ about section mission content _______________________*/
.mission-content {
  flex: 1;
  padding-right: 20px;
  margin-right: 30px;
  max-width: 600px;
}

/*__________________ about section mission heading _______________________*/
.mission-content h2 {
  font-size: 24px;
  margin-bottom: 10px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 30px;
  text-align: justify;
  white-space: nowrap;
  font-weight: bold;
}

/*__________________ about section mission paragraph _______________________*/
.mission-content p {
  font-size: 16px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  text-align: justify;
}

/*__________________ about section mission image container _______________________*/
.mission-image {
  flex: 1;
  text-align: start;
  margin-right: 30px;
}

/*__________________ about section mission image _______________________*/
.mission-image img {
  max-width: 80%;
  height: auto;
  border-radius: 10px;
}

/*__________________ about section about outside _______________________*/
.about-outside{
  display: flex;
  width: 100%;
  /* padding: 0 40px; */
  align-items: center;
  justify-content: center;
  background-color: #fafbff;
}

/*__________________ about section about container _______________________*/
.about-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 30px;
  width: 1140px;
}

/*__________________ about section about content _______________________*/
.about-content {
  flex: 1;
  padding-right: 20px;
  max-width: 600px;
  margin-right: 120px;
}

/*__________________ about section about heading _______________________*/
.about-content h2 {
  font-size: 30px;
  margin-bottom: 20px;
  color: #333;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

/*__________________ about section about paragraph _______________________*/
.about-content p {
  font-size: 17px;
  color: #333;
  font-family: "Roboto", sans-serif;
  margin-bottom: 10px;
  text-align: justify;
}

/*__________________ about section about image container _______________________*/
.about-image {
  flex: 1;
  text-align: left;
  opacity: 0;
  transform: translateX(50px);
  transition: opacity 0.6s ease-out 0.6s, transform 0.6s ease-out 0.6s;
}

/*__________________ about section about image container animation _______________________*/
.about-image.animate {
  opacity: 1;
  transform: translateX(0);
}

/*__________________ about section about image _______________________*/
.about-image img {
  max-width: 70%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
}
/*__________________ Responsive design _______________________*/
@media (max-width: 997px) {
  .comp1_outside {
    padding-top: 100px;
    padding-bottom: 100px;
    align-items: center;
  }

  .comp1_wrapper {
    padding-left: 0;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 200px;
  }

  .comp1_container {
    padding-left: 80px;
    align-items: left;
  }

  .comp1_heading {
    margin-left: 0;
    font-size: 60px;
    white-space: normal;
  }

  .comp1_paragraph{
    font-size: 40px;
    margin-left: 0;
  }

  .comp1_img {
    position: relative;
    bottom: 0;
    margin-top: 20px;
  }

  .about-outside{
    padding: 0;
  }
  .about-container {
    flex-direction: column;
    padding: 20px 0;
  }

  .about-content {
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 20px;
    width: 80%;
  }

  .about-image{
    text-align: center;
  }

  .about-image img {
    width: 60%;
  }

  .mission-outside{
    padding: 0;
  }

  .mission-container {
    flex-direction: column;
    padding: 20px;
  }

  .mission-content {
    padding-right: 0;
    margin-right: 0;
    margin-top: 20px;
    max-width: 80%;
    order: -1;
  }

  .mission-image {
    margin-right: 0;
    text-align: center;
  }

  .mission-image img {
    max-width: 80%;
  }
}



@media(max-width:680px){
  .comp1_heading {
    margin-left: 0;
    font-size: 40px;
    white-space: normal;
  }

  .comp1_paragraph{
    font-size: 30px !important;
    margin-left: 0;
  }
}

@media(max-width:568px){
  .comp1_outside {
    margin-top: 0;
    padding-top: 30px;
  }

  .comp1_wrapper {
    padding-left: 0;
    height: 150px;
  }

  .comp1_container {
    padding-left: 50px;
  }

  .comp1_img{
    max-width: 90%;
  }

}

@media(max-width:512px){
  .comp1_heading{
    font-size: 30px;
  }
  .comp1_paragraph{
    font-size: 20px !important;
    padding-top: 20px;
  }
}