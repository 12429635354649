/* _________________FeatureLandingpage_____________ */

.ourvision-container-background-color {
  background-color: #fafbff;
}

.featurecard-background-container {
  background-color: #fafbff;
}
.feature-landing-page-width-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
}

.feature-landing-page-width-container1 {
  background-color: #ffffff;
}

.feature-heading-conatainer {
  text-align: left;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
}

.feature-content-container-content {
  text-align: center;
  color: rgb(6, 6, 6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  background-color: #fafbff;
  line-height: 30px;
  /* max-width: 1000px; */
  padding: 10px;
  border-radius: 10px;
  height: auto; /* Adjusts to content height */
  overflow: hidden; /* Prevent overflow if content grows */
  display: flex;
  gap: 15px;
  justify-content: center;
  justify-content: space-evenly;
}

.feature-content-container-content2 {
  text-align: center;
  color: rgb(6, 6, 6);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 15px;

  line-height: 30px;
  /* max-width: 1000px; */

  border-radius: 10px;
  /* background-color: #121212; */
  height: auto; /* Adjusts to content height */
  overflow: hidden; /* Prevent overflow if content grows */
  display: grid;
  gap: 15px;
  justify-content: center;
  grid-template-columns: 1fr 1fr;
  flex-direction: row;
  margin: auto;
}

.feature1-cardrender-container {
  padding: 10px;
  max-width: 1140px;
  margin: auto;
}

/* ____________________Feature card2 component_________________ */

.featurecard2-container {
  max-width: 350px;
  width: 90%;
  background-color: #ffffff;
  text-align: center;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  height: 310px;
  border-radius: 10px;
  /* border-top-left-radius: 30px;
  border-bottom-right-radius: 30px; */
  overflow: hidden;
  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transition */
}

.featurecard2-container:hover {
  /* outline: 1px solid #078efb; */
  box-shadow: 0px 0px 20px rgba(7, 142, 251, 0.5); /* Light up effect */
  transform: scale(1.03); /* Slightly scale up the card */
  outline: 4px solid #078efb;
  transition: transform 1s, box-shadow 1s;
}

.feature-card2-icon-color-and-size {
  color: #078efb;
  font-size: 80px !important;
}

.feature-card2-icon-conatiner {
  /* background-color: rgb(252, 252, 252); */
  padding: 20px;
  padding-bottom: 40px;
  position: relative;
  border-radius: 10px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.feature-card2-heading {
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.feature-card2-content {
  font-size: 18px;
  font-weight: bold;
  padding: 20px;
  font-weight: bold;
  color: rgb(0, 0, 0);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  text-align: left;
  line-height: 22px;
  max-height: calc(22px * 4); /* Max height to accommodate 4 lines */
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Flexbox layout for truncation */
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical; /* Orient the box vertically */
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  text-overflow: ellipsis; /* Add ellipsis (...) at the end */
  line-clamp: 4; /* Limit to 4 lines (experimental) */
}

@media screen and (max-width: 930px) {
  .feature-content-container-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 10px;
  }

  .featurecard2-content {
    max-height: calc(22px * 3);
    height: 270px;
    overflow: hidden; /* Hide overflowing text */
    display: -webkit-box; /* Flexbox layout for truncation */
    -webkit-line-clamp: 3; /* Limit to 4 lines */
    -webkit-box-orient: vertical; /* Orient the box vertically */
    white-space: pre-wrap; /* Preserve whitespace and line breaks */
    text-overflow: ellipsis; /* Add ellipsis (...) at the end */
    line-clamp: 3; /* Limit to 4 lines (experimental) */
  }

  .featurecard2-container {
    height: 300px;
    max-width: 480px;
    width: 98%;
  }
}

@media screen and (max-width: 536px) {
  .feature-content-container-content {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: auto;
  }
  .featurecard2-container {
    max-width: 480px;
  }
}

/* ________________Feature card1 component_____________ */

.feature-card1-width-container {
  width: 1140px;
  margin: auto;
  padding: 10px;
}

.feature-card1-flex-container {
  display: flex;
  max-width: 600px;
  width: 100%;
  height: 220px;
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  /* border: 1px solid gainsboro; */

  border-radius: 10px;
  /* border-top-left-radius: 20px;
  border-bottom-right-radius: 20px; */
  background-color: #ffffff;

  transition: box-shadow 0.3s ease, transform 0.3s ease; /* Add transition */
}

.icon-container {
  min-width: 150px;
  height: 150px;
  margin: 20px;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
  border-radius: 5px;
}

.icon-container img {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.featurecard1-content-container {
  margin: 10px;
}

/* Styling for the icons */
.feature-icon {
  font-size: 80px !important;       /* Size of the icon */
  color: #1180ff;        /* Icon color (can be changed as needed) */
  transition: color 0.3s ease, transform 0.3s ease; /* Smooth transition for hover effects */
}

/* Hover effect for the icons */
.feature-icon:hover {
  color: #1180ff;       /* Change color on hover */
  transform: scale(1.1); /* Slightly increase size on hover */
}

.featurecard1-heading {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
  font-family: "Roboto", sans-serif;
  color: black;
  margin-top: 10px;
  text-align: justify;
}

.featurecard1-inside-content {
  font-size: 17px;
  font-family: "Roboto", sans-serif;
  color: black;
  text-align: left;
  line-height: 21px;
  font-weight: 200;
  max-height: calc(25px * 4); /* Max height to accommodate 4 lines */
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Flexbox layout for truncation */
  -webkit-line-clamp: 4; /* Limit to 4 lines */
  -webkit-box-orient: vertical; /* Orient the box vertically */
  white-space: pre-wrap; /* Preserve whitespace and line breaks */
  text-overflow: ellipsis; /* Add ellipsis (...) at the end */
  line-clamp: 4; /* Limit to 4 lines (experimental) */
}

.feature-card1-flex-container:hover {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); /* Light up effect */
  transform: scale(1.03); /* Slightly scale up the card */
  outline: 4px solid #078efb;
  transition: transform 1s;
}

@media screen and (max-width: 640px) {
  .feature-content-container-content2 {
    grid-template-columns: 1fr;
  }
  .feature-card1-flex-container {
    display: block;
    height: 320px;
  }

  .featurecard1-heading{
    
    text-align: center;
  }

  .featurecard1-inside-content{
    text-align: center;
  }
  .feature-card1-flex-container:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); /* Light up effect */
    transform: scale(1.01); /* Slightly scale up the card */
    outline: 4px solid #078efb;
    transition: transform 1s;
  }

  .icon-container {
    min-width: 120px;
    height: 120px;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
    border-radius: 5px;
  }
}

@media screen and (max-width: 960px) {
  .feature-content-container-content2 {
    padding: 18px;
  }
  .featurecard1-inside-content{
    text-align: center;
  }
  .featurecard1-heading{
    
    text-align: center;
  }
  .feature-card1-flex-container {
    display: block;
    height: 320px;
  }

  .feature-card1-flex-container:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); /* Light up effect */
    transform: scale(1.01); /* Slightly scale up the card */
    outline: 4px solid #078efb;
    transition: transform 1s;
  }

  .icon-container {
    min-width: 120px;
    height: 120px;
    margin: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4); */
    border-radius: 5px;
  }
}
