/* _______________________allcourse header section css______________________ */
.allcourse-section-header-backgroundcolor {
  background-color: #fafbff;
}

.allcourse-section-width-container {
  max-width: 1140px;
  margin: auto;
  /* position: sticky;
  top:60px; */
}

.allcourse-section-flex-container {
  display: flex;
  justify-content: space-between;
}

.allcourse-section-content-content-section {
  padding: 10px;
  padding-top: 20px;
}

.text-alignment-tagline {
  margin-left: 3px;
}

.allcourse-section-image-container {
  max-width: 550px;
  width: 95%;
  max-height: 450px;
  height: 90%;
}

.allcourse-section-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .allcourse-section-flex-container {
    display: block;

    margin: auto;
  }

  .allcourse-section-image-container {
    margin: auto;
  }

  .allcourse-section-content-content-section {
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

/* _____________________all course section____________ */

.allcoursesection-background-color {
  background-color: #fafbff;
}

.all-course-section-main-heading {
  padding: 13px;
  font-size: 26px;
  font-weight: bold;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.all-course-section-main-flex-container {
  display: flex;
  padding: 13px;
  gap: 10px;
}

.all-course-section-filter-section {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-width: 250px;
  width: 95%;
  height: 430px;
  padding: 20px;
  position: sticky;
  top: 70px;
  border-radius: 5px;
  margin-top: 10px;
  overflow-y: scroll;
  scrollbar-width:thin;
  scrollbar-color: #dedede #fffefe;
  /* background-color: rgba(179, 172, 255, 0.18); */
  
  
}

/* Webkit Browsers (Chrome, Safari) */
.all-course-section-filter-section::-webkit-scrollbar {
  width: 15px !important;
  height: 15px !important;
}

.all-course-section-filter-section::-webkit-scrollbar-track {
  background: #3c3939 !important;
}

.all-course-section-filter-section::-webkit-scrollbar-thumb {
  background: #b7b8ba !important;
}

.all-course-section-filter-section::-webkit-scrollbar-thumb:hover {
  background: #699595 !important;
}

.all-course-section-filter-options {
  padding: 5px;
}

.all-course-section-render-section{
  flex: 1;
  /* padding: 10px; */
}

.all-coures-sub-heading-section {
  margin-bottom: 5px;
  margin-top: 5px;
}

.all-course-card-render-section-container {
  flex:  1;
  width: 100%;
  padding-left: 20px;
}

/* ____________________all course render section____________________ */

/* .all-course-card-render-section-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
} */
.all-course-card-render-section-container {
  display: flex;
  flex-direction: column;
 
  
}

.buttonicons-reset-filter{
  font-size: 18px !important;
  margin-top: -2px;
}

.buttonicons-reset-filter1{
  font-size: 18px !important;
  margin-top: -2px;
}

.course-cards-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
 
  
 
}



.Search-and-cleae-filter-section-container{
  display: flex;
  align-items: center;
  justify-content: space-between; 
  margin: auto;
  padding-left: 10px;
  padding-right: 10px;
  gap: 20px;
  position: sticky;
  top:2px;
  z-index: 5;
  background-color: #fafbff;
}

.search-filed-width-control-container{
  flex: 1;
  
}
.clear-filter-section-more-review-button{
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */  
  padding: 16px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #007bff;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.clear-filter-section-more-review-button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}
.clear-filter-section-more-review-button1{
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */  
  padding: 14px;
  border-radius: 5px;
  display: none;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #007bff;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
  
}

.clear-filter-section-more-review-button1:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.duration-flex-container{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  max-width: 240px;
  padding: 4px;
  
}



.pagination-controls {
  display: flex;
  justify-content: center;
  margin-top: 16px;
}

.pagination-button {
  margin: 0 4px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  background-color: #fff;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}


.no-course-available-container{
  background-color: #ffffff;
  width:calc(100% - 30px);
  height: 360px;
  box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4);
  margin: 20px;
  border-radius: 10px;
  min-width: 200px;
}

.no-course-avaibale-image-container{
  max-width: 150px;
  max-height: 150px;
  display: flex;
  margin: auto;
  margin-top: 50px;
}

.no-course-heading-container{
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  text-align: center;
  
}

.no-course-avaibale-image-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


/* _______________modal___________ */

.course-fileter-sections-modal{
  padding: 10px;
  
}
.modal-outer-border-remove{
  width: 100%;
  height: 100vh;
}
.modalcontainer-all-course{
  width: 100%;
  height: 100vh;
}


.course-card-container{
  cursor: pointer;

}

.modal-border-remove-conatiner{
  background-color: rgb(255, 255, 255);
  max-width: 290px;  
width:80%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-out;
  box-shadow: 0px 0 10px rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
}


.seacrch-all-course .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  
  border-radius: 15px !important;
}

.hamburg-closeIcon:hover{
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
}

.modalHeader-all-course {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px;
  /* background-color: #4d7aff; */
  font-size: 24px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  
}


@media screen and (max-width: 930px) { 
  .course-cards-wrapper{
    grid-template-columns:  1fr;
    max-width: 400px;
    margin: auto;
    
  }
  
}

@media screen and (max-width: 676px) { 
  .all-course-section-filter-section{
display: none;
    
  }
  .course-cards-wrapper{
    grid-template-columns: 1fr 1fr;
    max-width: unset;
  }
  .clear-filter-section-more-review-button1{
    display: flex;
  }
  .clear-filter-section-more-review-button{
    display: none;
  }
  
}

@media screen and (max-width: 590px) { 
  .all-course-section-filter-section{
display: none;
    
  }
  .course-cards-wrapper{
    grid-template-columns: 1fr ;
    max-width: 400px;

    margin: auto;
  }
  
}