.backtotop-container {
    position: fixed;
    width: 30px;
    height: 30px;
    background-color: rgb(56, 59, 230);
    bottom: 20px;
    right: 20px;
    text-decoration: none;
    text-align: center;
    line-height: 30px;
    scroll-behavior: smooth;
    z-index: 9;

  }
  .back-to-top-button{
    background-color: rgb(56, 59, 230);
    border: none;
  }

  .back-to-top-icons{
    color: white;
    font-weight: bold !important;
    font-size: 18px !important;
    margin-top: 5px !important;
  }
  