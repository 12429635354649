/* Outer Container */
.exam-details-template-outer-container {
    background-color: #ffffff; 
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

/* Main Container */
.exam-details-template-main-container {
    max-width: 1140px;
    width: 100%;
}

/* Common Styles for all Containers */  
.exam-details-template-container {
    padding: 20px;
    background-color: #ffffff;
    font-family: 'Roboto', sans-serif;
}

/* Description Container */
.exam-details-template-description-container {
    text-align: justify;
    font-size: 17px;
    line-height: 30px;
    padding: 10px 30px; 
    font-family: 'Roboto', sans-serif;
}

/* Titles for Description Container */
.exam-details-template-description-container h3 {
    font-size: 24px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

/* Syllabus Container */
.exam-details-template-syllabus-container {
    text-align: left;
    font-size: 17px;
    line-height: 30px;
    padding: 10px 30px; 
    font-family: 'Roboto', sans-serif;
}

/* Titles for Syllabus Container */
.exam-details-template-syllabus-container h3 {
    font-size: 24px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

/* Eligibility Container */
.exam-details-template-eligibility-container {
    text-align: justify;
    font-size: 17px;
    line-height: 30px;
    padding: 10px 30px; 
    font-family: 'Roboto', sans-serif;
}

/* Titles for Eligibility Container */
.exam-details-template-eligibility-container h3 {
    font-size: 24px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

/* List Styles */
.exam-details-template-container ul {
    padding-left: 20px;
    list-style-type: none; 
}

.exam-details-template-container li {
    margin-bottom: 8px;
}

/* Fee Structure Container */
.exam-details-template-fee-structure-container {
    text-align: justify;
    font-size: 17px;
    line-height: 30px;
    padding: 10px 30px;
    font-family: 'Roboto', sans-serif;
}

/* Titles for Fee Structure Container */
.exam-details-template-fee-structure-container h3 {
    font-size: 24px;
    line-height: 30px;
    font-family: 'Roboto', sans-serif;
    margin: 0;
}

/* Fee Structure Table */
.fee-structure-table {
    max-width: 1140px;
    width: 50%;
    border-collapse: collapse;
    font-size: 17px;
    text-align: center;
    margin: 10px auto; 
}

.fee-structure-table th, .fee-structure-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.fee-structure-table th {
    background-color: #f2f2f2;
    font-weight: bold;
}

.fee-structure-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.fee-structure-table tr:hover {
    background-color: #e9e9e9;
}

.icon-position {
    vertical-align: middle;
    margin-right: 5px !important;
    font-size: 1.2em !important;      
    color: #007BFF; 
}


/* Media Queries for Responsive Design */

/* For devices with a max-width of 768px (Tablets and smaller) */
@media only screen and (max-width: 768px) {
    .exam-details-template-outer-container {
        padding: 15px 0;
    }

    .exam-details-template-main-container {
        padding: 0 15px;
    }

    .exam-details-template-description-container,
    .exam-details-template-syllabus-container,
    .exam-details-template-eligibility-container,
    .exam-details-template-fee-structure-container {
        padding: 10px 20px;
        font-size: 15px;
        line-height: 28px;
    }

    .exam-details-template-description-container h3,
    .exam-details-template-syllabus-container h3,
    .exam-details-template-eligibility-container h3,
    .exam-details-template-fee-structure-container h3 {
        font-size: 22px;
    }

    .fee-structure-table {
        width: 100%;
        font-size: 15px;
    }

    .icon-position {
        font-size: 1em;
    }
}

/* For devices with a max-width of 576px (Mobile phones) */
@media only screen and (max-width: 576px) {
    .exam-details-template-outer-container {
        padding: 10px 0;
        flex-direction: column;
    }

    .exam-details-template-main-container {
        padding: 0 10px;
    }

    .exam-details-template-description-container,
    .exam-details-template-syllabus-container,
    .exam-details-template-eligibility-container,
    .exam-details-template-fee-structure-container {
        padding: 10px;
        font-size: 14px;
        line-height: 25px;
    }

    .exam-details-template-description-container h3,
    .exam-details-template-syllabus-container h3,
    .exam-details-template-eligibility-container h3,
    .exam-details-template-fee-structure-container h3 {
        font-size: 20px;
    }

    .fee-structure-table {
        font-size: 14px;
    }

    .fee-structure-table th, .fee-structure-table td {
        padding: 6px;
    }

    .icon-position {
        font-size: 0.9em;
    }
}

/* For devices with a max-width of 400px (Small mobile phones) */
@media only screen and (max-width: 400px) {
    .exam-details-template-description-container,
    .exam-details-template-syllabus-container,
    .exam-details-template-eligibility-container,
    .exam-details-template-fee-structure-container {
        padding: 5px;
        font-size: 13px;
        line-height: 22px;
    }

    .exam-details-template-description-container h3,
    .exam-details-template-syllabus-container h3,
    .exam-details-template-eligibility-container h3,
    .exam-details-template-fee-structure-container h3 {
        font-size: 18px;
    }

    .fee-structure-table {
        font-size: 13px;
    }

    .icon-position {
        font-size: 0.8em;
    }
}
