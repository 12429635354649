.coming-soon-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100vh;
    background-color: #f0f0f0;
    padding: 0 20px;
  }
  
  .coming-soon-title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .coming-soon-subtitle {
    font-size: 1.5rem;
    color: #666;
    margin-bottom: 40px;
  }
  
  .coming-soon-timer {
    font-size: 2rem;
    color: #333;
  }
  
  .coming-soon-timer span {
    margin: 0 10px;
  }
  