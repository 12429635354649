.home-card-render-section-background-color {
  background-color: #ffffff;
}

.home-card-render-section-width-control-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
  
}

.caraousel-div-course-render-page{
  padding: 10px;
}

.Header-and-tagline-conatiner {
  text-align: left;
  color: black;
  font-family: "Roboto", sans-serif; 
  
}

.course-card-render-header {
  font-size: 24px;
  font-weight: 600;
  padding-bottom: 10px;
  font-family: "Roboto", sans-serif; 
  
}

.course-card-render-tagline {
  font-size: 17px;
  font-family: "Roboto", sans-serif; 
 
}

@media screen and (max-width: 805px) {
  .course-card-render-tagline {
    margin-top: 10px;
  }
}
  

/*_______________ Container for Categories and Subcategories_________________ */
.tab-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  margin-bottom: 10px;
  
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  margin-left: 1px;
}

.tab-container::-webkit-scrollbar {
  display: none;
}

/* Tab Styles */
.tab-item {
  padding: 10px 10px;  
  border-bottom: 1px solid #ccc;
  /* border-radius: 5px; */
  cursor: pointer;
  /* background-color: #f0f0f0; */
  font-size: 18px;
  
}

.tab-item.active { 
  border-bottom: 2px solid #007bff;
  color: black;
  font-weight: 600;
  font-family: "Roboto", sans-serif; 
}

.tab-item:hover { 
    /* border-bottom: 2px solid #007bff; */
    color: #007bff;
    
  }

/* _______________Container for Subcategories________________ */
.subcategory-container {
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  padding-top: 10px;
  margin-bottom: 10px;
  scrollbar-width: none;
}

.subcategory-item {
  padding: 10px 20px;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
}

.subcategory-item.active {
  background-color: #007bff;
  color: white;
}

/*____________________Course Cards Container_____________________ */
.course-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.react-multi-carousel-list{
  padding-bottom: 30px !important;
}


/* ________Hide the arrows by default______________ */
.react-multiple-carousel__arrow {
  opacity: 0 !important;
  display: none !important;
  transition: opacity 0.3s ease-in-out !important; /* Smooth transition for hover effect */
}

/* Show the arrows on hover */
.home-card-render-section-background-color:hover .react-multiple-carousel__arrow {
  
  display: block !important;
  opacity: 1 !important;
}

/* ___________________button______________________ */
.home-course-section-more-review-button{
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */  
  padding: 16px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #007bff;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.home-course-section-more-review-button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.course-header-and-button{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 430px) {
  .course-header-and-button {
    display: block;
  }
}
/* _______________card click_______ */

.home-course-select-card-click-render{
  cursor: pointer;
}