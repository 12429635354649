body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background-color: #f5f5f5;
  /* text-align: justify; */
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.job-portal-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
  display: flex;
  gap: 20px;
  flex-wrap: nowrap;
  height: calc(100vh - 0px);
}

.job-list-container {
  max-height: 100%; /* Adjust as needed */
  overflow-y: scroll;
  margin-right: 20px;
  /* width: 100%; */
  transition: all 0.3s ease;
  flex: 1;
}

.job-list-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.job-details-container::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

.job-list-container.job-list-shrink {
  flex: 0 0 50%;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader {
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #3498db;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Define the zoom-in animation */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}


/* Apply the animation to the job-details-container when it becomes visible */
.job-details-container {
  animation: zoomIn 0.5s ease-in-out forwards;
}

.job-details-container.slide {
  animation: slideIn 0.5s ease-in-out forwards;
}

.job-details-container {
  flex: 0 0 43%;
  padding: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 25px;
  margin-top: 15px;
  margin-bottom: 25px;
  overflow-y: scroll;
}

@media (max-width: 850px) {
  .job-portal-container {
    flex-direction: column;
  }

  .job-list-container,
  .job-details-container {
    flex: 1 1 auto;
  }
}

.job-main-card {
  border: 1px solid #e0e0e0;
  border-radius: 25px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 88%;
  max-width: 800px;
  background-color: #fff;
  margin-bottom: 20px;
  transition: box-shadow 0.3s ease;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
}

.job-main-card:hover,
.job-main-card.selected {
  box-shadow: 0 4px 10px rgba(14, 0, 23, 0.15);
  transform: scale(1.02); /* Adjust the scale value as needed */
  outline: 3px solid #078efb;
}

@media screen and (max-width: 500px) {
  .job-main-card:hover,
  .job-main-card.selected {
    box-shadow: none;
    transform: none;
    outline: none;
  }
}

.job-card-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  line-height: 25px;
  cursor: pointer;
  flex-wrap: wrap;
}

.launch-icon {
  color: #074ee7e2;;
  margin-right: 10px;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: auto;
  margin-top: 2px;
  margin-right: 0 !important;
}

.job-Link-icon{
  font-size: 20px !important
}

.launch-icon:hover {
  color: #0056b3;
}

.close-icon {
  cursor: pointer;
  color: red;
  font-size: 25px !important;
}

.job-details-page {
  max-width: 1140px;
  margin: auto;
  width: 50%;
  padding: 20px;
  height: auto;
  margin-top: 15px;
  margin-bottom: 30px;
}

.job-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 850px) {
  .job-details-page {
    width: 95%;
    padding: 15px;
  }
}

@media (max-width: 600px) {
  .job-details-page {
    width: 90%;
    padding: 10px;
  }
}

.job-title {
  font-size: 1.2em;
  margin: 0;
  color: black !important;
}

.company-name {
  font-size: 1em;
  color: black !important;
}

.job-card-body {
  margin-top: 25px;
}

.job-info-line {
  display: flex;
  align-items: center;
  gap: 10px; /* Adjust space between info items if needed */
  margin-bottom: 15px;
}

.job-info-item {
  display: flex;
  align-items: center;
  gap: 2px;
}

.job-icon {
  font-size: 1em;
  color: #555;
}

.rupee-icon {
  font-size: 1.2rem !important;
  color: #555;
}

.job-location,
.job-experience,
.job-salary,
.job-skills {
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.721) !important;
}

.job-posted-date {
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9em;
  color: #555;
}

.job-opening {
  display: flex;
  align-items: center;
}

.job-opening-post {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #555;
}

.job-additional-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.job-description {
  width: 100%;
  line-height: 25px;
  margin: auto 10px;
  padding: 5px;
  color: black;
}

.job-detail-item,
.about-company {
  width: 80%;
  max-width: 800px;
  line-height: 25px;
  color: black;
  /* margin-right: auto; */
}

.about-company {
  width: 100%;
  margin-top: 20px;
}

.about-company h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.about-company p {
  font-size: 16px;
  color: black;
  line-height: 1.5;
  margin: auto 5px;
  padding: 5px;
}

.job-detail-item {
  width: 100%;
  margin-top: 20px;
}

.job-detail-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: black;
}

.job-detail-item p {
  font-size: 16px;
  color: black;
  line-height: 1.5;
  margin: auto 5px;
  padding: 5px;
}

.apply-job-button {
  align-self: flex-end;
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  margin-left: auto;
}

.apply-job-button:hover {
  background-color: green;
}

.apply-job-button.applied {
  background-color: #28a745; /* Green color when applied */
  cursor: not-allowed;
}

.job-divider {
  width: 2px;
  height: 20px;
  background-color: rgba(73, 73, 73, 0.331); /* Divider color */
  margin: 0 5px;
}

.job-detail-item {
  margin-bottom: 10px;
}

.job-label {
  font-weight: bold;
}

.header-main {
  width: 100%;
  padding: 20px 0;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.header-search {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1090px;
  border: 1px solid #ddd;
  padding: 15px;
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(197, 195, 195, 0.1);
  background-color: #fff;
  transition: border-color 0.3s ease;
  margin: auto;
  cursor: pointer;
}

.header-search.expanded {
  border: 2px solid #276bd8;
  cursor: auto;
}

.search-bar {
  display: flex;
  align-items: center;
  width: 100%;
}

.search-section {
  display: flex;
  align-items: center;
  position: relative;
  flex: 1;
  padding: 0 10px;
}

.search-section.full-width {
  flex: 1 1 30%;
}

.no-border {
  width: 100%;
  padding: 8px;
  border: none;
  outline: none;
  font-size: 16px;
  background-color: transparent;
}

.vertical-line {
  width: 1px;
  height: 30px;
  background-color: #ddd;
  margin: 0 10px;
}

.job-search-icon {
  margin-right: 10px;
}

.cancel-icon {
  position: absolute;
  right: 10px;
  cursor: pointer;
}

.suggestions-list {
  position: absolute;
  top: 3em;
  left: 0;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1000;
  list-style-type: none;
  margin: 0;
  padding: 0;
  max-height: 150px;
  overflow-y: auto;
  border-radius: 10px;
}

.suggestions-list li {
  padding: 10px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.search-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
}

.search-button:hover {
  background-color: #0056b3;
}

.header-search input,
.header-search select {
  padding: 10px 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}

.job-main-card-container {
  margin-bottom: 20px;
  margin-top: -30px;
  padding: 5px;
  max-width: 100%;
}

.job-detail-card-container {
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 25px;
  margin-bottom: 20px;
  padding: 20px;
  max-width: 100%;
  margin: auto;
}

.back-arrow-container {
  position: absolute;
  top: 6em;
  left: 14em;
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 1000;
}

.back-arrow-icon {
  font-size: 24px;
  margin-right: 8px;
}

.back-arrow-container span {
  font-size: 16px;
}

@media (max-width: 850px) {
  .back-arrow-container {
    top: 5.5em;
    left: 0em;
  }

  .job-info-line {
    gap: 3px;
  }
}
