.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* #root {
  
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
} */

/* To add the Hovering effect to the any buttons(zoom) */
#button-zoom {
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out;
}

#button-zoom:hover {
  transform: scale(1.1);
  filter: brightness(1.2);
}

/* Common icons size for button */
.buttoniconcommon{
  font-size: 16px !important;
  margin-right: 3px;
  margin-top: -2px;
}


/* Hide the arrows by default */
.react-multiple-carousel__arrow {
  opacity: 0 !important;
  display: none !important;
  transition: opacity 0.3s ease-in-out !important; /* Smooth transition for hover effect */
}

/* Show the arrows on hover */
.home-card-render-section-background-color:hover .react-multiple-carousel__arrow {
  
  display: block !important;
  opacity: 1 !important;
}

/* * { 
    box-sizing: border-box; 
    margin: 0; 
    padding: 0; 
}  */


/* .dropdown-toggle::after {
  display: none !important;
} */