/* HeroSection.css */
.card-main-container{
    display: flex;
    width: 100%;
    background-color: #fafbff;
    align-items: center;
    justify-content: center;
    margin-top: 62px;
}

.blog-hero-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 45vh;
    padding: 0 20px;
    background-color: white;
  }
  
  .blog-hero-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1200px;
    width: 1140px;
  }
  
  .blog-hero-text {
    flex: 1;
    padding: 20px;
  }
  
  .blog-hero-title {
    font-size: 3rem;
    margin: 0;
    color: #333;
    font-family: "Roboto", sans-serif;
  }
  
  .blog-hero-subtitle {
    font-size: 17px;
    margin-top: 10px;
    color: #333;
    font-family: "Roboto", sans-serif;
    text-align: justify;
  }
  
  .blog-hero-button {
    padding: 0.8rem 1.5rem;
    font-size: 1rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px !important;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
    cursor: pointer;
  }
  
  .blog-hero-button:hover {
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  
  .blog-hero-image {
    flex: 1;
  }
  
  .blog-hero-image img {
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
  }

/* Flexbox styling for h2 and Autocomplete in the same row */
.modal-header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 15px 15px 20px;
}

/* Optional: Control width of the autocomplete to avoid it getting too wide */
.category-autocomplete {
  width: 250px; /* You can adjust the width as per your requirement */
}

.modal-title {
  margin: 0;
}

/* Modal box styling remains the same */
.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70%;
  min-height: 80%;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 20px;
  outline: none;
}
  
  /* Modal actions - buttons container */
  .modal-actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
  
  .modal-actions button {
    /* margin-left: 10px; */
    background-color: #2e7d32;
    color: white;
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  }

  .modal-actions button:hover{
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }

  .enquiry-form-addeducationalMapmodalcancel {
    position: absolute !important;
    top: -16px;
    right: -15px;
  }

  .enquiry-form-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;  
  font-size: 16px !important;
}

  
  /* Responsive adjustments */
  @media (max-width: 1140px){
    .blog-hero-title{
        font-size: 2rem;
    }
  }

  @media (max-width: 1024px){
    .modal-box{
      min-height: 70%;
    }
  }
  @media (max-width: 768px) {
    .blog-hero-content {
      flex-direction: column;
      text-align: center;
      padding: 30px !important;
    }
  
    .blog-hero-text {
      padding-top: 80px;
    }
  
    .blog-hero-title {
      font-size: 2rem;
      text-align: justify;
    }
  
    .blog-hero-subtitle {
      font-size: 17px;
    }
  
    .blog-hero-button {
      font-size: 1rem;
      padding: 10px 20px;
    }
  
    .blog-hero-image img {
      max-width: 80%;
    }
    .modal-box {
      width: 85%;
    }
  }
  
  @media (max-width: 480px) {
    .blog-hero-content{
        padding-top: 0;
    }
    .blog-hero-text{
        padding-top: 0;
    }
    .blog-hero-title {
      font-size: 2rem;
    }
  
    .blog-hero-subtitle {
      font-size: 1rem;
    }
  
    .blog-hero-button {
      font-size: 0.9rem;
      padding: 8px 16px;
    }
  }
  