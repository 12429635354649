/* _______________________Review header section css______________________ */
.review-section-header-backgroundcolor {
  background-color: #fafbff;
}

.review-section-width-container {
  max-width: 1140px;
  margin: auto;
}

.review-section-flex-container {
  display: flex;
  justify-content: space-between;
}

.review-section-content-content-section {
  padding: 15px;
}

.review-section-content-content-section .section-title {
  text-align: left;
}

.review-content-paragraph-section{
  font-size: 15px;
  line-height: 24px;
  font-family: 'Roboto' ,sans-serif;
  /* border: 1px solid red; */
  /* max-width: 80%; */
}
/* @media screen and (max-width:1106px) {
  

.review-section-content-content-section .section-title{
  font-size: 30px;
}
} */

.review-section-image-container {
  max-width: 450px;
  width: 40%;
  max-height: 450px;
  height: 40%;
}

.review-section-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  align-items: center;
}

@media screen and (max-width: 680px) {
  .review-section-flex-container {
    display: block;
    margin: auto;
  }

  .review-section-image-container {
    margin: auto;    
    width: 90%;    
    height: 91%;
  }

  .review-section-content-content-section {
    padding-top: 5px;
    padding-bottom: 8px;
  }
}

/* ___________________ ReviewLanding page______________*/
.review-rating-section-container-background {
  background-color: #ffffff;
  padding: 30px;
}

.Comapanyslides-section-container-background {
  background-color: white;
  padding: 30px;
}

/* _____________________Review-card2__________________ */

/* ___________________Review card__________________ */

.review-card-conatiner2 {
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow: 0px 0px 10px rgba(30, 10, 58, 0.09);
  height: 380px;
  margin: 10px;
  width: calc(100% - 20px);
  border-radius: 5px;
  background-color: #ffffff;
}

.review-avatat-name-designation-container2 {
  display: flex;
  padding: 15px;
}

.review-name-designation2 {
  margin: 8px;
  margin-left: 12px;
}

.rating-avatar2 {
  width: 56px !important;
  height: 56px !important;
  background-color: rgba(0, 128, 0, 0.813) !important;
}
.review-name2 {
  font-size: 20px;
  font-weight: 400;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.review-designation2 {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.review-description2 {
  font-weight: 400;
  color: rgb(41, 40, 40);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 18px;
  font-size: 16px;
  max-height: 200px;
  overflow: auto;
  scrollbar-width: 5px;
  line-height: 25px;
  margin-top: -5px;
}

/* _____________________Review More Card Section_____________ */

.reviewmorecard-section-background-color {
  background-color: #fafbff;
  margin-top: -10px;
  margin-bottom: -10px;
}

.review-more-card-width-container {
  max-width: 1140px;
  margin: auto;
}

.review-more-card-container {
  padding: 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.review-more-card-heading {
  font-size: 24px;
  font-weight: bold;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 10px;
  padding-bottom: 20px;
}

.review-more-card-section-render {
  padding: 10px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px; /* Optional: Add space between the cards */
}

@media (max-width: 900px) {
  .review-more-card-section-render {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (max-width: 620px) {
  .review-more-card-section-render {
    grid-template-columns: 1fr;
  }
  .review-card-conatiner2 {
    width: 350px;
    margin: auto;
  }
}

.review-more-section-more-review-button {
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 16px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #007bff;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.review-more-section-more-review-button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.review-more-load-more-card-section {
  display: flex;
  align-items: center;
  margin: auto;
  justify-content: center;
  max-width: 1140px;
}
