.resume-container {
  display: flex;
  max-width: 800px;
  margin: 40px auto;
  background-color: #fff;
  font-family: "Roboto", sans-serif;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.6);
  width: 210mm;
  height: 297mm;
}

.hidden{
  display: none;
}

.sidebar {
  width: 30%;
  padding: 20px;
  background-color: #1A3853;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.sidebar-section {
  margin-bottom: 20px;
  line-height: 1.6;
}

.sidebar-section p {
  display: flex;
  align-items: center;
  margin: 5px 0;
  line-height: 2;
}

.sidebar-section p,
.sidebar-section li {
  margin: 5px 0;
}

.sidebar-section p .MuiSvgIcon-root {
  margin-right: 10px;
  color: white;
}

.sidebar-section h3 {
  margin: 0;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid #ddd;
}

.photo-section {
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 15px;
  display: inline-block;
}

.profile-photo {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  border: 5px solid rgba(255, 255, 255, 0.842);
  margin: auto;
  align-content: center;
}

.edit-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: blue;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  display: none;
}

.photo-section:hover .edit-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.edit-icon input {
  display: none;
}


.main-content {
  width: 70%;
  padding: 35px;
  color: black;
}

.resume-header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-name {
  font-size: 1.2rem;
  text-align: left;
  margin-bottom: 5px;
}

.header-title {
  font-size: 1rem;
  text-align: left;
  margin-top: -1em;
  margin-bottom: 50px;
  color: #333;
}

.header-logo {
  margin-left: 20px;
}

.company-logo {
  /* max-width: 100px;
  height: auto; */
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  border: 5px solid rgba(255, 255, 255, 0.842);
  margin: auto;
  align-content: center;
}

.section {
  margin-bottom: 30px;
}

.section h3 {
  font-size: 1.2rem;
  font-weight: 600;
  border-bottom: 2px solid #333;
  padding-bottom: 10px;
  margin-bottom: 20px;
}

.job h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin-bottom: 5px;
}

.job p {
  margin-bottom: 5px;
}

.education-item {
  margin-bottom: 25px;
}

.education-item p {
  margin: 0;
}

.education-item,
.skills-title,
.languages-title {
  margin-bottom: -2px;
}

/*______________Work Experience Section____________*/
.work-experience {
  margin-bottom: 20px;
}

.work-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.work-header h4 {
  font-size: 1.1rem;
  font-weight: 600;
  margin: 0;
}

.work-header .duration {
  margin-left: 20px;
  margin-top: 10px;
  font-size: 15px;
}

.work-experience h5 {
  margin: 0;
  font-size: 1rem;
  font-weight: 500;
  color: #333;
}

.work-experience ul {
  list-style-type: disc;
  margin: 20px 0 0;
  padding-left: 20px;
}

.work-experience li {
  margin: 5px 0;
}

.profile-title,
.work-experience-title,
.references-title{
  color: #1A3853;
}

.editable {
  position: relative;
}

.editable:hover {
  outline: 3px solid black;
}

.editable.is-editing {
  outline: 2px dashed black;
}

.addItem,
.deleteItem {
  visibility: hidden;
  cursor: pointer;
}

/* .addItem{
  color: green;
}

.deleteItem{
  color: red;
} */

.education-item:hover .addItem,
.education-item:hover .deleteItem {
  visibility: visible;
}

.reference {
  padding: 5px;
  margin-bottom: 10px;
  border-radius: 5px;
  margin-top: -1em;
}

.references-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

