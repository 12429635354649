.test-cards-outer-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  
    /* background-color: #f8f9fa; */
}

/* Container for the test cards */
.test-cards-container {
    /* display: flex; */
    flex-direction: column;
    align-items: left;
    padding: 20px;
    width: 100%;
    max-width: 1140px;
}

.test-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 15px 0;
    padding: 20px;
    transition: transform 0.2s;
    width: 800px;
    
  }
  
  .test-card:hover {
    transform: scale(1.02);
  }
  
  /* Header (Label) */
  .test-card-header {
    display: flex;
    justify-content: flex-start;
  }
  
  .test-card-label {
    background-color: #28a745;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
  }
  
  /* Content Section */
  .test-card-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
  }
  
  /* Left Side Info */
  .test-card-info-left {
    display: flex;
    flex-direction: column;
  }
  
  .test-card-title {
    font-size: 18px;
    margin: 10px 0;
    font-family: "Roboto",sans-serif;
  }
  
  .test-card-info {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 12px;
    color: #666;
    font-family: "Roboto",sans-serif;
  }
  
  .test-card-info span {
    display: flex;
    align-items: center;
  }
  
  .icon {
    margin-right: 5px;
    font-size: 14px;
  }
  
  /* Right Side Button */
  .test-card-info-right {
    display: flex;
    align-items: center;
    /* margin-right: -37px; */
  }
  
  .start-test-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    
  }
  
  .css-i4bv87-MuiSvgIcon-root {
   
    font-size: 0.9rem;
}
/* .css-i4bv87-MuiSvgIcon-root {
  font-size: 0.8rem;

} */
  
  .start-test-btn:hover {
    background-color: #138496;
  }
  .button-icon {
    margin-right: 8px; /* Add some space between the icon and text */
  }
  
  .icon {
    margin-right: 5px; /* For spacing between text and icons */
  }
  /* Footer Section for Languages */
  .test-card-languages {
    margin-top: 15px;
    font-size: 13px;
    color: #555;
  }
  
  .test-card-languages span {
    font-weight: bold;
    color: #007bff;
  }
  

/* View More Button Styles */
.view-more-btn {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Roboto",sans-serif;
    transition: background-color 0.2s;
}

.view-more-btn:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4) !important;
}

/* Responsive Styles */
@media (max-width: 988px) {
    .test-card {
        width: 800px;
        padding: 15px;
    }
}

@media (max-width: 850px) {
    .test-card{
        width: 650px;
    }

    .test-card-label{
        font-size: 12px;
    }

    .test-card-title {
        font-size: 15px;
      }
      
      .test-card-info {
        font-size: 12px;
      }
      
      .icon {
        font-size: 12px;
      }
}

@media (max-width: 700px){
    .test-card{
        width: 550px;
    }
}

@media (max-width: 600px) {
    .test-card {
        width: 500px;
        padding: 10px;
    }

    .test-card-title{
        font-size: 14px;
    }

    .start-test-btn{
        font-size: 13px;
    }

    .view-more-btn {
        font-size: 14px;
    }
}

@media (max-width: 550px){
    .test-card {
        width: 400px;
    }

    .test-card-title{
        font-size: 13px;
    }

    .start-test-btn{
        font-size: 11px;
    }
}

@media (max-width: 450px){
    .test-card {
        width: 350px;
    }

    .test-card-title{
        font-size: 12px;
    }
}

@media (max-width: 380px){
    .test-card {
        width: 310px;
    }
}
