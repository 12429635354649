/* Container 1: Full-width container */
.Explore-container-outer {
  width: 100%;
  background-color: #ffffff;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}

/* Container 2: Centered and with max-width */
.Explore-container-inner {
  max-width: 1140px;
  width: 100%;
  padding: 0 10px;
}

/* Search card that appears like the other exam cards */
.search-card {
  grid-column: span 2; /* Make the search card span across two columns */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Search bar wrapper to position icon inside input */
.search-wrapper {
  position: relative;
  width: 95%;
  margin-right: 30px;
}

.search-wrapper input {
  width: 100%;
  padding: 12px 12px 12px 12px; 
  font-size: 16px;
  border-radius: 8px;
  border: 1px solid #ccc;
  outline: none;
}

/* Positioning the search icon inside the input field */
.search-icon {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
  font-size: 18px;
  color: #aaa;
}

/* Styling for the heading below the search bar */
.explore_heading {
  text-align: left;
  margin: 20px 0;
  font-size: 24px;
  font-weight: bold;
  color: #333;
}

/* Container 3: Grid layout for the exam cards */
.explore-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns by default */
  gap: 20px;
  max-height: 280px; /* Adjust to fit around 8 cards (height depends on card size) */
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  padding: 10px;
  max-height: 200px;
  overflow-y: hidden; /* Initially no scroll */
}
/* When "View All" is clicked, display all cards without scrolling */
.explore-grid.show-all {
    max-height: none; /* Allow full height */
    overflow-y: visible; /* Disable scrolling */
  }

.avatar-chip {
    width: 60px;
    height: 60px;
    background-color: #007bff; /* Background color of the avatar */
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%; /* Make it circular */
    font-size: 24px; /* Adjust the font size for the initial */
    font-weight: bold;
    margin-right: 10px; /* Space between chip and text */
  }

  
/* Card styling */
.explore-cards {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row; /* Align logo and text side by side */
  padding: 8px;
  transition: transform 0.2s ease-in-out;
  width: 90%; /* Decreased the card width */
  align-items: center; /* Center items vertically */
  margin: 0 auto; /* Center the card horizontally */
  min-height: 70px; /* Set a minimum height for the cards */
}

.explore-cards p{
  font-size: 16px;
  font-family: "Roboto",sans-serif;
  overflow: hidden; /* Prevent overflow */
  text-overflow: ellipsis; /* Truncate long text */
}

.explore-cards:hover {
  transform: scale(1.05);
}

/* Exam logos */
.explore-logo {
  width: 70px;
  height: 70px;
  margin-right: 10px; /* Space between logo and text */
}
/* "View All" button styling */
.view-all-btn {
    display: block;
    margin: 5px auto;
    padding: 10px 20px;
    font-size: 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 20px;
  }
  
  .view-all-btn:hover {
    background-color: #0056b3;
  }

/* Default behavior for larger screens */
/* For screens below 1024px (responsive behavior) */

  
  /* For screens below 1024px (responsive behavior) */
  @media (max-width: 1023px) {
    .explore-grid {
      max-height: 280px; /* Limit height initially */
      overflow-y: auto; /* Enable scrolling within the limited height */
      display: grid;
      grid-template-columns: repeat(2, 1fr); /* Two columns for responsive view */
      gap: 10px;
      padding: 10px;
      transition: max-height 0.3s ease; /* Smooth transition */
      overflow-y: hidden; /* Initially no scroll */
    }
  
    /* When "View All" is clicked, show all cards but keep scrolling */
    .explore-grid.show-all {
      max-height: 300px; /* Set a larger max height to show more items */
      overflow-y: auto; /* Keep scrolling enabled */
    }
  
    .explore-cards {
      flex-direction: row; /* Logo and title side by side */
    }
  
    .explore-logo {
      width: 60px;
      height: 60px;
    }
  }
  
  /* For very small screens (below 768px) */
  @media (max-width: 768px) {
    .explore-grid {
      grid-template-columns: 1fr; /* Single column for small screens */
      max-height: 270px; /* Limit height even more */
      overflow-y: hidden; /* Initially no scroll */
    }
  
    .explore-grid.show-all {
      max-height: 300px; /* Increase the height when "View All" is clicked */
    }
  }
  
  
/* ________________________________________________________________________________________ */ 
  /*___________________________________________________________________________________________  */

/* Outer container: Full-width */
.exploreAll-container-outer {
    width: 100%;
    background-color: #f5f7fa;
    padding: 20px 0;
    display: flex;
    justify-content: center;
}

/* Inner container: Max-width */
.exploreAll-container-inner {
    max-width: 1140px;
    width: 100%;
    padding: 0 10px;
}


  

/* Grid layout for the left and right sections */
.exploreAll-grid {
    display: grid;
    grid-template-columns: 300px 1fr; /* Increased left section width */
    gap: 5px;
}

/* Left section (Static exam list) */
.exploreAll-left-section {
    border-radius: 0px;
    padding: 20px;
    max-height: 300px; /* Set a fixed height */
    overflow-y: auto; /* Allow vertical scrolling */
    scrollbar-width: thin; /* For Firefox */
    scrollbar-color: #888 transparent; /* Custom color for Firefox */
}

/* For Webkit browsers (Chrome, Safari) */
.exploreAll-left-section::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
    height: 0;
}

.exploreAll-left-section::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar */
    border-radius: 4px; /* Round scrollbar */
}

.exploreAll-left-section::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
}

/* Right section (Related exams grid) */
.exploreAll-right-section {
    background-color: #f0f0f0;
    padding: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-height: 300px; /* Set a fixed height */
    overflow-y: auto; /* Allow vertical scrolling */
    scrollbar-width: thin; /* For Firefox */
}


/* .exploreAll-right-section ::after {
    border-color: #86a1ae;
    border-width: 2px;
    width: 8px;
    height: 8px;
    content: "";
    position: absolute;
    display: inline-block;
    top: 50%;
    border-left-style: solid;
    border-bottom-style: solid;
    transition: all .2s ease-in-out;
    transform: rotate(-135deg) translateY(-50%);
    right: 0;
    right: 1rem;
    margin-top: -.5rem;
} */



/* For Webkit browsers (Chrome, Safari) */
.exploreAll-right-section::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
    height: 0 !important; /* Remove the height for horizontal scrollbar */
}

.exploreAll-right-section::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar */
    border-radius: 4px; /* Round scrollbar */
}

.exploreAll-right-section::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
}

/* Related exam cards: Grid layout with 3 columns */
.exploreAll-related-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 27px;
}

/* Card styling */
.exploreAll-card {
    background-color: #ffffff;
    padding: 10px; /* Add padding for a consistent look */
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
    overflow: hidden; /* Ensure no overflow */
    display: flex; /* Use flexbox for layout */
    flex-direction: column; /* Stack items vertically */
}

.exploreAll-card:hover {
    transform: scale(1.05);
}

.exploreAll-card-icon {
    font-size: 24px;
    margin-bottom: 10px;
}

.exploreAll-card-info h3 {
    font-size: 16px;
    /* text-align: left; */
    margin: 0; /* Remove margin */
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis when text is too long */
    max-width: calc(100% - 20px); /* Allow some space for padding */
    box-sizing: border-box; /* Include padding in the width calculation */
    /* margin-right: -30px; */
}
/* New card container style */
.exploreAll-card-container {
    background-color: white; /* Background color for the card */
    border-radius: 0px; /* Rounded corners */
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);  */
    padding: 20px 10px 20px 10px; /* Padding inside the card */
}

.exploreAll-card-info p {
    font-size: 14px;
    color: #666;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis when text is too long */
    max-width: calc(100% - 20px); /* Allow some space for padding */
    box-sizing: border-box; /* Include padding in the width calculation */

}

/* Left section: Exam list styling */
.exploreAll-exam-list {
    display: flex;
    flex-direction: column;
}

.exploreAll-exam-item {
    display: flex;
    background-color: #ffffff; /* Card background */
    align-items: center;
    padding: 10px; /* Adjust padding */
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    overflow: hidden; /* Prevent overflow */
    width: 100%; /* Ensure the item takes full width */
    box-sizing: border-box; /* Include padding in the width calculation */
}



.exploreAll-exam-item:hover,
.exploreAll-exam-item.active {
    background-color: #f0f0f0;
}

.exploreAll-icon {
    margin-right: 10px;
}

.exploreAll-exam-name {
    font-size: 16px;
    white-space: nowrap; /* Prevent text from wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis when text is too long */
    max-width: calc(100% - 50px); /* Allow some space for padding and icon */
    box-sizing: border-box; /* Include padding in the width calculation */
}






/* Mobile view */

@media (max-width: 1030px) {
    .exploreAll-card-info h3,
    .exploreAll-card-info p {
        margin-right: -30px;

    }
}



@media (max-width: 1023px) {
    .exploreAll-card-content {
        flex-direction: row;
        align-items: center;
    }
  .exploreAll-mobile-view {
      padding: 10px; /* Set padding */
      text-align: left; /* Align text to the left */
      overflow: hidden; /* Prevent overflow */
  }
  .exploreAll-card-logo {
    max-width: 40px; /* Slightly smaller logo in mobile view */
    height: auto;
}

.exploreAll-avatar {
    width: 40px; /* Smaller avatar in mobile view */
    height: 40px;
    font-size: 14px; /* Smaller text in mobile avatar */
}

.exploreAll-card-content {
    flex-direction: row;
    align-items: center;
}

  .exploreAll-related-mobile {
      max-height: 300px; /* Set a fixed height for scrolling */
      overflow-y: auto; /* Enable vertical scrolling */
      padding: 20px; /* Add padding for better appearance */
  }

  .exploreAll-card {
      background-color: #ffffff; /* Card background */
      text-align: left; /* Align text to the left */
      cursor: pointer; /* Pointer cursor */
      padding: 10px; /* Padding inside the card */
      margin-bottom: 15px; /* Space below each card */
     
      box-sizing: border-box; /* Ensure padding and border are included in width/height */
  }
  .exploreAll-exam-list {
   
      text-align: left; /* Align text to the left */
      cursor: pointer; /* Pointer cursor */
      padding: 10px; /* Padding inside the card */
      margin-bottom: 15px; /* Space below each card */
     
      box-sizing: border-box; /* Ensure padding and border are included in width/height */

  }

  /* Outline effect on hover */
  /* .exploreAll-card:hover {
      outline: 2px solid #078efb; 
  } */

  .exploreAll-card-info h3 {
      font-size: 16px; /* Title font size */
      margin: 0; /* Remove margin */
     
      margin-bottom: 5px; /* Space below title */
  }

  .exploreAll-card-info p {
      font-size: 14px; /* Subtitle font size */
      color: #666; /* Subtitle color */
      margin: 0; /* Remove margin */
      
  }

  .exploreAll-back-btn {
      margin-bottom: 10px; /* Space below back button */
      background-color: #007bff; /* Button background color */
      border-radius: 4px;
      color: white; /* Button text color */
      border: none; /* Remove border */
      padding: 8px 12px; /* Button padding */
      cursor: pointer; /* Pointer cursor */
  }

  .exploreAll-back-btn:hover {
      background-color: #0056b3; /* Darker blue on hover */
  }
  .exploreAll-exam-item {
    background-color: white /* Card background */
  }
}