/* ____________________Landing page testimol__________ */
.testimol-landing-page-width-container {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
}

.testimol-landing-page-background-color {
  background-color: #fafbff;
}

.testimol-section-heading {
  text-align: left;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-weight: 600;

}

.testimol-section-more-button-container {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  padding-top: 10px;
}

.Testimol-section-more-review-button {
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */
  padding: 16px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #2196f3;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.Testimol-section-more-review-button:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}
/* __________________Rating section_______________ */

.rating-section-width-container {
  max-width: 1140px;
  margin: auto;
}

.rating-section-display-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  max-width: 1140px;
  margin: auto;
}

.rating-google-image-conatiner {
  max-width: 150px;
  max-height: 150px;
  padding: 10px;
  display: flex;
}

.rating-google-image-conatiner img {
  aspect-ratio: auto 120 / 39;
  width: 120px;
}

.rating-google-container {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  text-align: center;
  gap: 10px;
}

.rating-number-container {
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 34px;
  font-weight: 600;
  padding: 10px;
  margin: 10px;
}

.rating-star-icon-alignment {
  font-size: 35px !important;
  color: rgb(245, 119, 1);
}

@media screen and (max-width: 830px) {
  .rating-section-display-container {
    display: block;
  }
}

/* ___________________Review card__________________ */

.review-card-conatiner {
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  height: 320px;
  margin: 10px;
  width: calc(100% - 20px);
  border-radius: 10px;
  background-color: #ffffff;
}

.review-avatat-name-designation-container {
  display: flex;
  padding: 15px;
}

.review-name-designation {
  margin: 8px;
  margin-left: 12px;
}

.rating-avatar {
  width: 56px !important;
  height: 56px !important;
  background-color: rgba(0, 128, 0, 0.813) !important;
}
.review-name {
  font-size: 20px;
  font-weight: 400;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.review-designation {
  padding-top: 4px;
  font-size: 12px;
  font-weight: 400;
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.review-description {
  font-weight: 400;
  color: rgb(41, 40, 40);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  padding: 18px;
  font-size: 16px;
  max-height: 170px;
  /* border: 1px solid red; */
  overflow: auto;
  scrollbar-width: 5px;
  line-height: 25px;
  margin-top: -5px;
}

/* ____________Review card-render carousel________________ */

.review-card-carousel-background-color {
  background-color: #fafbff;
}

.review-card-carousel-width-container {
  max-width: 1140px;
  margin: auto;
  
}

/* .review-card-carousel-container {
  padding: 10px;
} */

/* Hide the arrows by default */
.react-multiple-carousel__arrow {
  opacity: 0 !important;
  display: none !important;
  transition: opacity 0.3s ease-in-out !important; /* Smooth transition for hover effect */
}

/* Show the arrows on hover */
.review-card-carousel-background-color:hover .react-multiple-carousel__arrow {
  display: block !important;
  opacity: 1 !important;
}
