.company-logo-background-color {
  background-color: white;
}

.company-logo-width-conatiner {
  max-width: 1140px;
  margin: auto;
  padding: 10px;
  padding-bottom: 20px;
  padding-top: 20px;
  
}

.company-logo-render-conatainer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fffefe00;
  z-index: 1; 
}

.comapany-logo-heading-conatainer {
  text-align: center;
  color: rgb(0, 0, 0);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 24px;
  font-weight: 600;
  padding: 10px;
  padding-top: 40px;
  text-wrap: pretty;
}

.comapany-render-tagline {
  font-size: 18px;
  text-align: center;
  max-width: 1000px;
  margin: auto;
  padding: 10px;
  text-wrap: pretty;
  color: rgb(0, 0, 0);
}

.company-logo-render-conatainer {
  background-color: #ffffff00;
  white-space: nowrap;
  border-radius: 10px;
  height: 360px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 5px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  overflow: hidden;
  position: relative;
  background-image: url("./CompanyLogo/19406401.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  width: 97%;
  margin: auto;
  padding: 10px;
  
  
  
}

.company-logs-heading-in-slide-section {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-top: 50px;
  font-weight: bold;
}

.company-logo-render-conatainer-outside {
  background-color: #ffffff;
  /* white-space: nowrap; */
  border-radius: 10px;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4);
  margin: 15px;
  display: grid;
  place-items: center;
  overflow: hidden;
}

.logos-slides {
  animation: slide 30s linear infinite;
  width: calc(250px * 14);
  margin-top: 30px;
  display: flex;
  
}

.logos-slides img {
  max-width: 120px;
  max-height: 120px;
  padding: 10px;
  margin-right: 100px;
}

/* Adjust the keyframes to fit the container width */
@keyframes slide {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-250px * 7));
  }
}
