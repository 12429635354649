.couese-card-outer-container {
  background-color: #ffffff;
  width:calc(100% - 30px);
  height: 360px;
  /* box-shadow: 0px 0px 10px rgba(132, 132, 132, 0.4); */
  box-shadow:  0px 0px 10px rgba(30, 10, 58, .09);
  margin: 20px;
  border-radius: 10px;
  cursor: pointer;
}

.course-card-image-section-container {
  height: 210px;
  width:calc(100%);
  position: relative;
}
.course-card-image-section-container img {
  height: 100%;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  object-fit: cover;
}

.course-card-course-name-conatainer {
  font-family: "Roboto", sans-serif;
  overflow: hidden;
  height: auto;
  text-align: left;
  margin-bottom: 12px;
  font-size: 22px;
  padding: 12px;
  font-weight: bold; 
  height: 70px;
  line-height: 27px;
  max-height: calc(23px * 2); /* Max height to accommodate 4 lines */
  overflow: hidden; /* Hide overflowing text */
  display: -webkit-box; /* Flexbox layout for truncation */
  -webkit-line-clamp: 2; /* Limit to 4 lines */
  -webkit-box-orient: vertical; /* Orient the box vertically */ 
  text-overflow: ellipsis; /* Add ellipsis (...) at the end */
  line-clamp: 2; /* Limit to 4 lines (experimental) */
}

.course-card-course-rating-container {
  margin: 12px;
  margin-top: -2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.course-card-course-rating-text-container {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: black;
}

.course-card-course-rating-star {
  font-size: 13px !important;
  margin-left: 5px;
}

.course-card-course-hours-sections {
  display: flex;  
padding: 12px;
padding-top:8px ;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
}
.course-card-hour {
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-card-hour-icon {
  font-size: 18px !important;
  font-weight: 900;
  color: rgb(0, 0, 0);
  margin-top: 2px;
}
.course-card-rupees-icon {
  font-size: 17px !important;
  font-weight: 900;
  color: rgb(0, 0, 0);
  margin-top: 2px;
}

.course-card-hour-Text {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: black;
  margin-top: 2px;
}

.course-card-course-free-paid {
  margin: 10px;
  margin-left: 14px;
  text-align: left;
}

.course-card-best-seller-tag {
  position: absolute;
  background-color: rgb(255 255 255 / 65%);
  top: 10px;
  left: 10px;
  font-size: 8px;
  font-family: "Roboto", sans-serif;
  color: rgb(9 8 8);
  padding: 3px;
  border-radius: 2px;
  font-weight: 600;
}

.course-price-section {
  display: flex;
  align-items: center;
  font-weight: 700;
  margin-top: 12px;
  margin-left: -3px;
}
