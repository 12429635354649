/* _____________________Course Include_________________ */
.enquiry-section-bakground-color{
  background-color: white;
}
.enquiry-section-container {
  max-width: 1140px;
  margin: auto;
  margin-bottom: -9px;
  padding: 10px;
}

.enquiry-section-flex-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}

.enquiry-section-image-conatiner {
  max-height: 550px;
  max-width: 550px;
}

.enquiry-section-image-conatiner img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.enquiry-section-Button-section {
  align-items: start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.enquiry-section-heading-section {
  font-size: 24px;
  color: black;
  font-family: "Roboto", sans-serif;
  margin-bottom: 20px;
  font-weight: 700;
  text-align: left;
}

.enquiry-section-points {
  color: black;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 18px;
  text-align: left;
}

.enquiry-section-point-icons {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
}

.buttoniconspoints-enquiry {
  font-size: 25px !important;
  margin-right: 4px;
  margin-top: -4px !important;
  cursor: pointer;
  color: green;
  
}

.buttonicons {
  font-size: 18px !important;
  margin-right: 4px;
  margin-top: -2px ;
  cursor: pointer;
  color: green;
  
}

.button-container {
  font-size: 0.875rem;
  color: rgb(255, 255, 255);
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  cursor: pointer;
  /* border: 1px solid black; */
  margin-top: 25px;
  padding: 16px;
  padding-top: 20px;
  border-radius: 5px;
  display: flex;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #2196f3;
  font-weight: 600;
  /* text-transform: uppercase; */
  transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  width: max-content;
}

.button-container:hover {
  transform: scale(1.05) !important;
  background-color: rgb(4, 99, 4);
}

.buttonicons-common-enquiry {
  font-size: 24px !important;
  padding-left: 4px;
  cursor: pointer;
  color: white;
  margin-top: -4px;
  animation: dance 0.5s infinite alternate;
  transition: color 0.2s ease-in-out;
}

.button-container:hover .buttonicons-common-enquiry {
  color: red;
}

@keyframes dance {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-4px);
  }
}

@media screen and (max-width: 830px) {
  .enquiry-section-flex-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 10px;
  }
  .enquiry-section-image-conatiner {
    align-items: center;
    margin: auto;
  }
}
/* _______________________end_______________________ */
