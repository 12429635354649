.ourvision-container-background-color {
  background-color: #ffffff;
}

.ourvision-container {
  max-width: 1140px;
  padding: 10px;
  margin: auto;
  
  
}

.ourvision-heading {
  text-align: left;
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 24px;
  font-weight: 600; 
  padding-top: 10px;
  padding-bottom: 10px;   
  
}

.ourvision-content {  
  color: black;
  font-family: "Roboto", sans-serif;
  font-size: 17px;
  /* font-weight: 500;  */
  padding: 10px; 
  line-height: 30px;
  max-width: 1140px;
  text-align: justify;
  height: auto; /* Adjusts to content height */
  overflow: hidden; /* Prevent overflow if content grows */
}
