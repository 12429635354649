.editor-container {  
  border: 1px solid #ccc;
  border-radius: 8px;
  
}

.toolbar-editor2 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgb(240, 240, 240);
  flex-wrap: wrap;
  background-color: rgb(235, 235, 235);
  padding: 16px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}



.toolbar-editor2 .toolbar1 button {
  background-color: #ffffff;
  border: none;
  margin-right: 5px;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 5px;
}

.toolbar-editor2 .toolbar1 button:hover {
  background-color: #ddd;
}

.buttoniconcommon-toolbar {
  font-size: 18px !important;
}

.edit-button-editor2{
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 1em;
  padding: 5px;
  margin-left: auto !important;
  transition: color 0.3s;
}

.edit-button-editor2:hover, .copy-button:hover {
  color: #2980b9;
}


.editable-area {
  border: 1px solid #ccc;
  height: calc(100vh - 300px);
  padding: 10px;
  border-radius: 4px;
  outline: none;
  font-size: 16px;
  text-align: left;
  position: relative;
  overflow: auto;
  margin: 16px;
  scrollbar-width: none;
}

.header-text-field-container {
  padding: 16px;
  padding-bottom: 6px;
}

.editable-area:empty::before {
  content: attr(data-placeholder);
  color: gray; /* Placeholder color */
  pointer-events: none; /* Prevent the placeholder text from being selected */
  display: block;
}

.editable-area:hover {
  outline: 1px solid black;
}
.editable-area:focus {
  outline: 2px solid #0088ff;
}

.writecodehere {
  scrollbar-width: 4px !important;
}

.save-button {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 8px 16px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 4px;
}

.save-button:hover {
  background-color: #45a049;
}

.editor-image-container {
  display: inline-block;
  position: relative;
  resize: both;
  overflow: auto;
}

.editor-image-container img {
  max-width: 100%;
  height: auto;
  display: block;
}

/* editorlanding.css */
.editor-container {
  position: relative;
}

.code-snippet-modal {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.code-snippet-modal h2 {
  margin-top: 0;
}

.code-snippet-modal label {
  display: block;
  margin-bottom: 10px;
}

.code-snippet-modal textarea {
  width: 100%;
}

.code-snippet-modal button {
  margin-right: 10px;
}

.code-viewer-content-render {
  height: 97%;
  max-height: calc(100vh - 210px);
  padding: 10px;
  padding-top: 15px;
  overflow: scroll;
  scrollbar-width: none;
}

.code-viewer-content-render-grid-container {
  display: grid;
  gap: 15px;
  grid-template-columns: 1fr;
}

.code-snippet-insert-heading {
  font-size: 22px;
  align-items: center;
  justify-content: center;
  display: flex;
  font-weight: bold;
  margin: 10px;
}

@media screen and (max-width: 530px) {
  .code-snippet-insert-heading {
    display: none;
  }
}

.code-viewer-snippet-button-alignment {
  display: flex;
  justify-content: flex-end;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.code-viewer-dialog-editor2 {
  background-color: white;
  padding: 10px;
  border-radius: 10px !important;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  width: 88%;
  max-width: 600px;
  position: absolute; /* Use absolute positioning for centering */
  margin: 0; /* Remove default margin */
  height: auto;
  top: 50%; /* Center vertically */
  left: 50%; /* Center horizontally */
  transform: translate(
    -50%,
    -50%
  ); /* Offset by half its own width and height */
}

/*____________________________Dialog box cancel button____________________________________*/
.enquiry-form-addeducationalMapmodalcancel {
  position: absolute !important;
  top: -16px;
  right: -15px;
}

.enquiry-form-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
  font-size: 16px !important;
}
/* _____________media query_____________ */
@media screen and (max-width:500px) {
  

.toolbar-editor2{
  padding: 5px;
} 
}

@media screen and (max-width:386px) {
  

  .buttoniconcommon-toolbar{
    font-size: 12px !important;
  } 
  }
