.exam-outside-container{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* background: linear-gradient(135deg, #007bff 0%, #28a745 100%); */
    background-color: white;
}

.exam-hero-container {
    width: 1140px;
    color: white;
    /* padding: 50px 0; */
    display: flex;
    justify-content: center;
  }
  
  .exam-hero-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 40px;
    border-radius: 12px;
    background-color: rgba(255, 255, 255, 0.1); /* Semi-transparent background */
    width: 90%;
    /* max-width: 1200px; */
    /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1); */
  }
  
  .exam-hero-left {
    flex: 1;
    color: #333;
    text-align: left;
  }

  .exam-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    font-family: "Roboto",sans-serif;
    font-size: 18px;
  }
  
  .exam-logo {
    max-width: 100px;
    height: auto;
    margin-right: 20px; /* Space between logo and exam name */
  }
  
  .hero-left h1 {
    font-size: 28px;
    margin: 0;
    font-family: "Roboto", sans-serif;
  }
  
  .exam-info {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #666;
  }
  
  .exam-info p {
    display: flex;
    align-items: center;
    margin: 0;
    font-size: 12px;
    font-family: "Roboto", sans-serif;
  }
  
  .exam-info p svg {
    margin-right: 8px; /* Add spacing between icon and text */
  }
  
  .exam-separator {
    margin: 0 10px;
    font-size: 18px;
  }
  
  
  .exam-hero-buttons{
    margin-top: 30px;
  }
  
  .get-started-btn{
    padding: 12px 20px;
    margin-right: 20px;
    text-decoration: none;
    color: white;
    background-color: #007bff; /* Change to a bold color */
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  }
  
  .get-started-btn:hover{
    transform: scale(1.05) !important;
    background-color: rgb(4, 99, 4) !important;
  }
  /* .exam-download-btn {
    border: 1px solid #0ad0f4 !important;
    color: #0ad0f4;
    background-color: transparent;
    transition: transform 0.4s ease-in-out, background-color 0.2s ease-in-out !important;
  }

  .exam-download-btn:hover{
    transform: scale(1.05) !important;
    background-color: #0ad0f4;
    color: white;
  } */
  
  .exam-hero-right img {
    width: 390px;
    border-radius: 8px;
  }
  
  /* Styling for the Info icon */
.navigation-icon {
  font-size: 24px; /* Adjust the size of the icon */
  margin-left: 10px; /* Add spacing between the title and the icon */
  color: #007bff; /* Icon color (blue for 'info') */
  transition: transform 0.3s ease, color 0.3s ease;
  cursor: pointer; /* Make the icon clickable */
  margin-top: 3px;
}

/* Icon hover effect */
.navigation-icon:hover {
  color: #0056b3; /* Darker blue on hover */
  transform: scale(1.2); /* Slight zoom effect on hover */
}
  /* Media queries for responsiveness */
  
  /* For tablets and smaller screens */

  @media (max-width: 925px){
    .exam-hero-right img{
      width: 300px;
    }
  }

  @media (max-width: 836px){
    .exam-hero-right img{
      width: 250px;
    }
  }

  @media (max-width: 772px){
    .exam-hero-right img{
      width: 230px;
    }
  }

  @media (max-width: 768px) {
    .exam-hero-section {
      flex-direction: column;
      text-align: center;
    }
  
    .exam-hero-right {
      margin-top: 20px;
    }
  
    .exam-hero-right img {
      width: 100%;
    }
  
    .get-started-btn, .exam-download-btn {
      margin-bottom: 10px;
    }
  }
  
  /* For mobile screens */
  @media (max-width: 480px) {

    .exam-hero-buttons{
        margin-top: 20px;
    }
    .exam-hero-left h1 {
      font-size: 20px;
    }
  
    .exam-hero-left p {
      font-size: 12px;
    }
  
    .get-started-btn, .exam-download-btn {
        font-size: 10px;
        padding: 14px;
    }

    .exam-download-btn{
        margin-top: 20px !important;
    }
  
    .exam-hero-right img {
      max-width: 80%;
    }
  }