.exam-overview-outer-container {
    width: 100%;
    /* padding: 40px 20px; */
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .exam-overview-container {
    width: 1140px;
    /* background: white;
    border-radius: 12px; */
    /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
    padding: 30px;
    text-align: left; 
  }
  .exam-overview-heading{
    font-size: 24px;
    font-family: "Roboto", sans-serif;
  }
  .exam-overview-container p {
    font-size: 18px;
    font-family: "Roboto", sans-serif;
    line-height: 1.6;
    color: black;
    text-align: justify;
    margin: 0;
  }
  
  /* Media Queries for responsiveness */
  @media (max-width: 1268px){
    .exam-overview-container {
        padding: 20px; 
        width: 90% !important;
      }
  }

  @media (max-width: 968px){
    .exam-overview-container {
        padding: 20px; 
        width: 85% !important;
      }
  }
  @media (max-width: 768px) {
    .exam-overview-outer-container{
        padding-left: 0;
        padding-bottom: 20px;
    }
    .exam-overview-container {
      padding: 20px; 
      width: 85% !important;
    }
  
    .exam-overview-container p {
      font-size: 16px; /* Slightly smaller font size */
    }
  }
  
  @media (max-width: 480px) {
    .exam-overview-container {
      padding: 15px;
      width: 90%;
    }
  
    .exam-overview-container p {
      font-size: 14px; /* Smaller font size for mobile */
    }
  }