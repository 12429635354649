.make-container-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85vh;
}

.page-loading {
  --s: 190px; /* Control the size of the loading spinner */
  width: var(--s); /* Set the width based on the size variable */
  height: var(--s); /* Set the height based on the size variable */
  position: relative; /* Position the content relative to this container */
  display: flex; /* Use flexbox to center the logo */
  justify-content: center; /* Center the logo horizontally */
  align-items: center; /* Center the logo vertically */
}

.page-loading img {
  width: 80%; /* Adjust to control the size of the inner circle */
  height: 80%; /* Adjust to control the size of the inner circle */
  background-color: rgb(245, 255, 254);
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1; /* Ensure the image is above the spinner */
}

.spinner {
  position: absolute; /* Position the spinner relative to the container */
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  transform: translate(-50%, -50%);
}

.spinner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 5px solid transparent;
  border-top-color: #e01cd5;
  border-bottom-color: #1cb5e0;
  box-sizing: border-box; 
  background: linear-gradient(to bottom, #e01cd5, #1cb5e0);
  animation: spin 0.6s linear infinite;


}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
