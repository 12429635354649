.carousel_inside{
  width: 1140px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.carousel{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: white;
}

/*__________________Carousel control dots _______________________*/
.carousel .control-dots{
    width: 400px;   
}

/*__________________Carousel control arrow pevious _______________________*/
.carousel .control-arrow.control-prev {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 210px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    opacity: 1 !important;
    border: 1px solid #6a6f73;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08)
}

/*__________________Carousel control arrow next _______________________*/
.carousel .control-arrow.control-next {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    margin-top: 210px;
    background-color: rgba(0, 0, 0, 0.7) !important;
    opacity: 1 !important;
    border: 1px solid #6a6f73;
    box-shadow: 0 2px 4px rgba(0, 0, 0, .08), 0 4px 12px rgba(0, 0, 0, .08)
}

/*__________________Carousel status _______________________*/
.carousel .carousel-status{
    right: 60px;
}

/*__________________Carousel control dots dot_______________________*/
.carousel .control-dots .dot {
    background: white !important;
    border: 5px solid white !important;
}

.text-overlay {
  position: absolute;
  bottom: 5%;
  left: 50%;
  width: 150%;
  transform: translate(-50%, -50%);
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 20px;
  border-radius: 10px;
  opacity: 0;
}


.text-overlay.fade-in {
  animation: fadeInOverlay 2s forwards;
}

/* Keyframes for animations */
@keyframes fadeInOverlay {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


@media (max-width: 1168px) {
  .text-overlay {
    width: 450px;
    height: 270px;
    margin-left: -150px !important;
    padding: 3px;
  }
  .text-overlay {
    bottom: 0;
    padding: 5px 10px;
    height: 30%;
    left: 70%;
    width: 150%;
  }

}
@media (max-width: 768px) {
  .carousel_inside{
    padding: 0 20px;
  }

    .carousel{
      width: 100%;
    }

    .text-overlay {
      bottom: 0;
      padding: 5px 10px;
      height: 30%;
      left: 70%;
      width: 150%;
    } 
    .text-overlay h2{
      font-size: 18px;
    } 

    .text-overlay p{
      font-size: 14px;
    }

    .carousel .control-arrow.control-prev {
        margin-top: 120px;
    }
  
      .carousel .control-arrow.control-next{
          margin-top: 120px;
    }

    .carousel .carousel-status{
        right: 50px;
    }

    .carousel .control-dots{
        width: 400px;
    }
  }
  
  @media (max-width: 580px) {
    .carousel{
      width: 100% !important;
    }
   

    .carousel .control-arrow.control-prev {
      margin-top: 120px;
    }

    .carousel .control-arrow.control-next{
        margin-top: 120px;
    }

    .carousel .carousel-status{
        right: 10px;
    }

    .text-overlay {
      bottom: 0;
      padding: 5px 10px;
      height: 30%;
      left: 85%;
      width: 150%;
    }

    .carousel .control-dots{
        width: 400px;
    }
  }

  @media (max-width:395px){
    .text-overlay{
      width: 110%;
      left: 93%;
    }

    .text-overlay h2{
      font-size: 14px;
    }
    .text-overlay p{
      font-size: 12px;
    }
  }

  @media (max-width:363px){

    .text-overlay{
      width: 110%;
      left: 100%;
    }
  }

  @media (max-width:362px){
    .text-overlay{
      width: 110%;
      left: 100.5%;
    }
  }