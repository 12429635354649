.image-croper2-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.image-croper2-content-container {
  background: white;
  padding: 30px;
  padding-top: 10px;
  border-radius: 8px;
  position: relative;
  max-width: 850px;
  width: 95%;
}

.image-croper2-addeducationalMapmodalcancel {
  position: absolute !important;
  top: -15px;
  right: -13px;
}
.image-croper2-addeducationalMapmodalcancelX {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
  height: 14px !important;
    width: 14px !important;
}

.image-croper2-table-header {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  color: black;
  /* border-bottom: 1px solid rgb(223, 218, 218); */
  text-align: center;
}

.image-croper2-grid-container {
  display: grid;
  grid-template-columns: 550px 1fr;
  gap: 10px;
}
.image-croper2-subHeading {
  color: black;
  font-weight: bold;
}

.image-shown-container {
  width: 520px;
  height: 330px;
  border: 1px solid rgb(223, 218, 218);
  margin-bottom: 8px;
  border-radius: 5px;
  overflow: scroll;
  scrollbar-width: none;
  
}

.image-shown-container::-webkit-scrollbar {
  width: 5px;
  border-radius: 10px;
  
}

.image-shown-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.image-shown-container::-webkit-scrollbar-thumb {
  background: #d8d8d8;
  border-radius: 10px;
}

.image-shown-container::-webkit-scrollbar-thumb:hover {
  background: #626363;
}

.Adust-image-conatiner {
  max-width: 520px;
  max-height: 330px;
  /* object-fit: cover; */
  /* object-fit: contain; */
  border-radius: 5px;
}

.image-cropper-controls {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.cropper-select-and-heading {
  display: flex;
  align-items: center;
  /* background-color: #2e7d32; */
  /* color: white; */
  padding: 2.5px;
  border-radius: 5px;
  /* border: none; */
  padding-left: 5px;
}
.input-size-and-design {
  width: 70px;
  padding: 3px;
  /* background-color:#2e7d32 ; */
  /* color: white; */
  /* border: none;
 outline: none; */
  padding-right: 5px;
  padding-left: 5px;
  height: 28px;
}

.image-croper-right-section {
  display: flex;
  flex-direction: column; 
  
}

.image-shown-container2 {
  width: 210px;
  height: 210px;
  margin-top: 10px;
 
}

.imgae-croper-save-button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: -22px;
}

.image-croper2-sub-heading {
  margin-bottom: 10px;
}

.image-cropper-left-section {
  max-width: 530px;
  max-height: 420px;
  padding-right: 4px;
  /* border-right: 1px solid rgb(167, 167, 167); */
  
}

.images-show-cropted-image {
  border: 1px solid rgb(223, 218, 218);
  /* object-fit: cover; */
  border-radius: 50%;
  width: 100%;
  height: 100%; 
  margin-top: 40px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 0px 10px rgba(0,0,0,0.01);
}
.image-subheading-tagline {
  display: flex;
  flex-grow: 1; /* Allows the container to grow and take up available space */
  width: 100%; /* Ensures the container takes up the full width */
  justify-content: space-between; /* Distributes the space between items */
  align-items: center; /* Aligns the items vertically in the center */
  margin-bottom: 10px;
}

.image-subheading-tagline > * {
  flex: 1; /* Makes each child item take up an equal portion of the space */
  min-width: 0; /* Prevents content from overflowing its container */
}


@media (max-width: 870px) {
  .image-croper2-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 10px;
    margin: auto;
    align-items: center;
  }
  .image-croper2-content-container {
    max-width: 590px;
  }
  .imgae-croper-save-button{
    margin-top: 10px;
    padding-top: 10px;
  }
  .image-croper-right-section{    
    margin-top: 38px;
    border-top: 1px solid rgb(223, 218, 218);
    align-items: center;
    padding-top: 10px
  }
  .images-show-cropted-image {
    margin-top: 5px;
    align-items: center;
    text-align: center;
    margin: auto  ;
  }
  .image-cropper-left-section{
    max-width: 520px;
    width: 95%;
    max-height: 420px;
    height: 95%;
  }
}

@media (max-width: 630px) {
  .image-croper2-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    height: 400px;
    overflow-y: scroll;
    padding-bottom: 10px;
    margin: auto;
    
  }
  .image-croper2-content-container {
    max-width: 360px;
  }
  .imgae-croper-save-button{
    margin-top: 10px;
    padding-top: 10px;
  }
  .image-croper-right-section{
    
   
    margin-top: 160px;
    align-items: flex-start;
    margin-left: 0px;
  }
  .images-show-cropted-image {
    margin-top: 5px;
 
   
  }
  .image-shown-container{
    width: 275px;
  }

  .image-cropper-controls {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
