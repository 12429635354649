.instructor-template-container {
  box-shadow: 0px 0px 10px 0px rgb(201, 201, 201);
  max-width: 980px;  
  margin: auto;
  width: 100%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  margin: 10px;
  /* background: linear-gradient(to right, #f0f0f0, #ffffff); */
  /* background: linear-gradient(to right, #f5f5dc, #ffffff); */
  /* background: linear-gradient(to right, #f5f5dc, #ffffff); */
  /* background: linear-gradient(to right, #e6e6fa, #ffffff); */
  background: white;
}

.instructor-content-container {
  display: flex;
  gap: 15px;
}

.instructor-image-name-designation {
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 300px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 14px;
}

.instructor-card-make-center-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.instructor-right-content {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  scrollbar-width: none;
  height: 300px;
  overflow: scroll;
}

.instructor-experience {
  display: flex;
  gap: 25px;
  padding: 11px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.instructor-image-container {
  width: 230px;
  height: 230px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 0px 5px rgba(84, 84, 84, 0.957);
}

.instructor-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instuctor-name {
  margin-top: 2px;
  padding:10px;
  padding-bottom: 3px;
  font-size: 19px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 700;
  color: black;
}

.instuctor-Designation {
  font-size: 14px;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: black;
}

.instructor-work-experience {
  display: flex;
  align-items: center;
  gap: 15px;
}

.year-font-size-and-align {
  font-weight: 700;
  color: black;
  font-family: "Roboto", sans-serif;
}

.work-experien-teaching-font-align {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  color: black;
}

.experience-icon-alignment {
  font-size: 33px !important;
}

.instructor-content {
  height: 157px;
  width: auto;
  overflow: scroll;
  scrollbar-width: none;
  padding: 12px;
  text-align: justify;
  font-family: "Roboto", sans-serif;
  color: black;
  display: flex;
  margin-top: 7px;
}

.instructor-logos {
  height: 65px;
 
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
    scrollbar-width: none;
    padding: 11px
}

.instructor-logos-inside-container {
  height: 55px;
  width: 55px;
}

.instructor-logos-inside-container img {
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
}

.social-media-contact {
  padding: 6px;
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  flex-wrap: wrap;
}

.social-media-hover-background-color{
  color:#0d6efd ;

}
.social-media-hover-background-color:hover {
  color: green;
  cursor: pointer;
}

.instructor-content1 {
  margin: 10px;
  text-align: justify;
  height: 130px;
  padding-bottom: 10px;
  overflow: hidden;
  overflow-y: scroll;
  display: none;
  font-family: "Roboto", sans-serif;
  color: black;
  scrollbar-width: none;
}

/* _______________Responsive card ________________ */
@media screen and (max-width: 861px) {
  .instructor-content {
    display: none;
    height: unset;
  }
  .instructor-content1 {
    display: block;
  }
}

@media screen and (max-width: 436px) {
.instructor-template-container {
  width: 65%;
  margin: auto;
}
}
@media screen and (max-width: 376px) {
  .instructor-template-container {
    width: 90%;
    margin: auto;
    margin-left: 10px;
  }
  }

@media screen and (max-width: 680px) {
  .instructor-content-container {
    display: block;
  }
  .instructor-content1 {
    height: 95px;
    margin-top: -4px;
  }
  .instructor-right-content {
    height: unset;
  }
  .make-the-image-container-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 220px;
  }
  .instructor-image-container {
    height: 150px;
    width: 150px;
  }
  .instructor-logos {
    margin-top: -7px;
  }
}

/* _________________end____________________________ */

/* ____________________Instructor Landing page____________ */

.Instructor-landing-page-search-input {
  width: auto;
  padding: 10px;
  border: none;
  outline: none;
}

.instructor-landing-page-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  margin: 10px;
  flex-wrap: wrap;
  gap: 5px;
}

.instructor-landing-page-search-container {
  display: flex;
  align-items: center;
  border: 1px solid rgb(216, 224, 240);
  border-radius: 16px;
  overflow: hidden;
  max-width: 290px;
  /* width: 90%; */
  box-shadow: 0px 0px 1px gray;
  /* padding-right: 2px; */
}

.instructor-landing-page-search-container:hover,
.instructor-landing-page-search-container:focus-within,
.instructor-landing-page-search-container:active {
  box-shadow: -1px 2px 2px gray;
}

@media screen and (max-width: 750px) {
  .instructor-landing-page-search-container {
    max-width: 790px;
    width: 100%;
  }
}

.instructor-landing-page-search-icon {
  padding: 8px;
}

.instructor-landing-page-search-icon svg {
  font-size: 28px;
  color: gray;
}

.instructor-landing-page-managee-button {
  border: 1px solid rgb(216, 224, 240);
  padding: 10px;
  border-radius: 16px;
  box-shadow: 0px 0px 1px gray;
  font-size: 18;
  font-weight: 600;
  height: fit-content;
  cursor: pointer;
}

.instructor-landing-page-managee-button:hover {
  background-color: #e9ecf3;
}

.instructor-landing-page-common-icon-designs {
  font-size: 18px;
  margin-top: -3px;
  color: black;
}

.instructor-landing-page-common {
  color: #000000;
}

.instructor-page-common-table-header-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  justify-items: center;
  flex-wrap: nowrap;
}

.instructor-page-avatar {
  background-color: green !important;
  font-size: small !important;
  height: fit-content;
  width: fit-content;
  cursor: pointer;
}

.instrtcor-page-landing-body-template {
  margin: 10px;
  border-radius: 20px;
  box-shadow: 0px 0px 1px gray;
  padding-bottom: 17px;
  padding-top: 10px;
  padding-left: 4px;
  height: calc(100vh - 120px);
}

/* ___________________________Add/Edit instructor _____________________ */
.instructor-Save-Button-Alignment-container {
  display: flex;
  justify-content: flex-end;
  padding: 12px;
}

.instructor-screen-center-alignment {
  width: 100%;
  height: 100%;
}

.instructor-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructor-inside-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  height: auto;
  max-width: 400px; /*width adustment of the instructor*/
  transform: translate(-50%, -50%);
  width: 90%;
 
  border-radius: 15px;
  
}

.instructor-close-iconButton-right-top-corner {
  position: absolute !important;
  top: -15px;
  right: -13px;
}

.instructor-close-iconButton-right-top-corner-symbol {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
}

.instructor-header-and-switch-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-items: center;
  color: black;
  font-weight: bold;
  padding: 5px;
  font-size: 21px;
  margin: 1px;
}

.instructor-fields-container {
  height: calc(100vh - 140px);
  padding: 10px;
  margin-left: 3px;
  margin-right: 3px;
  overflow: scroll;
}

.links-manage-container {
  margin: 10px;
  margin-top: -5px;
  color: rgb(121, 119, 119);
}

.social-media-icon {
  color: inherit; /* Default color */
}

.social-media-icon.green {
  color: green; /* Green color when link is present */
}

.instructor-logos-container {
  height: 70px;
  width: 70px;
  display: flex;
  margin-top: 5px;
  padding: 5px;
}

.instructor-logos-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.instructor-logos-flex-conatiner {
  display: inline-flex;
  margin-left: 10px;
}

.profile-image-container {
  margin-top: 5px;
  max-height: 170px;
  max-width: 170px;
  padding: 5px;
}

.profile-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-upload-button {
  font-size: 11px !important;
}

.chip-alignment-and-gap {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  margin: 3px;
}

.chip-background-color {
  background-color: #1d88fa !important;
  color: white !important;
}

.chip-background-color .MuiChip-deleteIcon {
  color: white !important;
}

.image-type-input-filed-none {
  display: none;
}

/* __________________View modal___________________ */

.viewinstructor-screen-center-alignment {
  width: 100%;
  height:100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewinstructor-container {
  height:100%;
  width: 100%;
}

.viewinstructor-inside-content-container {
  position: absolute;
  top: 50%;
  left: 50%;
  height: 97%;
  width:95%; /*width adustment of the viewinstructor*/
  transform: translate(-50%, -50%);
  padding: 3px;
  background: linear-gradient(to right, #e6e6fa, #ffffff);
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.viewinstructor-close-iconButton-right-top-corner {
  position: absolute !important;
  top: -15px;
  right: -13px;
}

.viewinstructor-close-iconButton-right-top-corner-symbol {
  background-color: red;
  border-radius: 50%;
  padding: 4px;
  color: white;
  font-weight: bold;
}

.viewinstructor-header-and-switch-container {
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  align-items: baseline;
  color: black;
  padding: 15px;
}

.viewinstructor-header-name {
  font-weight: bold;
  text-align: center;
}

.viewinstructor-scroll-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.view-instructor-inside-container {
  display: flex;
  justify-content: center;
  overflow: scroll;
  padding-top: 30px;
}


/* ________________template2______________ */

.instructor-template-container-template1 {
  box-shadow: 0px 0px 5px  rgb(196, 196, 196);
  max-width: 360px;
  width: 95%;
  margin: 10px;
  border-radius: 10px;
  position: relative;

  
 
  /* background: linear-gradient(to right, #f0f0f0, #ffffff); */
  /* background: linear-gradient(to right, #f5f5dc, #ffffff); */
  /* background: linear-gradient(to right, #f5f5dc, #ffffff); */
  /* background: linear-gradient(to right, #e6e6fa, #ffffff); */
  background: white
}


  .make-the-image-container-center-template1{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
  padding: 5px;
    background: linear-gradient(to right, #e1e1e8, #ebe4e4);
  margin: 5px;
}

.instructor-image-name-designation-template1 {
  width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 240px;
  align-items: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 14px;

}

.instructor-template-container-template1-scroll{
  height: calc(100vh - 160px);
  padding: 5px;
  overflow-y: scroll;
}


.instructor-image-container-template1 {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin: 0 auto; 
  box-shadow: 0px 0px 5px rgba(84, 84, 84, 0.957);
  position: relative;
}

.instructor-image-container-template1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.instructor-image-container-template1:hover{
  background-color: #94939327;
}

.image-edit-delete-buttons{
  position: absolute;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 5px;

}

.instructor-name-and-designation-container-template2{
 
  margin: 5px;
  text-align: center;
  padding: 5px;

}

.instructor-experience-work-teaching-container{
  
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: space-between;

}

.instructor-worked-company-social-link-container{
 
  padding: 5px;
  margin: 5px;
  display: flex;
  gap: 10px;
}


.overflow-scroll-container{
  padding: 5px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.instructor-worked-company-logos{
 display: flex;
 overflow-x:scroll;
  gap: 5px;
  width: 90%;

}



.delete-logos-conatiner-image-induvitual{
  position: relative;
  top: -40px;
}

.instructor-worked-company-logos-hold-container{
  width: 60px;
  height: 60px;  
  padding: 5px;
}

.instructor-worked-company-logos-hold-container img{
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.image-edit-delete-buttons-logos{
  position: relative;
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 5px;
  margin-top: -50px;
  

}

.about-instructor-conatiner{
  margin: 5px;
}

.buttonicons-edit{
  color: blue;
  font-size: 17px !important;
  margin-right: 4px;
  cursor: pointer;

}

.buttonicons-delete{
  color: red;
  font-size: 17px !important;
  margin-right: 4px;
  cursor: pointer;

}

.instructor-logos-heading{
  margin-top:7px;
  color: #000000;
  font-family: "Roboto", sans-serif;

}

.instructorname-template2{
  color: #000000;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 19px;
}
.instructordesignation-template2{
  color: #000000;
  font-family: "Roboto", sans-serif;

  font-size: 15px;
}



